import React, { useState } from "react";
import {
  Box,
  Grid,
  Heading,
  Text,
  Flex,
  IconButton,
  Icon,
  Select,
} from "@chakra-ui/react";
import { Bar, Line } from "react-chartjs-2";
import "chart.js/auto";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { FaBuilding, FaWallet, FaCashRegister } from "react-icons/fa";
import DashboardBox from "../../../commons/DashboardBox";

// Dashboard Box Data (Dummy)
const dashboardData = [
  { name: "Asset", value: 150000, icon: FaBuilding, bg: "blue.500" },
  { name: "Cash in Hand", value: 50000, icon: FaWallet, bg: "green.500" },
  { name: "Capital", value: 200000, icon: FaCashRegister, bg: "purple.500" },
  { name: "Cash Business", value: 120000, icon: FaWallet, bg: "orange.500" },
  { name: "Cash Personal", value: 30000, icon: FaWallet, bg: "red.500" },
];

const ProjectFinancialManagement = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [timeRange, setTimeRange] = useState("monthly");

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleTimeRangeChange = (e) => {
    setTimeRange(e.target.value);
  };

  // Dynamic Expense Data based on Time Range
  const expenseData = {
    labels:
      timeRange === "monthly"
        ? ["January", "February", "March", "April", "May"]
        : timeRange === "quarterly"
        ? ["Q1", "Q2", "Q3", "Q4"]
        : ["2020", "2021", "2022", "2023"],
    datasets: [
      {
        label: "Home Expenses (AED)",
        data:
          timeRange === "monthly"
            ? [5000, 6000, 5500, 7000, 8000]
            : timeRange === "quarterly"
            ? [15000, 18000, 22000, 25000]
            : [60000, 65000, 70000, 80000],
        backgroundColor: "rgba(255, 159, 64, 0.6)",
        borderColor: "rgba(255, 159, 64, 1)",
        borderWidth: 1,
      },
      {
        label: "Remittances (AED)",
        data:
          timeRange === "monthly"
            ? [2000, 2500, 2200, 3000, 2800]
            : timeRange === "quarterly"
            ? [7000, 8000, 8500, 9000]
            : [28000, 30000, 32000, 35000],
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  // Profit/Loss Chart Data (Project-wise)
  const projectProfitData = {
    labels: [
      "Project Abdullah",
      "Project Zain",
      "Project Omar",
      "Project Asim",
    ],
    datasets: [
      {
        label: "Profit/Loss (AED)",
        data: [10000, -5000, 15000, 12000],
        backgroundColor: [
          "rgba(75, 192, 192, 0.6)",
          "rgba(255, 99, 132, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(75, 192, 192, 0.6)",
        ],
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  // Line Chart Data for Actual vs Projected Profits
  const profitComparisonData = {
    labels:
      timeRange === "monthly"
        ? ["January", "February", "March", "April", "May"]
        : timeRange === "quarterly"
        ? ["Q1", "Q2", "Q3", "Q4"]
        : ["2020", "2021", "2022", "2023"],
    datasets: [
      {
        label: "Projected Profit (AED)",
        data:
          timeRange === "monthly"
            ? [12000, 15000, 17000, 25000, 30000]
            : timeRange === "quarterly"
            ? [45000, 50000, 60000, 70000]
            : [180000, 200000, 220000, 250000],
        fill: false,
        borderColor: "rgba(75, 192, 192, 1)",
        tension: 0.1,
      },
      {
        label: "Actual Profit (AED)",
        data:
          timeRange === "monthly"
            ? [10000, 12000, 16000, 22000, 27000]
            : timeRange === "quarterly"
            ? [40000, 45000, 55000, 65000]
            : [160000, 180000, 210000, 240000],
        fill: false,
        borderColor: "rgba(255, 99, 132, 1)",
        tension: 0.1,
      },
    ],
  };

  return (
    <Box p={4}>
      {/* Dashboard Section */}
      <Heading size="lg" mb={6}>
        Finance Dashboard
      </Heading>
      <Grid templateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={4}>
        {dashboardData.map((item) => (
          <DashboardBox
            key={item.name}
            icon={item.icon}
            name={item.name}
            value={item.value.toLocaleString()}
            type="money"
            backgroundColor={item.bg}
          />
        ))}
      </Grid>

      {/* Expense Chart Section */}
      <Box mt={8}>
        <Flex justify="space-between" align="center">
          <Heading size="md">Expenses Over Time</Heading>
          <Select
            width="150px"
            value={timeRange}
            onChange={handleTimeRangeChange}
          >
            <option value="monthly">Monthly</option>
            <option value="quarterly">Quarterly</option>
            <option value="yearly">Yearly</option>
          </Select>
          <IconButton
            icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            onClick={toggleOpen}
            variant="outline"
            aria-label="Toggle chart visibility"
          />
        </Flex>

        {isOpen && (
          <Box bg="white" p={4} borderRadius="md" borderWidth="1px" mt={4}>
            <Bar
              data={expenseData}
              options={{
                responsive: true,
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
              }}
            />
          </Box>
        )}
      </Box>

      {/* Profit/Loss Chart Section */}
      <Box mt={8}>
        <Flex justify="space-between" align="center">
          <Heading size="md">Profit/Loss Project-wise</Heading>
          <Select
            width="150px"
            value={timeRange}
            onChange={handleTimeRangeChange}
          >
            <option value="monthly">Monthly</option>
            <option value="quarterly">Quarterly</option>
            <option value="yearly">Yearly</option>
          </Select>
        </Flex>
        <Box bg="white" p={4} borderRadius="md" borderWidth="1px" mt={4}>
          <Bar
            data={projectProfitData}
            options={{
              responsive: true,
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
        </Box>
      </Box>

      {/* Actual vs Projected Profits Section */}
      <Box mt={8}>
        <Flex justify="space-between" align="center">
          <Heading size="md">Actual vs Projected Profits</Heading>
          <Select
            width="150px"
            value={timeRange}
            onChange={handleTimeRangeChange}
          >
            <option value="monthly">Monthly</option>
            <option value="quarterly">Quarterly</option>
            <option value="yearly">Yearly</option>
          </Select>
        </Flex>
        <Box bg="white" p={4} borderRadius="md" borderWidth="1px" mt={4}>
          <Line
            data={profitComparisonData}
            options={{
              responsive: true,
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectFinancialManagement;
