import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  IconButton,
  Text,
  Textarea,
  Heading,
  Collapse,
  useDisclosure,
  Image,
  Divider,
} from "@chakra-ui/react";
import { FaPlus, FaTrash, FaChevronUp, FaChevronDown } from "react-icons/fa";
import { LiaTasksSolid } from "react-icons/lia";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import GenericHeader from "../../commons/GenericHeader";
import {
  addActivity,
  addManpower,
  selectDutyById,
  updateDuty,
} from "../../store/dutiesPlanningSlice";
import PrimaryButton from "../../commons/PrimaryButton";
import { useDropzone } from "react-dropzone";

const AddNextDayPlan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const isEdit = Boolean(id);
  const dutiesPlanning = useSelector((state) => state.dutiesPlanning);
  const duty = useSelector((state) => selectDutyById(state, parseInt(id)));

  const [manpower, setManpower] = useState(duty ? duty.manpower : []);
  const [officeTasks, setOfficeTasks] = useState(duty ? duty.officeTasks : "");
  const [otherRemarks, setOtherRemarks] = useState("");
  const [foremanReport, setForemanReport] = useState("");
  const [foremanName, setForemanName] = useState(duty ? duty.foremanName : "");
  const [activityInput, setActivityInput] = useState(
    duty ? duty.activityInput : ""
  );
  const [manpowerInput, setManpowerInput] = useState("");
  const [date, setDate] = useState(duty ? duty.date : "");
  const [workImages, setWorkImages] = useState(duty ? duty.workImages : []);
  const [goingPhotos, setGoingPhotos] = useState([]);
  const [endPhotos, setEndPhotos] = useState([]);
  const { isOpen, onToggle } = useDisclosure();

  useEffect(() => {
    if (!isEdit) {
      const today = new Date().toISOString().split("T")[0];
      setDate(today);
    }
  }, [isEdit]);

  const handleAddManpower = () => {
    if (manpowerInput) {
      setManpower([...manpower, manpowerInput]);
      dispatch(
        addManpower({ id: duty ? duty.id : null, manpower: manpowerInput })
      );
      setManpowerInput("");
    }
  };

  const handleDeleteManpower = (index) => {
    const newManpower = manpower.filter((_, i) => i !== index);
    setManpower(newManpower);
  };

  const handleAddPlan = () => {
    const plan = {
      id: duty ? duty.id : new Date().getTime(),
      date,
      activityInput,
      officeTasks,
      otherRemarks,
      foremanName,
      foremanReport,
      manpower,
      workImages,
      goingPhotos,
      endPhotos,
    };

    if (isEdit) {
      dispatch(updateDuty(plan));
    } else {
      dispatch(addActivity(plan));
    }

    navigate("/foreman-reporting");
  };

  const onDrop = (acceptedFiles, setImageState) => {
    const newImages = acceptedFiles.map((file) => URL.createObjectURL(file));
    setImageState((prevImages) => [...prevImages, ...newImages]);
  };

  const handleDeleteImage = (index, setImageState, images) => {
    setImageState(images.filter((_, i) => i !== index));
  };

  return (
    <Box>
      <GenericHeader
        title={
          isEdit ? "Update Today's Plan Report" : "Add Today's Plan Report"
        }
        back="/foreman-reporting"
        icon={<LiaTasksSolid />}
      />
      <Box
        p={4}
        borderRadius="20"
        background={"white"}
        border={"3px solid gray"}
      >
        <FormControl mb={4}>
          <Flex gap={10} mb={4}>
            <FormControl flex={1}>
              <FormLabel>Project/Site</FormLabel>
              <Input placeholder="Enter project or site name" />
            </FormControl>

            <FormControl flex={1}>
              <FormLabel>Stage Name</FormLabel>
              <Input placeholder="Enter stage name" />
            </FormControl>

            <FormControl flex={1}>
              <FormLabel>Date</FormLabel>
              <Input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </FormControl>
          </Flex>
        </FormControl>

        {/* Encapsulated Today's Plan Report Section */}
        <Box
          borderWidth="1px"
          borderColor={"black"}
          borderRadius="md"
          p={4}
          mb={8}
        >
          <Heading
            size="md"
            mb={4}
            fontWeight="bold"
            color="gray.600"
            opacity="0.9"
          >
            Today's Plan Report
          </Heading>
          <Divider mb={4} />
          <Flex direction={{ base: "column", md: "row" }} gap={10}>
            {/* Column 1: Foreman Name, Work Description, Office Remarks, Other Remarks */}
            <Box flex="1" p={4} borderRadius="md">
              <FormControl mb={4}>
                <FormLabel>Foreman Name</FormLabel>
                <Input
                  value={foremanName}
                  onChange={(e) => setForemanName(e.target.value)}
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Work Description</FormLabel>
                <Textarea
                  value={activityInput}
                  onChange={(e) => setActivityInput(e.target.value)}
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Office Remarks</FormLabel>
                <Textarea
                  value={officeTasks}
                  onChange={(e) => setOfficeTasks(e.target.value)}
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Other Remarks</FormLabel>
                <Textarea
                  value={otherRemarks}
                  onChange={(e) => setOtherRemarks(e.target.value)}
                />
              </FormControl>
            </Box>

            {/* Column 2: Going Photos and End Photos */}
            <Box flex="1" p={4} borderRadius="md">
              <FormControl mb={4}>
                <FormLabel>Going Photos</FormLabel>
                <Box
                  {...useDropzone({
                    onDrop: (acceptedFiles) =>
                      onDrop(acceptedFiles, setGoingPhotos),
                    accept: "image/*",
                  })}
                  p={4}
                  borderWidth="2px"
                  borderRadius="md"
                  borderColor="gray.300"
                  textAlign="center"
                  cursor="pointer"
                >
                  <Text>
                    Drag & drop some files here, or click to select files
                  </Text>
                </Box>
                <Flex mt={2} wrap="wrap" gap={2}>
                  {goingPhotos.map((url, index) => (
                    <Box key={index} position="relative">
                      <Image
                        src={url}
                        alt={`Going ${index}`}
                        boxSize="100px"
                        objectFit="cover"
                      />
                      <IconButton
                        icon={<FaTrash />}
                        onClick={() =>
                          handleDeleteImage(index, setGoingPhotos, goingPhotos)
                        }
                        size="xs"
                        position="absolute"
                        top={0}
                        right={0}
                        colorScheme="red"
                      />
                    </Box>
                  ))}
                </Flex>
              </FormControl>

              <FormControl mb={4}>
                <FormLabel>End Photos</FormLabel>
                <Box
                  {...useDropzone({
                    onDrop: (acceptedFiles) =>
                      onDrop(acceptedFiles, setEndPhotos),
                    accept: "image/*",
                  })}
                  p={4}
                  borderWidth="2px"
                  borderRadius="md"
                  borderColor="gray.300"
                  textAlign="center"
                  cursor="pointer"
                >
                  <Text>
                    Drag & drop some files here, or click to select files
                  </Text>
                </Box>
                <Flex mt={2} wrap="wrap" gap={2}>
                  {endPhotos.map((url, index) => (
                    <Box key={index} position="relative">
                      <Image
                        src={url}
                        alt={`End ${index}`}
                        boxSize="100px"
                        objectFit="cover"
                      />
                      <IconButton
                        icon={<FaTrash />}
                        onClick={() =>
                          handleDeleteImage(index, setEndPhotos, endPhotos)
                        }
                        size="xs"
                        position="absolute"
                        top={0}
                        right={0}
                        colorScheme="red"
                      />
                    </Box>
                  ))}
                </Flex>
              </FormControl>
            </Box>
          </Flex>

          {/* Select Manpower Section */}
          <Box mt={8}>
            <Heading
              size="md"
              mb={4}
              fontWeight="bold"
              color="gray.600"
              opacity="0.9"
            >
              Select Manpower
            </Heading>
            <Divider mb={4} />
            <Flex mb={4}>
              <Select
                value={manpowerInput}
                onChange={(e) => setManpowerInput(e.target.value)}
                flex={1}
              >
                <option value="Plumber-Akram-32323">Plumber-Akram-32323</option>
                <option value="Electrician-Rahul-21321">
                  Electrician-Rahul-21321
                </option>
                <option value="Carpenter-Deepak-45654">
                  Carpenter-Deepak-45654
                </option>
              </Select>
              <IconButton
                icon={<FaPlus />}
                onClick={handleAddManpower}
                ml={2}
                variant="outline"
                colorScheme="gray"
              />
            </Flex>
            <Flex wrap="wrap" gap={4}>
              {manpower.map((person, index) => (
                <Box
                  key={index}
                  p={4}
                  borderWidth="1px"
                  borderRadius="md"
                  position="relative"
                >
                  <Text>{person}</Text>
                  <Text fontSize="sm" color="gray.500">
                    Designation
                  </Text>
                  <Text fontSize="sm" color="gray.500">
                    Rate
                  </Text>
                  <FormControl mt={2}>
                    <FormLabel>Performance </FormLabel>
                    <Input type="number" min={0} max={10} value={""} />
                  </FormControl>
                  <IconButton
                    icon={<FaTrash />}
                    onClick={() => handleDeleteManpower(index)}
                    size="xs"
                    position="absolute"
                    top={2}
                    right={2}
                    colorScheme="red"
                  />
                </Box>
              ))}
            </Flex>
          </Box>

          {/* Foreman Reporting Section */}
          <Box mt={8}>
            <Heading
              size="md"
              mb={4}
              fontWeight="bold"
              color="gray.600"
              opacity="0.9"
            >
              Foreman Reporting
            </Heading>
            <Divider mb={4} />
            <Flex gap={5}>
              <FormControl mb={4}>
                <FormLabel>Foreman Report</FormLabel>
                <Textarea
                  value={foremanReport}
                  onChange={(e) => setForemanReport(e.target.value)}
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Work Photos</FormLabel>
                <Box
                  {...useDropzone({
                    onDrop: (acceptedFiles) =>
                      onDrop(acceptedFiles, setWorkImages),
                    accept: "image/*",
                  })}
                  p={4}
                  borderWidth="2px"
                  borderRadius="md"
                  borderColor="gray.300"
                  textAlign="center"
                  cursor="pointer"
                >
                  <Text>
                    Drag & drop some files here, or click to select files
                  </Text>
                </Box>
                <Flex mt={2} wrap="wrap" gap={2}>
                  {workImages.map((url, index) => (
                    <Box key={index} position="relative">
                      <Image
                        src={url}
                        alt={`Work ${index}`}
                        boxSize="100px"
                        objectFit="cover"
                      />
                      <IconButton
                        icon={<FaTrash />}
                        onClick={() =>
                          handleDeleteImage(index, setWorkImages, workImages)
                        }
                        size="xs"
                        position="absolute"
                        top={0}
                        right={0}
                        colorScheme="red"
                      />
                    </Box>
                  ))}
                </Flex>
              </FormControl>
            </Flex>
          </Box>
        </Box>

        {/* Next Day Work Plan Section */}
        <Box mt={8}>
          <Heading
            size="md"
            mb={4}
            onClick={onToggle}
            cursor="pointer"
            fontWeight="bold"
            color="gray.600"
            opacity="0.9"
            bg="gray.100"
            p={2}
            borderRadius="md"
            display="flex"
            alignItems="center"
          >
            {isOpen ? <FaChevronUp /> : <FaChevronDown />}
            <Text ml={2}>Next Day Work Plan</Text>
          </Heading>
          <Divider mb={4} />
          <Collapse in={isOpen} animateOpacity>
            <FormControl mb={4}>
              <FormLabel>Work Description</FormLabel>
              <Textarea
                value={activityInput}
                onChange={(e) => setActivityInput(e.target.value)}
              />
            </FormControl>

            <Box mt={8}>
              <Heading
                size="md"
                mb={4}
                fontWeight="bold"
                color="gray.600"
                opacity="0.9"
              >
                Select Manpower
              </Heading>
              <Divider mb={4} />
              <Flex mb={4}>
                <Select
                  value={manpowerInput}
                  onChange={(e) => setManpowerInput(e.target.value)}
                  flex={1}
                >
                  <option value="Plumber-Akram-32323">
                    Plumber-Akram-32323
                  </option>
                  <option value="Electrician-Akram-21321">
                    Electrician-Rahul-21321
                  </option>
                  <option value="Carpenter-ASlam-45654">
                    Carpenter-ASlam-45654
                  </option>
                </Select>
                <IconButton
                  icon={<FaPlus />}
                  onClick={handleAddManpower}
                  ml={2}
                  variant="outline"
                  colorScheme="gray"
                />
              </Flex>
              <Flex wrap="wrap" gap={4}>
                {manpower.map((person, index) => (
                  <Box
                    key={index}
                    p={4}
                    borderWidth="1px"
                    borderRadius="md"
                    position="relative"
                  >
                    <Text>{person}</Text>
                    <Text fontSize="sm" color="gray.500">
                      Designation
                    </Text>
                    <Text fontSize="sm" color="gray.500">
                      Rate
                    </Text>
                    <IconButton
                      icon={<FaTrash />}
                      onClick={() => handleDeleteManpower(index)}
                      size="xs"
                      position="absolute"
                      top={2}
                      right={2}
                      colorScheme="red"
                    />
                  </Box>
                ))}
              </Flex>
            </Box>

            <FormControl mb={4} mt={4}>
              <FormLabel>Use Previous List</FormLabel>
              <Select>
                <option value="PreviousList1">Previous List 1</option>
                <option value="PreviousList2">Previous List 2</option>
                <option value="PreviousList3">Previous List 3</option>
              </Select>
            </FormControl>
          </Collapse>
        </Box>

        <Flex justifyContent="center" mt={4}>
          <PrimaryButton
            title={isEdit ? "Update Plan" : "Add Plan"}
            onClick={handleAddPlan}
          />
        </Flex>
      </Box>
    </Box>
  );
};

export default AddNextDayPlan;
