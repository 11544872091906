import React, { useState, useMemo, useCallback } from "react";
import {
  Box,
  Button,
  Flex,
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  useDisclosure,
  Select,
  FormControl,
  FormLabel,
  Switch,
  Image,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { AddIcon } from "@chakra-ui/icons";
import debounce from "lodash/debounce";
import GenericHeader from "../../commons/GenericHeader";
import SearchInput from "../../commons/SearchInput";
import GenericGrid from "../Procurement/GenericGrid";
import { useNavigate } from "react-router-dom";
import DashboardBox from "../../commons/DashboardBox";
import { selectWorkDone, deleteWorkDone } from "../../store/workDoneSlice";

const WorkDoneList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const works = useSelector(selectWorkDone);

  const [rowData, setRowData] = useState(works);
  const [originalData] = useState(works);
  const [sortConfig, setSortConfig] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(works);

  const toast = useToast();
  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();
  const {
    isOpen: isColumnModalOpen,
    onOpen: onOpenColumnModal,
    onClose: onCloseColumnModal,
  } = useDisclosure();

  const [selectedEmpName, setSelectedEmpName] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const filters = useMemo(
    () => ({
      empName: selectedEmpName,
      date: selectedDate,
    }),
    [selectedEmpName, selectedDate]
  );

  const debouncedApplySearchFilter = useCallback(
    debounce((text) => applySearchFilter(text), 300),
    []
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedApplySearchFilter(text);
  };

  const applySearchFilter = (text) => {
    const filteredData = originalData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(text.toLowerCase())
      )
    );
    setFilteredData(filteredData);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredData(originalData);
  };

  const handleTagClose = (filterType) => {
    let newSelectedEmpName = selectedEmpName;
    let newSelectedDate = selectedDate;

    switch (filterType) {
      case "empName":
        newSelectedEmpName = "";
        break;
      case "date":
        newSelectedDate = "";
        break;
      default:
        break;
    }

    const filteredData = originalData.filter((row) => {
      return (
        (!newSelectedEmpName || row.empName === newSelectedEmpName) &&
        (!newSelectedDate || row.date === newSelectedDate)
      );
    });

    setSelectedEmpName(newSelectedEmpName);
    setSelectedDate(newSelectedDate);
    setRowData(filteredData);
    setFilteredData(filteredData);

    toast({
      title: "Filter Removed",
      description: `Filter for ${filterType} has been removed.`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const applyFilter = () => {
    const filteredData = originalData.filter((row) => {
      return (
        (!selectedEmpName || row.empName === selectedEmpName) &&
        (!selectedDate || row.date === selectedDate)
      );
    });

    setRowData(filteredData);
    setFilteredData(filteredData);
    toast({
      title: "Filters Applied",
      description: "Data has been filtered",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const clearFilter = () => {
    setSelectedEmpName("");
    setSelectedDate("");
    setRowData(originalData);
    setFilteredData(originalData);
    toast({
      title: "Filters Cleared",
      description: "Filters have been cleared",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const handleRowClick = (row) => {
    navigate(`/daily-work-reporting/edit/${row.id}`);
  };

  const workDoneColumns = [
    {
      id: "sno",
      header: "Sno",
      accessor: (item, index) => index + 1,
      show: true,
      width: 20,
    },
    {
      id: "date",
      header: "Date",
      accessor: (item) => item.date,
      show: true,
      width: 150,
    },
    {
      id: "empName",
      header: "Employee Name",
      accessor: (item) => item.empName,
      show: true,
      width: 150,
    },
    {
      id: "workDescription",
      header: "Work Done Description",
      accessor: (item) => item.workDescription,
      show: true,
      width: 250,
    },
    {
      id: "images",
      header: "Images",
      render: (row) =>
        row.images.length > 0 ? (
          <Flex>
            {row.images.map((image, index) => (
              <Image
                key={index}
                src={image}
                alt="Work Done"
                boxSize="50px"
                mr={2}
              />
            ))}
          </Flex>
        ) : (
          "No Images"
        ),
      show: true,
      width: 150,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    workDoneColumns.filter((col) => col.show).map((col) => col.id)
  );

  const handleVisibleColumnsChange = (columnId) => {
    const updatedColumns = visibleColumns.includes(columnId)
      ? visibleColumns.filter((col) => col !== columnId)
      : [...visibleColumns, columnId];
    setVisibleColumns(updatedColumns);
  };

  const handleSort = (column) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === column &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key: column, direction: direction });

    const sortedData = [...rowData].sort((a, b) => {
      if (a[column] < b[column]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[column] > b[column]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredData(sortedData);
  };

  return (
    <Box>
      <GenericHeader
        title="Work Done List"
        to={"/daily-work-reporting/add"}
        addButtonName={"Work Done"}
        icon={<AddIcon />}
      />
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        <Button leftIcon={<AddIcon />} onClick={onOpenFilterModal}>
          Filter
        </Button>
        <SearchInput
          placeholder="Search Work Done"
          value={searchText}
          onChange={handleSearchInputChange}
          onClear={handleClearSearch}
        />
        <Button onClick={onOpenColumnModal}>Toggle Columns</Button>
      </Flex>
      <Flex mb={4}>
        <Flex>
          {filters.empName && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Employee: ${filters.empName}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("empName")} />
            </Tag>
          )}
          {filters.date && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Date: ${filters.date}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("date")} />
            </Tag>
          )}
        </Flex>
      </Flex>
      <Modal isOpen={isFilterModalOpen} onClose={onCloseFilterModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Select
                placeholder="Select Employee"
                onChange={(e) => setSelectedEmpName(e.target.value)}
                mb={4}
                value={selectedEmpName}
              >
                {originalData.map((row) => (
                  <option key={row.empName} value={row.empName}>
                    {row.empName}
                  </option>
                ))}
              </Select>
              <Select
                placeholder="Select Date"
                onChange={(e) => setSelectedDate(e.target.value)}
                value={selectedDate}
              >
                {originalData.map((row) => (
                  <option key={row.date} value={row.date}>
                    {row.date}
                  </option>
                ))}
              </Select>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={applyFilter} mr={3}>
              Apply Filter
            </Button>
            <Button variant="ghost" onClick={clearFilter}>
              Clear Filter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isColumnModalOpen} onClose={onCloseColumnModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Toggle Columns</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {workDoneColumns.map((col) => (
                <FormControl
                  display="flex"
                  alignItems="center"
                  key={col.id}
                  mb={2}
                >
                  <FormLabel htmlFor={col.id} mb="0" flex="1">
                    {col.header}
                  </FormLabel>
                  <Switch
                    id={col.id}
                    isChecked={visibleColumns.includes(col.id)}
                    onChange={() => handleVisibleColumnsChange(col.id)}
                  />
                </FormControl>
              ))}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onCloseColumnModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box>
        <GenericGrid
          columns={workDoneColumns}
          data={filteredData}
          title="Work Done List"
          visibleColumnsState={visibleColumns}
          filterState={filters}
          onVisibleColumnsChange={handleVisibleColumnsChange}
          onSort={handleSort}
          sortConfig={sortConfig}
          onRowClick={handleRowClick}
        />
      </Box>
    </Box>
  );
};

export default WorkDoneList;
