import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Input,
  Select,
  Textarea,
  useDisclosure,
  Text,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { Pie, Bar } from "react-chartjs-2";
import { GrMoney } from "react-icons/gr";
import { IoAddOutline, IoTrashOutline, IoPencil } from "react-icons/io5";
import DashboardBox from "../../commons/DashboardBox";
import { updateTask, addTask, deleteTask } from "../../store/stageTasksSlice";

const StageTaskContainer = () => {
  const dispatch = useDispatch();
  const { stages } = useSelector((state) => state.stageTasks);
  const firstStageTasks = stages[0].tasks;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const cancelRef = React.useRef();
  const toast = useToast();

  const totalBudget = 100000; // Example total budget

  // Pie chart data
  const pieData = {
    labels: [],
    datasets: [
      {
        data: [10, 15, 20, 10, 20, 25], // Example data for pie chart
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#66FF66",
          "#FF9966",
        ],
      },
    ],
  };

  // Dummy data for bar chart
  const barData = {
    labels: ["Amit", "Rahul", "Sameer", "Sana", "Rizwan"],
    datasets: [
      {
        label: "Number of Tasks",
        data: [12, 19, 3, 5, 8],
        backgroundColor: [
          "#36A2EB",
          "#FF6384",
          "#FFCE56",
          "#66FF66",
          "#FF9966",
        ],
      },
    ],
  };

  const handleOpenDrawer = (task = null, index = null) => {
    if (task) {
      setFormData(task);
      setIsEdit(true);
      setCurrentTaskIndex(index);
    } else {
      setFormData({
        taskName: "",
        startDate: "",
        endDate: "",
        priority: "Medium",
        dependency: "",
        assignedTo: "",
        status: "Not Started",
        notes: "",
        finalRemarks: "",
      });
      setIsEdit(false);
      setCurrentTaskIndex(null);
    }
    onOpen();
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    if (isEdit && currentTaskIndex !== null) {
      // Update existing task
      dispatch(
        updateTask({
          stageIndex: 0,
          taskIndex: currentTaskIndex,
          updatedTask: formData,
        })
      );
    } else {
      // Add new task
      dispatch(addTask({ stageIndex: 0, newTask: { ...formData } }));
    }
    onClose();
  };

  const handleDeleteTask = (taskIndex) => {
    setIsDeleteOpen(true);
    setTaskToDelete(taskIndex);
  };

  const confirmDeleteTask = () => {
    dispatch(deleteTask({ stageIndex: 0, taskIndex: taskToDelete }));
    toast({
      title: "Task Deleted",
      description: "The task has been deleted successfully.",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
    setIsDeleteOpen(false);
  };

  // Search filtering logic
  const filteredTasks = firstStageTasks.filter((task) =>
    task.taskName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate metrics
  const today = new Date().toISOString().split("T")[0]; // Current date in YYYY-MM-DD
  const overdueTasks = firstStageTasks.filter(
    (task) => task.endDate < today
  ).length;
  const completedTasks = firstStageTasks.filter(
    (task) => task.status === "Completed"
  ).length;
  const daysUntilDue = firstStageTasks
    .filter((task) => task.endDate >= today)
    .map((task) => {
      const dueDate = new Date(task.endDate);
      const diffTime = dueDate - new Date(today);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert ms to days
    });

  const minDaysUntilDue =
    daysUntilDue.length > 0 ? Math.min(...daysUntilDue) : 0;

  return (
    <Box
      p="24px"
      border="2px solid #D3D3D3" // Grayish color and 2px solid border
      borderRadius="md"
    >
      {/* Dashboard Boxes */}
      <Flex justifyContent="space-between" mb={6} gap={2}>
        <DashboardBox
          icon={GrMoney}
          name="Total Tasks"
          value={firstStageTasks.length.toString()}
          backgroundColor="#FF9966"
        />
        <DashboardBox
          icon={GrMoney}
          name="Overdue Tasks"
          value={overdueTasks.toString()}
          backgroundColor="#FF4C4C"
        />
        <DashboardBox
          icon={GrMoney}
          name="Days Until Due Date"
          value={minDaysUntilDue.toString()}
          backgroundColor="#FFA500"
        />
        <DashboardBox
          icon={GrMoney}
          name="Completed Tasks"
          value={completedTasks.toString()}
          backgroundColor="#6DBE45"
        />
      </Flex>

      {/* Chart Section */}
      <Flex justifyContent="space-between" mb={8}>
        {/* Pie Chart */}
        <Box w="45%" h={250}>
          <Pie
            data={pieData}
            options={{ plugins: { legend: { display: false } } }}
          />
        </Box>
        {/* Bar Chart */}
        <Box w="45%" h={250}>
          <Bar data={barData} />
        </Box>
      </Flex>

      {/* Task List Section */}
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        <Text fontWeight="bold" fontSize="xl">
          Task List for {stages[0].stageName}
        </Text>
        <Flex>
          <Input
            placeholder="Search tasks..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            mr={2}
          />
          <IconButton
            icon={<IoAddOutline />}
            onClick={() => handleOpenDrawer()}
          />
        </Flex>
      </Flex>

      {/* Table with search, sticky header, S.No, and scroll */}
      <Box height="300px" overflowY="scroll">
        <Table variant="simple">
          <Thead position="sticky" top={0} zIndex={1} bg="white">
            <Tr>
              <Th>S. No</Th>
              <Th>Task Name</Th>
              <Th>Assigned To</Th>
              <Th>Start Date</Th>
              <Th>End Date</Th>
              <Th>Status</Th>
              <Th>Priority</Th>
              <Th>Dependency</Th>
              <Th>Notes</Th>
              <Th>Final Remarks</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredTasks.map((task, index) => (
              <Tr key={index}>
                <Td>{index + 1}</Td> {/* Serial Number */}
                <Td>{task.taskName}</Td>
                <Td>{task.assignedTo}</Td>
                <Td>{task.startDate}</Td>
                <Td>{task.endDate}</Td>
                <Td>{task.status}</Td>
                <Td>{task.priority}</Td>
                <Td>{task.dependency}</Td>
                <Td>{task.notes}</Td>
                <Td>{task.finalRemarks}</Td>
                <Td>
                  <IconButton
                    size="sm"
                    icon={<IoPencil />}
                    onClick={() => handleOpenDrawer(task, index)}
                    mr={2}
                  />
                  <IconButton
                    size="sm"
                    icon={<IoTrashOutline />}
                    onClick={() => handleDeleteTask(index)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {/* Drawer for Add/Edit Task */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{isEdit ? "Edit Task" : "Add New Task"}</DrawerHeader>

          <DrawerBody>
            <Input
              placeholder="Task Name"
              name="taskName"
              value={formData.taskName || ""}
              onChange={handleInputChange}
              mb={4}
            />
            <Input
              placeholder="Assigned To"
              name="assignedTo"
              value={formData.assignedTo || ""}
              onChange={handleInputChange}
              mb={4}
            />
            <Input
              placeholder="Start Date"
              type="date"
              name="startDate"
              value={formData.startDate || ""}
              onChange={handleInputChange}
              mb={4}
            />
            <Input
              placeholder="End Date"
              type="date"
              name="endDate"
              value={formData.endDate || ""}
              onChange={handleInputChange}
              mb={4}
            />
            <Select
              placeholder="Status"
              name="status"
              value={formData.status || ""}
              onChange={handleInputChange}
              mb={4}
            >
              <option value="Not Started">Not Started</option>
              <option value="In Progress">In Progress</option>
              <option value="Completed">Completed</option>
            </Select>
            <Select
              placeholder="Priority"
              name="priority"
              value={formData.priority || ""}
              onChange={handleInputChange}
              mb={4}
            >
              <option value="Low">Low</option>
              <option value="Medium">Medium</option>
              <option value="High">High</option>
            </Select>
            <Input
              placeholder="Dependency"
              name="dependency"
              value={formData.dependency || ""}
              onChange={handleInputChange}
              mb={4}
            />
            <Textarea
              placeholder="Notes"
              name="notes"
              value={formData.notes || ""}
              onChange={handleInputChange}
              mb={4}
            />
            <Textarea
              placeholder="Final Remarks"
              name="finalRemarks"
              value={formData.finalRemarks || ""}
              onChange={handleInputChange}
            />
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleSave}>
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      {/* Delete Confirmation AlertDialog */}
      <AlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Task
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this task?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleteOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmDeleteTask} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default StageTaskContainer;
