import { createSlice } from "@reduxjs/toolkit";

const initialVehicles = [
  {
    sno: 1,
    type: "Car",
    plateNo: "ABC123",
    mulkyaExpiry: "2023-12-31",
    insuranceExpiry: "2023-11-30",
    maintenanceCost: 500,
    purchasePrice: 20000,
    mulkyaRenewalCost: 300,
    driverName: "Asim Khan",
    dateHandover: "2023-01-01",
    dateReturn: "2023-12-01",
    finesCount: 2,
    todoMaintenance: "Oil Change",
    totalCost: 100,
    driverHistory: [
      {
        driverName: "Asim Khan",
        dateHandover: "2023-01-01",
        dateReturn: "2023-12-01",
      },
    ],
    fines: [{ amount: 100, date: "2023-06-01", reason: "Speeding" }],
    maintenanceHistory: [
      {
        type: "Oil Change",
        date: "2023-06-15",
        cost: 200,
        description: "Routine oil change",
      },
      {
        type: "Brake Pad",
        date: "2023-07-10",
        cost: 300,
        description: "Replaced brake pads",
      },
    ],
  },
  // Other vehicles...
];

const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState: initialVehicles,
  reducers: {
    addFine: (state, action) => {
      const { sno, fine } = action.payload;
      const vehicle = state.find((v) => v.sno === sno);
      if (vehicle) {
        vehicle.fines.push(fine);
        vehicle.finesCount += 1;
      }
    },
    changeDriver: (state, action) => {
      const { sno, newDriver } = action.payload;
      const vehicle = state.find((v) => v.sno === sno);
      if (vehicle) {
        vehicle.driverHistory.push({
          driverName: vehicle.driverName,
          dateHandover: vehicle.dateHandover,
          dateReturn: new Date().toISOString().split("T")[0],
        });
        vehicle.driverName = newDriver;
        vehicle.dateHandover = new Date().toISOString().split("T")[0];
      }
    },
    addMaintenance: (state, action) => {
      const { sno, maintenance } = action.payload;
      const vehicle = state.find((v) => v.sno === sno);
      if (vehicle) {
        vehicle.todoMaintenance = maintenance;
        vehicle.maintenanceHistory.push(maintenance); // Add to maintenance history
      }
    },
  },
});

export const { addFine, changeDriver, addMaintenance } = vehiclesSlice.actions;
export const selectVehicles = (state) => state.vehicles;
export const selectVehicleBySno = (state, sno) =>
  state.vehicles.find((v) => v.sno === sno);

export default vehiclesSlice.reducer;
