import { createSlice } from "@reduxjs/toolkit";

const initialProjectPlan = [
  {
    stageName: "Stage 1",
    tasks: [
      {
        taskName:
          "Mobilization & site preparation, Temporary Facilities, Site Fencing",
        startDate: "2024-09-01",
        endDate: "2024-09-07",
        priority: "High",
        dependency: "None",
      },
      {
        taskName: "Earth works + Sub Structure",
        startDate: "2024-09-10",
        endDate: "2024-09-16",
        priority: "Medium",
        dependency:
          "Mobilization & site preparation, Temporary Facilities, Site Fencing",
      },
      {
        taskName: "Excavation",
        startDate: "2024-09-19",
        endDate: "2024-09-25",
        priority: "Medium",
        dependency: "Earth works + Sub Structure",
      },
      {
        taskName: "Levelling, Compaction and Curing",
        startDate: "2024-09-28",
        endDate: "2024-10-04",
        priority: "Medium",
        dependency: "Excavation",
      },
      {
        taskName: "Polythene Sheet (and Anti Termite if required)",
        startDate: "2024-10-07",
        endDate: "2024-10-13",
        priority: "Medium",
        dependency: "Levelling, Compaction and Curing",
      },
      {
        taskName: "Road Base (if required)",
        startDate: "2024-10-16",
        endDate: "2024-10-22",
        priority: "Medium",
        dependency: "Polythene Sheet (and Anti Termite if required)",
      },
      {
        taskName: "P.C.C LAYOUT",
        startDate: "2024-10-25",
        endDate: "2024-10-31",
        priority: "Medium",
        dependency: "Road Base (if required)",
      },
      {
        taskName: "WOODen FOOTINGS BOXES Carpentary works",
        startDate: "2024-11-03",
        endDate: "2024-11-09",
        priority: "Medium",
        dependency: "P.C.C LAYOUT",
      },
      {
        taskName: "PCC CONCRETE (for Footings+Solid Block)",
        startDate: "2024-11-12",
        endDate: "2024-11-18",
        priority: "Medium",
        dependency: "WOODen FOOTINGS BOXES Carpentary works",
      },
      {
        taskName: "Curing and removing shutters",
        startDate: "2024-11-21",
        endDate: "2024-11-27",
        priority: "Medium",
        dependency: "PCC CONCRETE (for Footings+Solid Block)",
      },
      {
        taskName:
          "CLEAN SHUTTERS, REMOVE NAILS, PROPER PACEMENT/STORAGE, HOUSE KEEPING",
        startDate: "2024-11-30",
        endDate: "2024-12-06",
        priority: "Medium",
        dependency: "Curing and removing shutters",
      },
      {
        taskName: "Footing Steel Work",
        startDate: "2024-12-09",
        endDate: "2024-12-15",
        priority: "Medium",
        dependency:
          "CLEAN SHUTTERS, REMOVE NAILS, PROPER PACEMENT/STORAGE, HOUSE KEEPING",
      },
      {
        taskName: "Footings+STB Shuttering Carpentary work",
        startDate: "2024-12-18",
        endDate: "2024-12-24",
        priority: "Medium",
        dependency: "Footing Steel Work",
      },
      {
        taskName: "Footing Concrete",
        startDate: "2024-12-27",
        endDate: "2025-01-02",
        priority: "Medium",
        dependency: "Footings+STB Shuttering Carpentary work",
      },
      {
        taskName: "Curing and removing shutters",
        startDate: "2025-01-05",
        endDate: "2025-01-11",
        priority: "Medium",
        dependency: "Footing Concrete",
      },
      {
        taskName:
          "CLEAN SHUTTERS, REMOVE NAILS, PROPER PACEMENT/STORAGE, HOUSE KEEPING",
        startDate: "2025-01-14",
        endDate: "2025-01-20",
        priority: "Medium",
        dependency: "Curing and removing shutters",
      },
    ],
  },
  {
    stageName: "Stage 2",
    tasks: [
      {
        taskName: "Soilid Block work",
        startDate: "2025-01-23",
        endDate: "2025-01-29",
        priority: "Medium",
        dependency:
          "CLEAN SHUTTERS, REMOVE NAILS, PROPER PACEMENT/STORAGE, HOUSE KEEPING (Stage 1)",
      },
      {
        taskName: "Neck Columns",
        startDate: "2025-02-01",
        endDate: "2025-02-07",
        priority: "Medium",
        dependency: "Soilid Block work",
      },
      {
        taskName: "BACKFILLING UPTO TIE BEAM LEVEL",
        startDate: "2025-02-10",
        endDate: "2025-02-16",
        priority: "Medium",
        dependency: "Neck Columns",
      },
      {
        taskName: "Levelling, Compaction and Curing",
        startDate: "2025-02-19",
        endDate: "2025-02-25",
        priority: "Medium",
        dependency: "BACKFILLING UPTO TIE BEAM LEVEL",
      },
      {
        taskName: "Tie Beams Steel Work",
        startDate: "2025-02-28",
        endDate: "2025-03-06",
        priority: "Medium",
        dependency: "Levelling, Compaction and Curing",
      },
      {
        taskName: "Tie Beams Shuttering Carpentary work",
        startDate: "2025-03-09",
        endDate: "2025-03-15",
        priority: "Medium",
        dependency: "Tie Beams Steel Work",
      },
      {
        taskName: "MEP sleeves",
        startDate: "2025-03-18",
        endDate: "2025-03-24",
        priority: "Medium",
        dependency: "Tie Beams Shuttering Carpentary work",
      },
      {
        taskName: "Tie Beams Concrete",
        startDate: "2025-03-27",
        endDate: "2025-04-02",
        priority: "Medium",
        dependency: "MEP sleeves",
      },
      {
        taskName: "Curing and removing shutters",
        startDate: "2025-04-05",
        endDate: "2025-04-11",
        priority: "Medium",
        dependency: "Tie Beams Concrete",
      },
      {
        taskName:
          "CLEAN SHUTTERS, REMOVE NAILS, PROPER PACEMENT/STORAGE, HOUSE KEEPING",
        startDate: "2025-04-14",
        endDate: "2025-04-20",
        priority: "Medium",
        dependency: "Curing and removing shutters",
      },
      {
        taskName: "Sewerage Pipes for Bathrooms, Kitchen etc.",
        startDate: "2025-04-23",
        endDate: "2025-04-29",
        priority: "Medium",
        dependency:
          "CLEAN SHUTTERS, REMOVE NAILS, PROPER PACEMENT/STORAGE, HOUSE KEEPING (Stage 2)",
      },
      {
        taskName: "Levelling, Compaction and Curing",
        startDate: "2025-05-02",
        endDate: "2025-05-08",
        priority: "Medium",
        dependency: "Sewerage Pipes for Bathrooms, Kitchen etc.",
      },
      {
        taskName: "Polythene and Anti Termite",
        startDate: "2025-05-11",
        endDate: "2025-05-17",
        priority: "Medium",
        dependency: "Levelling, Compaction and Curing",
      },
      {
        taskName: "BRC Mesh Steel for Floor",
        startDate: "2025-05-20",
        endDate: "2025-05-26",
        priority: "Medium",
        dependency: "Polythene and Anti Termite",
      },
      {
        taskName: "Grade Slab floor concrete",
        startDate: "2025-05-29",
        endDate: "2025-06-04",
        priority: "Medium",
        dependency: "BRC Mesh Steel for Floor",
      },
      {
        taskName: "Super Structure",
        startDate: "2025-06-07",
        endDate: "2025-06-13",
        priority: "Medium",
        dependency: "Grade Slab floor concrete",
      },
      {
        taskName: "Columns",
        startDate: "2025-06-16",
        endDate: "2025-06-22",
        priority: "Medium",
        dependency: "Super Structure",
      },
      {
        taskName: "GF Columns Axis work",
        startDate: "2025-06-25",
        endDate: "2025-07-01",
        priority: "Medium",
        dependency: "Columns",
      },
      {
        taskName: "GF Columns steel work",
        startDate: "2025-07-04",
        endDate: "2025-07-10",
        priority: "Medium",
        dependency: "GF Columns Axis work",
      },
      {
        taskName: "GF Columns shuttering",
        startDate: "2025-07-13",
        endDate: "2025-07-19",
        priority: "Medium",
        dependency: "GF Columns steel work",
      },
      {
        taskName: "GF Columns concrete",
        startDate: "2025-07-22",
        endDate: "2025-07-28",
        priority: "Medium",
        dependency: "GF Columns shuttering",
      },
      {
        taskName: "Curing and removing shutters",
        startDate: "2025-07-31",
        endDate: "2025-08-06",
        priority: "Medium",
        dependency: "GF Columns concrete",
      },
      {
        taskName:
          "CLEAN SHUTTERS, REMOVE NAILS, PROPER PACEMENT/STORAGE, HOUSE KEEPING",
        startDate: "2025-08-09",
        endDate: "2025-08-15",
        priority: "Medium",
        dependency: "Curing and removing shutters",
      },
    ],
  },
  {
    stageName: "Stage 3",
    tasks: [
      {
        taskName: "Slab",
        startDate: "2025-08-18",
        endDate: "2025-08-24",
        priority: "Medium",
        dependency:
          "CLEAN SHUTTERS, REMOVE NAILS, PROPER PACEMENT/STORAGE, HOUSE KEEPING (Stage 2)",
      },
      {
        taskName: "Scaffolding",
        startDate: "2025-08-27",
        endDate: "2025-09-02",
        priority: "Medium",
        dependency: "Slab",
      },
      {
        taskName: "Bottom of Beams",
        startDate: "2025-09-05",
        endDate: "2025-09-11",
        priority: "Medium",
        dependency: "Scaffolding",
      },
      {
        taskName: "Plywood fixing",
        startDate: "2025-09-14",
        endDate: "2025-09-20",
        priority: "Medium",
        dependency: "Bottom of Beams",
      },
      {
        taskName: "Steel work",
        startDate: "2025-09-23",
        endDate: "2025-09-29",
        priority: "Medium",
        dependency: "Plywood fixing",
      },
      {
        taskName: "Electrical pipes & others sleeve",
        startDate: "2025-10-02",
        endDate: "2025-10-08",
        priority: "Medium",
        dependency: "Steel work",
      },
      {
        taskName: "Slab concrete",
        startDate: "2025-10-11",
        endDate: "2025-10-17",
        priority: "Medium",
        dependency: "Electrical pipes & others sleeve",
      },
      {
        taskName: "Parapet Blockwork",
        startDate: "2025-10-20",
        endDate: "2025-10-26",
        priority: "Medium",
        dependency: "Slab concrete",
      },
      {
        taskName: "Parapet steel",
        startDate: "2025-10-29",
        endDate: "2025-11-04",
        priority: "Medium",
        dependency: "Parapet Blockwork",
      },
      {
        taskName: "Parapet shuttering",
        startDate: "2025-11-07",
        endDate: "2025-11-13",
        priority: "Medium",
        dependency: "Parapet steel",
      },
      {
        taskName: "Parapet concrete",
        startDate: "2025-11-16",
        endDate: "2025-11-22",
        priority: "Medium",
        dependency: "Parapet shuttering",
      },
      {
        taskName: "Removing of Scaffolding + shuttering",
        startDate: "2025-11-25",
        endDate: "2025-12-01",
        priority: "Medium",
        dependency: "Parapet concrete",
      },
    ],
  },
  {
    stageName: "Stage 4",
    tasks: [
      {
        taskName: "Block Work",
        startDate: "2025-12-04",
        endDate: "2025-12-10",
        priority: "Medium",
        dependency: "Removing of Scaffolding + shuttering (Stage 3)",
      },
      {
        taskName: "Internal & External",
        startDate: "2025-12-13",
        endDate: "2025-12-19",
        priority: "Medium",
        dependency: "Block Work",
      },
      {
        taskName: "Lintels",
        startDate: "2025-12-22",
        endDate: "2025-12-28",
        priority: "Medium",
        dependency: "Internal & External",
      },
      {
        taskName: "Plaster Work",
        startDate: "2025-12-31",
        endDate: "2026-01-06",
        priority: "Medium",
        dependency: "Lintels",
      },
      {
        taskName: "Internal Plaster",
        startDate: "2026-01-09",
        endDate: "2026-01-15",
        priority: "Medium",
        dependency: "Plaster Work",
      },
      {
        taskName: "External Plaster",
        startDate: "2026-01-18",
        endDate: "2026-01-24",
        priority: "Medium",
        dependency: "Internal Plaster",
      },
      {
        taskName: "Plumbing work:-",
        startDate: "2026-01-27",
        endDate: "2026-02-02",
        priority: "Medium",
        dependency: "External Plaster",
      },
      {
        taskName: "Water Supply & Drainage pipes (External)",
        startDate: "2026-02-05",
        endDate: "2026-02-11",
        priority: "Medium",
        dependency: "Plumbing work:-",
      },
      {
        taskName:
          "Internal water and drainage supply pipes (Bathrooms, Laundry, Kitchen)",
        startDate: "2026-02-14",
        endDate: "2026-02-20",
        priority: "Medium",
        dependency: "Water Supply & Drainage pipes (External)",
      },
      {
        taskName: "Vent, rain water and roof pipes",
        startDate: "2026-02-23",
        endDate: "2026-02-29",
        priority: "Medium",
        dependency:
          "Internal water and drainage supply pipes (Bathrooms, Laundry, Kitchen)",
      },
      {
        taskName: "Drainage pipes & points for AC unit",
        startDate: "2026-03-03",
        endDate: "2026-03-09",
        priority: "Medium",
        dependency: "Vent, rain water and roof pipes",
      },
      {
        taskName: "Sanitary Wares for Bath and Wash",
        startDate: "2026-03-12",
        endDate: "2026-03-18",
        priority: "Medium",
        dependency: "Drainage pipes & points for AC unit",
      },
      {
        taskName: "Manholes (Including pipe & Cover)",
        startDate: "2026-03-21",
        endDate: "2026-03-27",
        priority: "Medium",
        dependency: "Sanitary Wares for Bath and Wash",
      },
      {
        taskName: "Water tanks",
        startDate: "2026-03-30",
        endDate: "2026-04-05",
        priority: "Medium",
        dependency: "Manholes (Including pipe & Cover)",
      },
      {
        taskName: "Automatic pressure water pump",
        startDate: "2026-04-08",
        endDate: "2026-04-14",
        priority: "Medium",
        dependency: "Water tanks",
      },
      {
        taskName: "Septic Tank and Soakaway",
        startDate: "2026-04-17",
        endDate: "2026-04-23",
        priority: "Medium",
        dependency: "Automatic pressure water pump",
      },
      {
        taskName: "Electrical work:-",
        startDate: "2026-04-26",
        endDate: "2026-05-02",
        priority: "Medium",
        dependency: "Septic Tank and Soakaway",
      },
      {
        taskName: "Dewa approved drawings",
        startDate: "2026-05-05",
        endDate: "2026-05-11",
        priority: "Medium",
        dependency: "Electrical work:-",
      },
      {
        taskName: "Owner approved points locations",
        startDate: "2026-05-14",
        endDate: "2026-05-20",
        priority: "Medium",
        dependency: "Dewa approved drawings",
      },
      {
        taskName:
          "Conduits for Switch sockets, AC's, Telephone, TV, Intercom, pumps, gate motor)",
        startDate: "2026-05-23",
        endDate: "2026-05-29",
        priority: "Medium",
        dependency: "Owner approved points locations",
      },
      {
        taskName: "Accessories GI boxes etc",
        startDate: "2026-06-01",
        endDate: "2026-06-07",
        priority: "Medium",
        dependency:
          "Conduits for Switch sockets, AC's, Telephone, TV, Intercom, pumps, gate motor)",
      },
      {
        taskName: "Wiring",
        startDate: "2026-06-10",
        endDate: "2026-06-16",
        priority: "Medium",
        dependency: "Accessories GI boxes etc",
      },
      {
        taskName: "DBS SMDB etc",
        startDate: "2026-06-19",
        endDate: "2026-06-25",
        priority: "Medium",
        dependency: "Wiring",
      },
      {
        taskName: "Electrical Isolators",
        startDate: "2026-06-28",
        endDate: "2026-07-04",
        priority: "Medium",
        dependency: "DBS SMDB etc",
      },
      {
        taskName: "Water Heaters",
        startDate: "2026-07-07",
        endDate: "2026-07-13",
        priority: "Medium",
        dependency: "Electrical Isolators",
      },
      {
        taskName: "Solar System (if applicable)",
        startDate: "2026-07-16",
        endDate: "2026-07-22",
        priority: "Medium",
        dependency: "Water Heaters",
      },
      {
        taskName: "Switch sockets, Light Fittings and Exhaust Fans",
        startDate: "2026-07-25",
        endDate: "2026-07-31",
        priority: "Medium",
        dependency: "Solar System (if applicable)",
      },
      {
        taskName: "Earthing",
        startDate: "2026-08-03",
        endDate: "2026-08-09",
        priority: "Medium",
        dependency: "Switch sockets, Light Fittings and Exhaust Fans",
      },
      {
        taskName: "Dewa inspection",
        startDate: "2026-08-12",
        endDate: "2026-08-18",
        priority: "Medium",
        dependency: "Earthing",
      },
    ],
  },
  {
    stageName: "Stage 5",
    tasks: [
      {
        taskName: "Water Proofing",
        startDate: "2026-08-21",
        endDate: "2026-08-27",
        priority: "Medium",
        dependency: "Dewa inspection (Stage 4)",
      },
      {
        taskName: "Substructure water proofing bitumen",
        startDate: "2026-08-30",
        endDate: "2026-09-05",
        priority: "Medium",
        dependency: "Water Proofing",
      },
      {
        taskName: "Wet area (Bath+Kitchen)",
        startDate: "2026-09-08",
        endDate: "2026-09-14",
        priority: "Medium",
        dependency: "Substructure water proofing bitumen",
      },
      {
        taskName: "Roof water proofing combo system",
        startDate: "2026-09-17",
        endDate: "2026-09-23",
        priority: "Medium",
        dependency: "Wet area (Bath+Kitchen)",
      },
      {
        taskName: "Flooring tiles",
        startDate: "2026-09-26",
        endDate: "2026-10-02",
        priority: "Medium",
        dependency: "Roof water proofing combo system",
      },
      {
        taskName: "External Garnite (staircase)",
        startDate: "2026-10-05",
        endDate: "2026-10-11",
        priority: "Medium",
        dependency: "Flooring tiles",
      },
      {
        taskName: "Flooring ceramic for Rooms, baths, kitchen",
        startDate: "2026-10-14",
        endDate: "2026-10-20",
        priority: "Medium",
        dependency: "External Garnite (staircase)",
      },
      {
        taskName: "Wall ceramic for kitchens and bathrooms",
        startDate: "2026-10-23",
        endDate: "2026-10-29",
        priority: "Medium",
        dependency: "Flooring ceramic for Rooms, baths, kitchen",
      },
      {
        taskName: "Marble threshold",
        startDate: "2026-11-01",
        endDate: "2026-11-07",
        priority: "Medium",
        dependency: "Wall ceramic for kitchens and bathrooms",
      },
      {
        taskName: "Painting and ceiling",
        startDate: "2026-11-10",
        endDate: "2026-11-16",
        priority: "Medium",
        dependency: "Marble threshold",
      },
    ],
  },
  {
    stageName: "Stage 6",
    tasks: [
      {
        taskName: "Internal paint",
        startDate: "2026-11-19",
        endDate: "2026-11-25",
        priority: "Medium",
        dependency: "Painting and ceiling (Stage 5)",
      },
      {
        taskName: "External paint",
        startDate: "2026-11-28",
        endDate: "2026-12-04",
        priority: "Medium",
        dependency: "Internal paint",
      },
      {
        taskName: "False ceiling (6060) for Bath, Kitchen only",
        startDate: "2026-12-07",
        endDate: "2026-12-13",
        priority: "Medium",
        dependency: "External paint",
      },
      {
        taskName: "Gypsum Board",
        startDate: "2026-12-16",
        endDate: "2026-12-22",
        priority: "Medium",
        dependency: "False ceiling (6060) for Bath, Kitchen only",
      },
      {
        taskName: "Aluminum & glass work",
        startDate: "2026-12-25",
        endDate: "2026-12-31",
        priority: "Medium",
        dependency: "Gypsum Board",
      },
    ],
  },
  {
    stageName: "Stage 7",
    tasks: [
      {
        taskName: "Aluminum doors",
        startDate: "2027-01-03",
        endDate: "2027-01-09",
        priority: "Medium",
        dependency: "Aluminum & glass work (Stage 6)",
      },
      {
        taskName: "Aluminum windows",
        startDate: "2027-01-12",
        endDate: "2027-01-18",
        priority: "Medium",
        dependency: "Aluminum doors",
      },
      {
        taskName: "Kitchen and pantry cabinets",
        startDate: "2027-01-21",
        endDate: "2027-01-27",
        priority: "Medium",
        dependency: "Aluminum windows",
      },
      {
        taskName: "Gates for Boundary Wall",
        startDate: "2027-01-30",
        endDate: "2027-02-05",
        priority: "Medium",
        dependency: "Kitchen and pantry cabinets",
      },
      {
        taskName: "Joinery Works",
        startDate: "2027-02-08",
        endDate: "2027-02-14",
        priority: "Medium",
        dependency: "Gates for Boundary Wall",
      },
      {
        taskName: "Doors",
        startDate: "2027-02-17",
        endDate: "2027-02-23",
        priority: "Medium",
        dependency: "Joinery Works",
      },
      {
        taskName: "HVAC & mechanical works",
        startDate: "2027-02-26",
        endDate: "2027-03-04",
        priority: "Medium",
        dependency: "Doors",
      },
    ],
  },
  {
    stageName: "Stage 8",
    tasks: [
      {
        taskName: "Split AC",
        startDate: "2027-03-07",
        endDate: "2027-03-13",
        priority: "Medium",
        dependency: "HVAC & mechanical works (Stage 7)",
      },
      {
        taskName: "AC works Ducts and Diffusers",
        startDate: "2027-03-16",
        endDate: "2027-03-22",
        priority: "Medium",
        dependency: "Split AC",
      },
      {
        taskName: "Miscellaneous work",
        startDate: "2027-03-25",
        endDate: "2027-03-31",
        priority: "Medium",
        dependency: "AC works Ducts and Diffusers",
      },
      {
        taskName: "Galvanized Cat-ladder",
        startDate: "2027-04-03",
        endDate: "2027-04-09",
        priority: "Medium",
        dependency: "Miscellaneous work",
      },
      {
        taskName: "Interlock",
        startDate: "2027-04-12",
        endDate: "2027-04-18",
        priority: "Medium",
        dependency: "Galvanized Cat-ladder",
      },
      {
        taskName: "Landscape work",
        startDate: "2027-04-21",
        endDate: "2027-04-27",
        priority: "Medium",
        dependency: "Interlock",
      },
      {
        taskName: "Testing and commissioning",
        startDate: "2027-04-30",
        endDate: "2027-05-06",
        priority: "Medium",
        dependency: "Landscape work",
      },
      {
        taskName: "Cleaning and Handing over",
        startDate: "2027-05-09",
        endDate: "2027-05-15",
        priority: "Medium",
        dependency: "Testing and commissioning",
      },
    ],
  },
];

const initialState = {
  stages: initialProjectPlan,
};

const stageTasksSlice = createSlice({
  name: "stageTasks",
  initialState,
  reducers: {
    updateTask: (state, action) => {
      const { stageIndex, taskIndex, updatedTask } = action.payload;
      state.stages[stageIndex].tasks[taskIndex] = {
        ...state.stages[stageIndex].tasks[taskIndex],
        ...updatedTask,
      };
    },
    addTask: (state, action) => {
      const { stageIndex, newTask } = action.payload;
      state.stages[stageIndex].tasks.push(newTask);
    },
    deleteTask: (state, action) => {
      const { stageIndex, taskIndex } = action.payload;
      state.stages[stageIndex].tasks.splice(taskIndex, 1);
    },
  },
});

export const { updateTask, addTask, deleteTask } = stageTasksSlice.actions;
export default stageTasksSlice.reducer;
