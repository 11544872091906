import React, { useState } from "react";
import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Heading,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Input,
  Textarea,
  Flex,
} from "@chakra-ui/react";
import GenericHeader from "../../../commons/GenericHeader";

// Dummy accommodation data with room change history
const dummyAccommodations = [
  {
    sno: 1,
    employeeName: "Asim",
    roomNo: "101",
    area: "Building A",
    remarks: "No issues",
    roomChanges: [
      {
        oldRoom: "100",
        newRoom: "101",
        date: "2023-01-15",
        remarks: "Upgrade",
      },
      {
        oldRoom: "99",
        newRoom: "100",
        date: "2022-12-01",
        remarks: "Routine change",
      },
    ],
  },
  {
    sno: 2,
    employeeName: "Empl 1",
    roomNo: "102",
    area: "Building B",
    remarks: "Room needs repairs",
    roomChanges: [
      {
        oldRoom: "101",
        newRoom: "102",
        date: "2023-02-12",
        remarks: "Maintenance issue",
      },
    ],
  },
  {
    sno: 3,
    employeeName: "Empl 2",
    roomNo: "201",
    area: "Building A",
    remarks: "No issues",
    roomChanges: [], // No room changes for this employee
  },
  {
    sno: 4,
    employeeName: "Empl 3",
    roomNo: "202",
    area: "Building B",
    remarks: "Assigned new room",
    roomChanges: [
      {
        oldRoom: "200",
        newRoom: "202",
        date: "2023-03-10",
        remarks: "Upgraded to larger room",
      },
    ],
  },
  {
    sno: 5,
    employeeName: "Empl 4",
    roomNo: "301",
    area: "Building C",
    remarks: "Room requires cleaning",
    roomChanges: [
      {
        oldRoom: "300",
        newRoom: "301",
        date: "2023-01-30",
        remarks: "Regular shift",
      },
    ],
  },
];

const LaborAccommodationDetail = () => {
  const [accommodations, setAccommodations] = useState(dummyAccommodations);
  const [selectedAccommodation, setSelectedAccommodation] = useState(
    accommodations[0]
  ); // Select the first one by default
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [newRoom, setNewRoom] = useState({ roomNo: "", remarks: "" });

  const handleRoomChange = (e) => {
    const { name, value } = e.target;
    setNewRoom((prev) => ({ ...prev, [name]: value }));
  };

  // Function to handle room change logic and update the accommodation data
  const handleSaveRoomChange = () => {
    const roomChangeDetails = {
      ...newRoom,
      oldRoom: selectedAccommodation.roomNo,
      date: new Date().toISOString().split("T")[0], // Use current date
    };

    // Update the selected accommodation's room and room change history
    const updatedAccommodation = {
      ...selectedAccommodation,
      roomNo: newRoom.roomNo,
      roomChanges: [...selectedAccommodation.roomChanges, roomChangeDetails],
    };

    // Update the accommodations array
    const updatedAccommodations = accommodations.map((acc) =>
      acc.sno === selectedAccommodation.sno ? updatedAccommodation : acc
    );
    setAccommodations(updatedAccommodations);
    setSelectedAccommodation(updatedAccommodation); // Update the state with the new room
    setDrawerOpen(false);
    setNewRoom({ roomNo: "", remarks: "" }); // Reset the form
  };

  return (
    <Box>
      <Flex justifyContent={"space-between"}>
        <GenericHeader
          back={"/hr/accommodations"}
          title={"Accommodation Details"}
        />
        <Button mt={4} colorScheme="teal" onClick={() => setDrawerOpen(true)}>
          Change Room
        </Button>
      </Flex>

      <Box bg="white" p={5} borderRadius="md" borderWidth="1px">
        <Table variant="simple">
          <Tbody>
            <Tr>
              <Td>Employee Name</Td>
              <Td>{selectedAccommodation.employeeName}</Td>
            </Tr>
            <Tr>
              <Td>Room No</Td>
              <Td>{selectedAccommodation.roomNo}</Td>
            </Tr>
            <Tr>
              <Td>Area</Td>
              <Td>{selectedAccommodation.area}</Td>
            </Tr>
            <Tr>
              <Td>Remarks</Td>
              <Td>{selectedAccommodation.remarks}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>

      {/* Room Change History */}
      <Heading size="md" mt={8} mb={4}>
        Room Change History
      </Heading>
      <Box bg="white" p={5} borderRadius="md" borderWidth="1px">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>Old Room</Th>
              <Th>New Room</Th>
              <Th>Remarks</Th>
            </Tr>
          </Thead>
          <Tbody>
            {selectedAccommodation.roomChanges.map((change, index) => (
              <Tr key={index}>
                <Td>{change.date}</Td>
                <Td>{change.oldRoom}</Td>
                <Td>{change.newRoom}</Td>
                <Td>{change.remarks}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {/* Change Room Drawer */}
      <Drawer
        isOpen={isDrawerOpen}
        placement="right"
        onClose={() => setDrawerOpen(false)}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Change Room</DrawerHeader>
          <DrawerBody>
            <Input
              name="roomNo"
              value={newRoom.roomNo}
              onChange={handleRoomChange}
              placeholder="Enter new room number"
              mb={4}
            />
            <Textarea
              name="remarks"
              value={newRoom.remarks}
              onChange={handleRoomChange}
              placeholder="Remarks for room change"
            />
          </DrawerBody>
          <DrawerFooter>
            <Button
              variant="outline"
              mr={3}
              onClick={() => setDrawerOpen(false)}
            >
              Cancel
            </Button>
            <Button colorScheme="teal" onClick={handleSaveRoomChange}>
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default LaborAccommodationDetail;
