import React, { useState } from "react";
import {
  Box,
  SimpleGrid,
  Heading,
  Flex,
  Input,
  Button,
  Select,
  VStack,
  Container,
} from "@chakra-ui/react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { RiDashboard3Line } from "react-icons/ri";
import { FaUserAlt } from "react-icons/fa";
import GenericHeader from "../../../commons/GenericHeader";

const HRDashboard = () => {
  // Dummy Employee Names for Salary and Attendance
  const employees = ["Ali", "Bilal", "Carmen", "Dawud", "Elias", "Fatima"];

  // State for employee salary selection and filtering
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [timePeriod, setTimePeriod] = useState("Monthly"); // Time selection

  // Overall Attendance Data (Absent, Present, Leave)
  const attendanceData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "Present",
        data: [150, 160, 170, 180, 175, 190],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
      {
        label: "Absent",
        data: [5, 3, 4, 2, 6, 3],
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
      {
        label: "Leave",
        data: [10, 12, 8, 7, 9, 11],
        backgroundColor: "rgba(255, 206, 86, 0.6)",
      },
    ],
  };

  // Monthly Salary Data for each employee
  const salaryData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: `Salary (AED) for ${selectedEmployee || "Ali"}`,
        data: [5000, 5200, 5400, 5500, 5600, 5800], // Dummy data
        backgroundColor: "rgba(54, 162, 235, 0.6)",
      },
    ],
  };

  // Time Range (Monthly, Quarterly, Yearly) based Employee Attendance Data
  const employeeAttendanceByTime = {
    labels:
      timePeriod === "Monthly"
        ? ["Jan", "Feb", "Mar", "Apr"]
        : ["Q1", "Q2", "Q3", "Q4"],
    datasets: [
      {
        label: `${timePeriod} Attendance`,
        data:
          timePeriod === "Monthly" ? [90, 85, 88, 92] : [275, 280, 290, 300], // Dummy data
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  // Handle change of employee selection
  const handleEmployeeChange = (e) => {
    setSelectedEmployee(e.target.value);
  };

  // Handle change in time period selection (Monthly, Quarterly, Yearly)
  const handleTimePeriodChange = (e) => {
    setTimePeriod(e.target.value);
  };

  return (
    <Box p={4} bg="gray.100" minH="100vh">
      <GenericHeader title="HR Dashboard" icon={<RiDashboard3Line />} />

      <SimpleGrid columns={[1, 2]} spacing={4} mt={4}>
        {/* Overall Attendance Chart */}
        <Box p={4} bg="white" borderWidth="1px" borderRadius="md">
          <Flex justify="space-between" align="center" mb={4}>
            <Heading size="md">Overall Attendance</Heading>
            <Button size="sm" colorScheme="teal">
              View Details
            </Button>
          </Flex>
          <Bar data={attendanceData} />
        </Box>

        {/* Employee Salary Stats */}
        <Box p={4} bg="white" borderWidth="1px" borderRadius="md">
          <Flex justify="space-between" align="center" mb={4}>
            <Heading size="md">Employee Salary Stats</Heading>
          </Flex>
          <Input
            placeholder="Search Employee..."
            onChange={handleEmployeeChange}
            value={selectedEmployee}
            mb={4}
          />
          <Bar data={salaryData} />
        </Box>
      </SimpleGrid>

      {/* Time Selection for Attendance and Salary */}
      <SimpleGrid columns={[1, 2]} spacing={4} mt={4}>
        {/* Time-Range Filter for Attendance */}
        <Box p={4} bg="white" borderWidth="1px" borderRadius="md">
          <Flex justify="space-between" align="center" mb={4}>
            <Heading size="md">Employee Attendance by Time</Heading>
            <Select
              onChange={handleTimePeriodChange}
              value={timePeriod}
              width="200px"
            >
              <option value="Monthly">Monthly</option>
              <option value="Quarterly">Quarterly</option>
              <option value="Yearly">Yearly</option>
            </Select>
          </Flex>
          <Bar data={employeeAttendanceByTime} />
        </Box>

        {/* Another Time-Range Filter for Salary */}
        <Box p={4} bg="white" borderWidth="1px" borderRadius="md">
          <Flex justify="space-between" align="center" mb={4}>
            <Heading size="md">Employee Salary by Time</Heading>
            <Select
              onChange={handleTimePeriodChange}
              value={timePeriod}
              width="200px"
            >
              <option value="Monthly">Monthly</option>
              <option value="Quarterly">Quarterly</option>
              <option value="Yearly">Yearly</option>
            </Select>
          </Flex>
          <Bar data={salaryData} />
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default HRDashboard;
