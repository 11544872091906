// petrolCostsSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = [
  // Sample Data
  {
    sno: 1,
    driverName: "John Doe",
    date: "2023-09-01",
    petrolCost: 150,
    sites: ["Site A", "Site B"],
    stage: "Completed",
    billPhoto: "https://via.placeholder.com/150",
  },
  // Add more entries as needed
];

const petrolCostsSlice = createSlice({
  name: "petrolCosts",
  initialState,
  reducers: {
    addPetrolCost: (state, action) => {
      state.push(action.payload);
    },
    deletePetrolCost: (state, action) => {
      return state.filter((entry) => entry.sno !== action.payload);
    },
    // Add more reducers as needed (e.g., updatePetrolCost)
  },
});

export const { addPetrolCost, deletePetrolCost } = petrolCostsSlice.actions;

export const selectPetrolCosts = (state) => state.petrolCosts;

export default petrolCostsSlice.reducer;
