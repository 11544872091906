// ProjectItem.js
import React, { useState, useRef } from "react";
import {
  Image,
  Text,
  Button,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "../../styles/ProjectItem.css";
import { FaTrash } from "react-icons/fa"; // Icon for the delete button

const ProjectItem = ({ project, isActive, onClick, onDelete }) => {
  const activeProject = useSelector((state) => state.projectList.activeProject);
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef();
  const toast = useToast();

  // Function to open the AlertDialog
  const onOpen = () => setIsOpen(true);

  // Function to close the AlertDialog
  const onClose = () => setIsOpen(false);

  // Function to confirm the deletion and show the toast
  const confirmDelete = () => {
    onClose();
    onDelete(project.id); // Call the delete handler passed from the parent

    // Show toast notification
    toast({
      title: "Project deleted.",
      description: `${project.name} has been deleted.`,
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  };

  return (
    <div
      className={`project-item ${isActive ? "active" : ""} ${
        activeProject?.name === project?.name ? "blinking-shadow active" : ""
      }`}
      onClick={onClick}
    >
      <div className="project-content">
        {/* Delete Button */}
        <button
          className="delete-button"
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering the parent onClick
            onOpen(); // Open the confirmation modal
          }}
        >
          <FaTrash />
        </button>

        {/* AlertDialog for confirming deletion */}
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Project
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure you want to delete the project "{project.name}"?
                This action cannot be undone.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

        {/* If the project item is not active, show the vertical text for desktop and horizontal text for mobile */}
        {!isActive ? (
          <div className="project-name-vertical">{project.name}</div>
        ) : (
          <>
            <div className="project-header-details">
              <strong>In Progress</strong>
              <Text fontWeight={"bold"} color="#00A006">
                + {project.agreementAmount} AED
              </Text>
            </div>
            {/* Project Image */}
            <Image
              src={project.image}
              alt={project.title}
              style={{ width: "100%", objectFit: "cover" }}
            />

            <h3
              style={{
                fontWeight: "bolder",
                fontSize: "20px",
                marginTop: "5px",
              }}
            >
              {project.name}
            </h3>

            <div className="project-item-footer">
              <Text mt={5}>Current Stage</Text>
              <Text fontSize={16} fontWeight={"bold"} mb={5} color={"#00A097"}>
                BLOCK PLASTER, MEP CONDUITS
              </Text>
              <Link to={`/projects/${project.name}`}>
                <button>See Details</button>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProjectItem;
