import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Heading,
} from "@chakra-ui/react";
import GenericHeader from "../../../commons/GenericHeader";

const LaborAccommodationForm = ({ onSubmit }) => {
  const [formState, setFormState] = useState({
    employeeName: "",
    roomNo: "",
    area: "",
    remarks: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formState);
    setFormState({ employeeName: "", roomNo: "", area: "", remarks: "" });
  };

  return (
    <Box p={5} borderRadius="md" borderWidth="1px" bg="white">
      <GenericHeader title={"Add Accommodation"} back={"/hr/accommodations"} />
      <form onSubmit={handleSubmit}>
        <FormControl mb={3} isRequired>
          <FormLabel>Employee Name</FormLabel>
          <Input
            name="employeeName"
            value={formState.employeeName}
            onChange={handleChange}
            placeholder="Enter employee name"
          />
        </FormControl>

        <FormControl mb={3} isRequired>
          <FormLabel>Room No</FormLabel>
          <Input
            name="roomNo"
            value={formState.roomNo}
            onChange={handleChange}
            placeholder="Enter room number"
          />
        </FormControl>

        <FormControl mb={3}>
          <FormLabel>Area</FormLabel>
          <Input
            name="area"
            value={formState.area}
            onChange={handleChange}
            placeholder="Enter area"
          />
        </FormControl>

        <FormControl mb={3}>
          <FormLabel>Remarks</FormLabel>
          <Textarea
            name="remarks"
            value={formState.remarks}
            onChange={handleChange}
            placeholder="Any remarks"
          />
        </FormControl>

        <Button type="submit" colorScheme="teal" width="100%">
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default LaborAccommodationForm;
