import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addProject } from "../../store/projectsSlice";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  NumberInput,
  NumberInputField,
  Select,
  Flex,
  Heading,
  useToast,
  Text,
  Image,
  IconButton,
  List,
  ListItem,
  ListIcon,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Stack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
  IoAddCircleOutline,
  IoArrowBackCircleOutline,
  IoCloseCircle,
} from "react-icons/io5";
import { HiOutlinePlusCircle } from "react-icons/hi2";
import { useDropzone } from "react-dropzone";
import { calculateDaysBetweenDates } from "../../utils";
import GanttChartComponent from "./GanttChartComponent";
import { DeleteIcon } from "@chakra-ui/icons";
// import GanttChartComponent2 from "./GanttChartComponent2"; // Uncomment if needed

const initialProjectPlan = [
  {
    stageName: "Stage 1",
    tasks: [
      {
        taskName:
          "Mobilization & site preparation, Temporary Facilities, Site Fencing",
        startDate: "2024-09-01",
        endDate: "2024-09-07",
        priority: "High",
        dependency: "None",
      },
      {
        taskName: "Earth works + Sub Structure",
        startDate: "2024-09-01",
        endDate: "2024-09-07",
        priority: "Medium",
        dependency: "Task 1",
      },
      {
        taskName: "Excavation",
        startDate: "2024-09-05",
        endDate: "2024-09-07",
        priority: "Medium",
        dependency: "Task 1",
      },
      // Add all other tasks for Stage 1 similarly...
    ],
  },
  {
    stageName: "Stage 2",
    tasks: [
      {
        taskName: "Soilid Block work",
        startDate: "2024-09-05",
        endDate: "2024-09-07",
        priority: "Medium",
        dependency: "Task 1",
      },
      {
        taskName: "Neck Columns",
        startDate: "2024-09-05",
        endDate: "2024-09-07",
        priority: "Medium",
        dependency: "Task 1",
      },
      // Add all other tasks for Stage 2 similarly...
    ],
  },
  // Continue adding all stages and their respective tasks as per your data...
  {
    stageName: "Stage 3",
    tasks: [
      {
        taskName: "Slab",
        startDate: "2024-09-05",
        endDate: "2024-09-07",
        priority: "Medium",
        dependency: "Task 1",
      },
      // Add other tasks...
    ],
  },
  {
    stageName: "Stage 4",
    tasks: [
      {
        taskName: "Block Work",
        startDate: "2024-09-05",
        endDate: "2024-09-07",
        priority: "Medium",
        dependency: "Task 1",
      },
      // Add other tasks...
    ],
  },
  // Continue adding all stages up to Stage 8...
  {
    stageName: "Stage 5",
    tasks: [
      {
        taskName: "Water Proofing",
        startDate: "2024-09-05",
        endDate: "2024-09-07",
        priority: "Medium",
        dependency: "Task 1",
      },
      // Add other tasks...
    ],
  },
  {
    stageName: "Stage 6",
    tasks: [
      {
        taskName: "Internal paint",
        startDate: "2024-09-05",
        endDate: "2024-09-07",
        priority: "Medium",
        dependency: "Task 1",
      },
      // Add other tasks...
    ],
  },
  {
    stageName: "Stage 7",
    tasks: [
      {
        taskName: "Aluminum doors",
        startDate: "2024-09-05",
        endDate: "2024-09-07",
        priority: "Medium",
        dependency: "Task 1",
      },
      // Add other tasks...
    ],
  },
  {
    stageName: "Stage 8",
    tasks: [
      {
        taskName: "Split AC",
        startDate: "2024-09-05",
        endDate: "2024-09-07",
        priority: "Medium",
        dependency: "Task 1",
      },
      // Add other tasks...
    ],
  },
];

const AddSiteForm = () => {
  const projects = useSelector((state) => state.projectList.projects);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [totalDays, setTotalDays] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    agreementAmount: "",
    projectManager: "",
    clientName: "",
    variationAmounts: [{ amount: "", days: "" }],
    projectType: "Complete Villa",
    startDate: "",
    endDate: "",
    location: "",
    image: "",
    documents: [],
    stages: [],
    projectPlan: initialProjectPlan, // Initialize with predefined data
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "startDate" || name === "endDate") {
      const startDate = name === "startDate" ? value : formData.startDate;
      const endDate = name === "endDate" ? value : formData.endDate;
      if (startDate && endDate) {
        const diffDays = calculateDaysBetweenDates(startDate, endDate);
        setTotalDays(diffDays);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const projectNameExists = projects.some(
      (project) => project.name === formData.name
    );
    if (projectNameExists) {
      toast({
        title: "Error",
        description:
          "Project name already exists. Please enter a different name.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const totalStageDays = formData.stages.reduce(
      (acc, stage) => acc + parseInt(stage.days || 0, 10),
      0
    );
    if (totalStageDays > totalDays) {
      toast({
        title: "Error",
        description: `Total days for all stages (${totalStageDays}) exceed the total project days (${totalDays}). Please adjust the days.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    dispatch(addProject(formData));
    toast({
      title: "Success",
      description: "Project Added.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    navigate("/projects");
  };

  const calculateRemainingDays = () => {
    const totalStageDays = formData.stages.reduce(
      (acc, stage) => acc + parseInt(stage.days || 0, 10),
      0
    );
    return totalDays - totalStageDays;
  };

  // Image Dropzone
  const onDropImage = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length === 0) return;
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, image: reader.result });
      };
      reader.readAsDataURL(file);
    },
    [formData]
  );

  const {
    getRootProps: getRootPropsImage,
    getInputProps: getInputPropsImage,
    isDragActive: isDragActiveImage,
  } = useDropzone({
    onDrop: onDropImage,
    accept: { "image/*": [] },
    multiple: false,
  });

  const removeImage = () => {
    setFormData({ ...formData, image: "" });
  };

  // Documents Dropzone
  const onDropDocuments = useCallback(
    (acceptedFiles) => {
      setFormData({
        ...formData,
        documents: [...formData.documents, ...acceptedFiles],
      });
    },
    [formData]
  );

  const {
    getRootProps: getRootPropsDocs,
    getInputProps: getInputPropsDocs,
    isDragActive: isDragActiveDocs,
  } = useDropzone({
    onDrop: onDropDocuments,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc", ".docx"],
    },
    multiple: true,
  });

  const removeDocument = (file) => {
    setFormData({
      ...formData,
      documents: formData.documents.filter((doc) => doc !== file),
    });
  };

  // Project Plan State within Drawer
  const [projectPlan, setProjectPlan] = useState(initialProjectPlan);

  const handleAddStage = () => {
    setProjectPlan([
      ...projectPlan,
      { stageName: `Stage ${projectPlan.length + 1}`, tasks: [] },
    ]);
  };

  const handleRemoveStage = (index) => {
    const updatedPlan = projectPlan.filter((_, i) => i !== index);
    setProjectPlan(updatedPlan);
  };

  const handleStageNameChange = (index, value) => {
    const updatedPlan = [...projectPlan];
    updatedPlan[index].stageName = value;
    setProjectPlan(updatedPlan);
  };

  const handleAddTask = (stageIndex) => {
    const updatedPlan = [...projectPlan];
    updatedPlan[stageIndex].tasks.push({
      taskName: "",
      startDate: "",
      endDate: "",
      priority: "Medium",
      dependency: "",
    });
    setProjectPlan(updatedPlan);
  };

  const handleRemoveTask = (stageIndex, taskIndex) => {
    const updatedPlan = [...projectPlan];
    updatedPlan[stageIndex].tasks = updatedPlan[stageIndex].tasks.filter(
      (_, i) => i !== taskIndex
    );
    setProjectPlan(updatedPlan);
  };

  const handleTaskChange = (stageIndex, taskIndex, field, value) => {
    const updatedPlan = [...projectPlan];
    updatedPlan[stageIndex].tasks[taskIndex][field] = value;
    setProjectPlan(updatedPlan);
  };

  const saveProjectPlan = () => {
    setFormData({ ...formData, projectPlan });
    onClose();
    toast({
      title: "Project Plan Saved",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <>
      <Flex gap={5} align="center" mb="4">
        <IoArrowBackCircleOutline
          onClick={() => navigate("/projects")}
          color="#db8700"
          size={50}
          cursor={"pointer"}
        />

        <Heading as="h2" size="lg">
          <Flex gap={5} align={"center"}>
            <Text>Add Project / Site </Text>
            <HiOutlinePlusCircle color="green.600" fontSize={40} />
          </Flex>
        </Heading>
        <Box width="40px" />
      </Flex>

      <Box
        as="form"
        onSubmit={handleSubmit}
        p={4}
        borderRadius="20"
        background={"white"}
        border={"3px solid gray"}
      >
        <VStack spacing={4}>
          {/* Existing Form Fields */}
          <FormControl isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Agreement Amount</FormLabel>
            <NumberInput
              min={0}
              name="agreementAmount"
              value={formData.agreementAmount}
              onChange={(value) =>
                setFormData({ ...formData, agreementAmount: value })
              }
            >
              <NumberInputField />
            </NumberInput>
          </FormControl>

          {/* New Fields: Project Manager and Client Name */}
          <FormControl isRequired>
            <FormLabel>Project Manager</FormLabel>
            <Input
              name="projectManager"
              value={formData.projectManager}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Client Name</FormLabel>
            <Input
              name="clientName"
              value={formData.clientName}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Type of Project</FormLabel>
            <Flex align="center" gap={4}>
              <Select
                name="projectType"
                value={formData.projectType}
                onChange={handleInputChange}
              >
                <option>Villa Construction</option>
                <option>Additional Block</option>
                <option>Maintenance Project</option>
              </Select>
              {/* <Button onClick={onOpen} colorScheme="blue">
                Configure Project Plan
              </Button> */}
            </Flex>
          </FormControl>

          {/* Display Saved Project Plan */}
          {/* {formData.projectPlan.length > 0 && (
            <Box width="100%">
              <Heading size="md" mb={2}>
                Project Plan
              </Heading>
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th>Stage</Th>
                    <Th>Task Name</Th>
                    <Th>Start Date</Th>
                    <Th>End Date</Th>
                    <Th>Priority</Th>
                    <Th>Dependency</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {formData.projectPlan.map((stage, sIdx) =>
                    stage.tasks.map((task, tIdx) => (
                      <Tr key={`${sIdx}-${tIdx}`}>
                        <Td>{stage.stageName}</Td>
                        <Td>{task.taskName}</Td>
                        <Td>{task.startDate}</Td>
                        <Td>{task.endDate}</Td>
                        <Td>{task.priority}</Td>
                        <Td>{task.dependency}</Td>
                      </Tr>
                    ))
                  )}
                </Tbody>
              </Table>
            </Box>
          )} */}

          {/* Start Date, End Date, and Total Days in Horizontal Flex */}
          <Flex gap={4} width="100%" justify="space-between">
            <FormControl isRequired>
              <FormLabel>Planned Start Date</FormLabel>
              <Input
                type="date"
                name="startDate"
                value={formData.startDate}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Planned End Date</FormLabel>
              <Input
                type="date"
                name="endDate"
                value={formData.endDate}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Total Days Allotted: {totalDays} Days</FormLabel>
            </FormControl>
          </Flex>

          {/* Variation Amounts and Days */}
          <FormControl>
            <FormLabel>Variation Amounts and Days</FormLabel>
            {formData.variationAmounts.map((variation, index) => (
              <Box key={index} display="flex" gap={2} mb={2}>
                <NumberInput
                  min={0}
                  name="amount"
                  value={variation.amount}
                  onChange={(value) => {
                    const updatedVariations = [...formData.variationAmounts];
                    updatedVariations[index].amount = value;
                    setFormData({
                      ...formData,
                      variationAmounts: updatedVariations,
                    });
                  }}
                >
                  <NumberInputField placeholder="Amount" />
                </NumberInput>
                <NumberInput
                  min={0}
                  name="days"
                  value={variation.days}
                  onChange={(value) => {
                    const updatedVariations = [...formData.variationAmounts];
                    updatedVariations[index].days = value;
                    setFormData({
                      ...formData,
                      variationAmounts: updatedVariations,
                    });
                  }}
                >
                  <NumberInputField placeholder="Days" />
                </NumberInput>
                <Button
                  onClick={() => {
                    const updatedVariations = formData.variationAmounts.filter(
                      (_, i) => i !== index
                    );
                    setFormData({
                      ...formData,
                      variationAmounts: updatedVariations,
                    });
                  }}
                >
                  Remove
                </Button>
              </Box>
            ))}
            <Button
              onClick={() =>
                setFormData({
                  ...formData,
                  variationAmounts: [
                    ...formData.variationAmounts,
                    { amount: "", days: "" },
                  ],
                })
              }
            >
              Add Variation
            </Button>
          </FormControl>

          {/* Location/Area */}
          <FormControl isRequired>
            <FormLabel>Location/Area</FormLabel>
            <Input
              name="location"
              value={formData.location}
              onChange={handleInputChange}
            />
          </FormControl>

          {/* Image Upload with Dropzone */}
          <FormControl isRequired>
            <FormLabel>Upload Picture</FormLabel>
            <Box
              {...getRootPropsImage()}
              border="2px dashed gray"
              padding={4}
              textAlign="center"
              cursor="pointer"
            >
              <input {...getInputPropsImage()} />
              {isDragActiveImage ? (
                <Text>Drop the image here ...</Text>
              ) : formData.image ? (
                <Flex direction="column" align="center">
                  <Image
                    src={formData.image}
                    alt="Uploaded"
                    boxSize="150px"
                    objectFit="cover"
                    mb={2}
                  />
                  <Button onClick={removeImage} colorScheme="red">
                    Remove
                  </Button>
                </Flex>
              ) : (
                <Text>Drag 'n' drop an image here, or click to select one</Text>
              )}
            </Box>
          </FormControl>

          {/* Documents Upload with Dropzone */}
          <FormControl>
            <FormLabel>Upload Project Documents</FormLabel>
            <Box
              {...getRootPropsDocs()}
              border="2px dashed gray"
              padding={4}
              textAlign="center"
              cursor="pointer"
            >
              <input {...getInputPropsDocs()} />
              {isDragActiveDocs ? (
                <Text>Drop the documents here ...</Text>
              ) : (
                <Text>
                  Drag 'n' drop documents here, or click to select files
                </Text>
              )}
            </Box>
            {formData.documents.length > 0 && (
              <List spacing={2} mt={2}>
                {formData.documents.map((file, index) => (
                  <ListItem key={index}>
                    <Flex align="center" justify="space-between">
                      <Text>{file.name}</Text>
                      <IconButton
                        icon={<IoCloseCircle />}
                        size="sm"
                        colorScheme="red"
                        onClick={() => removeDocument(file)}
                        aria-label="Remove Document"
                      />
                    </Flex>
                  </ListItem>
                ))}
              </List>
            )}
          </FormControl>

          {/* Stages */}
          {/* <FormControl>
            <FormLabel>Stages</FormLabel>
            {formData.stages.map((stage, index) => (
              <Box key={index} display="flex" gap={2} mb={2}>
                <Input
                  name="name"
                  placeholder="Stage Name"
                  value={stage.name}
                  onChange={(e) => {
                    const updatedStages = [...formData.stages];
                    updatedStages[index].name = e.target.value;
                    setFormData({ ...formData, stages: updatedStages });
                  }}
                />
                <NumberInput
                  min={0}
                  name="days"
                  value={stage.days}
                  onChange={(value) => {
                    const updatedStages = [...formData.stages];
                    updatedStages[index].days = value;
                    setFormData({ ...formData, stages: updatedStages });
                  }}
                >
                  <NumberInputField placeholder="Days" />
                </NumberInput>
                <Button
                  onClick={() => {
                    const updatedStages = formData.stages.filter(
                      (_, i) => i !== index
                    );
                    setFormData({ ...formData, stages: updatedStages });
                  }}
                >
                  Remove
                </Button>
              </Box>
            ))}
            <Button
              onClick={() =>
                setFormData({
                  ...formData,
                  stages: [
                    ...formData.stages,
                    { name: "", days: calculateRemainingDays() },
                  ],
                })
              }
              isDisabled={calculateRemainingDays() <= 0} // Disable the button if no remaining days
            >
              Add Stage
            </Button>
          </FormControl> */}

          {/* Submit Button */}
          <button
            type="submit"
            colorScheme="primary"
            className="btn-primary"
            isDisabled={
              !formData.name ||
              !formData.agreementAmount ||
              !formData.projectManager ||
              !formData.clientName ||
              !formData.startDate ||
              !formData.endDate ||
              !formData.location ||
              !formData.image
            }
          >
            <IoAddCircleOutline /> Add Site
          </button>
        </VStack>
      </Box>

      {/* Configure Project Plan Drawer */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Configure Project Plan</DrawerHeader>

          <DrawerBody>
            <VStack spacing={4} align="stretch">
              {projectPlan.map((stage, stageIndex) => (
                <Box
                  key={stageIndex}
                  borderWidth="3px"
                  borderColor={"blue"}
                  borderRadius="lg"
                  p={4}
                >
                  <Flex justify="space-between" align="center" mb={4}>
                    <Input
                      placeholder="Stage Name"
                      value={stage.stageName}
                      onChange={(e) =>
                        handleStageNameChange(stageIndex, e.target.value)
                      }
                      width="45%"
                    />
                    <IconButton
                      icon={<DeleteIcon />}
                      colorScheme="red"
                      variant="outline"
                      onClick={() => handleRemoveStage(stageIndex)}
                      aria-label="Remove Stage"
                      size="lg"
                    />
                  </Flex>
                  {/* Tasks within Stage */}
                  {stage.tasks.map((task, taskIndex) => (
                    <Box
                      key={taskIndex}
                      borderWidth="2px"
                      borderRadius="md"
                      borderColor={"green"}
                      p={3}
                      mb={3}
                    >
                      <Flex justify="space-between" align="center" mb={2}>
                        <Text fontWeight="bold">Task {taskIndex + 1}</Text>
                        <IconButton
                          icon={<IoCloseCircle />}
                          colorScheme="red"
                          size="sm"
                          onClick={() =>
                            handleRemoveTask(stageIndex, taskIndex)
                          }
                          aria-label="Remove Task"
                        />
                      </Flex>
                      <Flex gap={4} wrap="wrap">
                        <FormControl width="30%">
                          <FormLabel>Task Name</FormLabel>
                          <Input
                            value={task.taskName}
                            onChange={(e) =>
                              handleTaskChange(
                                stageIndex,
                                taskIndex,
                                "taskName",
                                e.target.value
                              )
                            }
                          />
                        </FormControl>
                        <FormControl width="20%">
                          <FormLabel>Start Date</FormLabel>
                          <Input
                            type="date"
                            value={task.startDate}
                            onChange={(e) =>
                              handleTaskChange(
                                stageIndex,
                                taskIndex,
                                "startDate",
                                e.target.value
                              )
                            }
                          />
                        </FormControl>
                        <FormControl width="20%">
                          <FormLabel>End Date</FormLabel>
                          <Input
                            type="date"
                            value={task.endDate}
                            onChange={(e) =>
                              handleTaskChange(
                                stageIndex,
                                taskIndex,
                                "endDate",
                                e.target.value
                              )
                            }
                          />
                        </FormControl>
                        <FormControl width="15%">
                          <FormLabel>Priority</FormLabel>
                          <Select
                            value={task.priority}
                            onChange={(e) =>
                              handleTaskChange(
                                stageIndex,
                                taskIndex,
                                "priority",
                                e.target.value
                              )
                            }
                          >
                            <option>Low</option>
                            <option>Medium</option>
                            <option>High</option>
                          </Select>
                        </FormControl>
                        <FormControl width="15%">
                          <FormLabel>Dependency</FormLabel>
                          <Input
                            value={task.dependency}
                            onChange={(e) =>
                              handleTaskChange(
                                stageIndex,
                                taskIndex,
                                "dependency",
                                e.target.value
                              )
                            }
                          />
                        </FormControl>
                      </Flex>
                    </Box>
                  ))}
                  <Button
                    onClick={() => handleAddTask(stageIndex)}
                    colorScheme="green"
                    size="sm"
                  >
                    Add Task
                  </Button>
                </Box>
              ))}
              <Flex justifyContent={"space-between"} gap={10}>
                <Button onClick={handleAddStage} colorScheme="blue">
                  Add Stage
                </Button>
                <Button onClick={saveProjectPlan} colorScheme="teal">
                  Save Project Plan
                </Button>
              </Flex>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Gantt Chart Components (Optional) */}
      {/* <GanttChartComponent /> */}
      {/* <GanttChartComponent2 /> */}
    </>
  );
};

export default AddSiteForm;
