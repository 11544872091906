// src/features/todos/todosSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  tasks: [], // Flattened structure for simplicity
};

const todosSlice = createSlice({
  name: "todos",
  initialState,
  reducers: {
    addTask: (state, action) => {
      state.tasks.push(action.payload);
    },
    toggleTaskCompletion: (state, action) => {
      const task = state.tasks.find((task) => task.id === action.payload);
      if (task) {
        task.completed = !task.completed;

        // Handle repeat functionality
        if (task.completed && task.repeat !== "None") {
          let newDueDate = new Date(task.dueDate);
          switch (task.repeat) {
            case "Daily":
              newDueDate.setDate(newDueDate.getDate() + 1);
              break;
            case "Weekly":
              newDueDate.setDate(newDueDate.getDate() + 7);
              break;
            case "Monthly":
              newDueDate.setMonth(newDueDate.getMonth() + 1);
              break;
            case "Yearly":
              newDueDate.setFullYear(newDueDate.getFullYear() + 1);
              break;
            default:
              break;
          }

          const newTask = {
            ...task,
            id: uuidv4(), // Ensure a new unique ID
            dueDate: newDueDate.toISOString().split("T")[0],
            completed: false,
          };

          state.tasks.push(newTask);
        }
      }
    },
    deleteTask: (state, action) => {
      state.tasks = state.tasks.filter((task) => task.id !== action.payload);
    },
    editTask: (state, action) => {
      const { id, description, dueDate, priority, reminder, repeat, color } =
        action.payload;
      const task = state.tasks.find((task) => task.id === id);
      if (task) {
        task.description = description;
        task.dueDate = dueDate;
        task.priority = priority;
        task.reminder = reminder;
        task.repeat = repeat;
        task.color = color;
      }
    },
  },
});

export const { addTask, toggleTaskCompletion, deleteTask, editTask } =
  todosSlice.actions;

export default todosSlice.reducer;
