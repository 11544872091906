import React, { useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectPetrolCosts, // Selector to fetch petrol cost entries
  addPetrolCost, // Action to add a new petrol cost entry
  deletePetrolCost, // Action to delete a petrol cost entry
} from "../../store/petrolCostsSlice"; // Update the path as per your project structure

import {
  Box,
  Flex,
  Button,
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
  useToast,
  useDisclosure,
  IconButton,
  FormControl,
  FormLabel,
  Switch,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter,
  Input,
  Textarea,
  VStack,
  CheckboxGroup,
  Checkbox,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import debounce from "lodash/debounce";
import GenericGrid from "./GenericGrid";
import SearchInput from "../../commons/SearchInput";
import DashboardBox from "../../commons/DashboardBox"; // Ensure DashboardBox is properly imported
import { CiMoneyBill } from "react-icons/ci";
import GenericHeader from "../../commons/GenericHeader";

const PetrolCost = () => {
  const dispatch = useDispatch();
  const petrolCosts = useSelector(selectPetrolCosts);

  const [rowData, setRowData] = useState(petrolCosts);
  const [originalData] = useState(petrolCosts);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(petrolCosts);
  const toast = useToast();

  // Modal Controls
  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();
  const {
    isOpen: isColumnModalOpen,
    onOpen: onOpenColumnModal,
    onClose: onCloseColumnModal,
  } = useDisclosure();

  // Drawer Controls
  const {
    isOpen: isDrawerOpen,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();
  const btnRef = React.useRef();

  // Filter States
  const [selectedDriverName, setSelectedDriverName] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedStage, setSelectedStage] = useState("");

  // Sites is a multi-select
  const [selectedSites, setSelectedSites] = useState([]);

  // Form States for Adding New Entry
  const [newDriverName, setNewDriverName] = useState("");
  const [newDate, setNewDate] = useState("");
  const [newPetrolCost, setNewPetrolCost] = useState("");
  const [newSites, setNewSites] = useState([]);
  const [newStage, setNewStage] = useState("");
  const [newBillPhoto, setNewBillPhoto] = useState(null);

  // Memoized Filters
  const filters = useMemo(
    () => ({
      driverName: selectedDriverName,
      date: selectedDate,
      stage: selectedStage,
      sites: selectedSites,
    }),
    [selectedDriverName, selectedDate, selectedStage, selectedSites]
  );

  // Debounced Search
  const debouncedApplySearchFilter = useCallback(
    debounce((text) => applySearchFilter(text), 300),
    [originalData]
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedApplySearchFilter(text);
  };

  const applySearchFilter = (text) => {
    const lowercasedText = text.toLowerCase();
    const filtered = originalData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(lowercasedText)
      )
    );
    setFilteredData(filtered);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredData(originalData);
  };

  const handleTagClose = (filterType) => {
    let updatedFilters = { ...filters };

    switch (filterType) {
      case "driverName":
        setSelectedDriverName("");
        updatedFilters.driverName = "";
        break;
      case "date":
        setSelectedDate("");
        updatedFilters.date = "";
        break;
      case "stage":
        setSelectedStage("");
        updatedFilters.stage = "";
        break;
      case "sites":
        setSelectedSites([]);
        updatedFilters.sites = [];
        break;
      default:
        break;
    }

    applyFilters(updatedFilters);
    toast({
      title: "Filter Removed",
      description: `Filter for ${filterType} has been removed.`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const applyFilters = (currentFilters) => {
    let filtered = originalData;

    if (currentFilters.driverName) {
      filtered = filtered.filter(
        (row) => row.driverName === currentFilters.driverName
      );
    }

    if (currentFilters.date) {
      filtered = filtered.filter((row) => row.date === currentFilters.date);
    }

    if (currentFilters.stage) {
      filtered = filtered.filter((row) => row.stage === currentFilters.stage);
    }

    if (currentFilters.sites && currentFilters.sites.length > 0) {
      filtered = filtered.filter((row) =>
        currentFilters.sites.every((site) => row.sites.includes(site))
      );
    }

    setFilteredData(filtered);
  };

  const handleApplyFilter = () => {
    const currentFilters = {
      driverName: selectedDriverName,
      date: selectedDate,
      stage: selectedStage,
      sites: selectedSites,
    };
    applyFilters(currentFilters);
    toast({
      title: "Filters Applied",
      description: "Data has been filtered.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const handleClearFilter = () => {
    setSelectedDriverName("");
    setSelectedDate("");
    setSelectedStage("");
    setSelectedSites([]);
    setFilteredData(originalData);
    toast({
      title: "Filters Cleared",
      description: "All filters have been cleared.",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  // Column Definitions
  const petrolCostColumns = [
    {
      id: "sno",
      header: "S.No",
      accessor: (item, index) => index + 1,
      show: true,
      minWidth: 50,
    },
    {
      id: "driverName",
      header: "Driver Name",
      accessor: (item) => item.driverName,
      show: true,
      minWidth: 150,
    },
    {
      id: "date",
      header: "Date",
      accessor: (item) => item.date,
      show: true,
      minWidth: 120,
    },
    {
      id: "petrolCost",
      header: "Petrol Cost",
      accessor: (item) => `AED ${item.petrolCost.toLocaleString()}`,
      show: true,
      minWidth: 150,
    },
    {
      id: "sites",
      header: "Sites",
      accessor: (item) => item.sites.join(", "),
      show: true,
      minWidth: 200,
    },
    {
      id: "stage",
      header: "Stage",
      accessor: (item) => item.stage,
      show: true,
      minWidth: 150,
    },
    {
      id: "billPhoto",
      header: "Bill Photo",
      accessor: (item) => item.billPhoto,
      render: (item) => (
        <Box
          as="button"
          onClick={(e) => {
            e.stopPropagation();
            // Implement image viewing logic here, e.g., open a modal or lightbox
            window.open(item.billPhoto, "_blank");
          }}
          color="teal.500"
          textDecoration="underline"
        >
          View Photo
        </Box>
      ),
      show: true,
      minWidth: 120,
    },
    {
      id: "actions",
      header: "Actions",
      accessor: (item) => item.sno,
      render: (item) => (
        <IconButton
          aria-label="Delete Row"
          icon={<DeleteIcon />}
          colorScheme="red"
          size="sm"
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteRow(item.sno);
          }}
        />
      ),
      show: true,
      minWidth: 100,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    petrolCostColumns.filter((col) => col.show !== false).map((col) => col.id)
  );

  const handleVisibleColumnsChange = (columnId) => {
    const updatedColumns = visibleColumns.includes(columnId)
      ? visibleColumns.filter((col) => col !== columnId)
      : [...visibleColumns, columnId];
    setVisibleColumns(updatedColumns);
  };

  const handleDeleteRow = (sno) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this petrol cost entry?"
    );
    if (confirmed) {
      dispatch(deletePetrolCost(sno));
      setRowData(rowData.filter((row) => row.sno !== sno));
      setFilteredData(filteredData.filter((row) => row.sno !== sno));
      toast({
        title: "Entry Deleted",
        description: `Petrol cost entry S.No ${sno} has been deleted.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Adding New Petrol Cost Entry
  const handleAddPetrolCost = () => {
    if (
      !newDriverName ||
      !newDate ||
      !newPetrolCost ||
      newSites.length === 0 ||
      !newStage ||
      !newBillPhoto
    ) {
      toast({
        title: "Incomplete Data",
        description: "Please fill in all the fields.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // For simplicity, we're using a URL for the bill photo. In a real application, you'd handle file uploads appropriately.
    const billPhotoURL = URL.createObjectURL(newBillPhoto);

    const newEntry = {
      sno: originalData.length + 1, // Adjust according to your data management
      driverName: newDriverName,
      date: newDate,
      petrolCost: parseFloat(newPetrolCost),
      sites: newSites,
      stage: newStage,
      billPhoto: billPhotoURL,
    };

    dispatch(addPetrolCost(newEntry));
    setRowData([...rowData, newEntry]);
    setFilteredData([...filteredData, newEntry]);

    // Reset form fields
    setNewDriverName("");
    setNewDate("");
    setNewPetrolCost("");
    setNewSites([]);
    setNewStage("");
    setNewBillPhoto(null);

    toast({
      title: "Entry Added",
      description: "New petrol cost entry has been added.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });

    onCloseDrawer();
  };

  return (
    <Box>
      {/* Dashboard Boxes */}
      <Flex justifyContent={"space-between"}>
        <GenericHeader title={"Daily Petrol Cost"} />
        <Button
          leftIcon={<AddIcon />}
          colorScheme="teal"
          onClick={onOpenDrawer}
          ref={btnRef}
        >
          Add Petrol Cost
        </Button>
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Flex
          alignItems="center"
          width="100%"
          justifyContent="space-around"
          gap={3}
        >
          <DashboardBox
            icon={CiMoneyBill}
            name="Total Petrol Cost"
            value={`AED ${filteredData
              .reduce((sum, item) => sum + item.petrolCost, 0)
              .toLocaleString()}`}
            backgroundColor="blue.300"
            type="money"
            ariaLabel="Total Petrol Cost"
          />
          {/* Add more DashboardBoxes as needed */}
        </Flex>
      </Flex>

      {/* Action Buttons */}
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        <Button leftIcon={<AddIcon />} onClick={onOpenFilterModal}>
          Filter
        </Button>
        <SearchInput
          placeholder="Search Petrol Costs"
          value={searchText}
          onChange={handleSearchInputChange}
          onClear={handleClearSearch}
        />
        <Button onClick={onOpenColumnModal}>Toggle Columns</Button>
      </Flex>

      {/* Active Filters */}
      <Flex mb={4}>
        <Flex>
          {filters.driverName && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Driver: ${filters.driverName}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("driverName")} />
            </Tag>
          )}
          {filters.date && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Date: ${filters.date}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("date")} />
            </Tag>
          )}
          {filters.stage && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Stage: ${filters.stage}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("stage")} />
            </Tag>
          )}
          {filters.sites && filters.sites.length > 0 && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Sites: ${filters.sites.join(
                ", "
              )}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("sites")} />
            </Tag>
          )}
        </Flex>
      </Flex>

      {/* Filter Modal */}
      <Modal isOpen={isFilterModalOpen} onClose={onCloseFilterModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Driver Name</FormLabel>
                <Select
                  placeholder="Select Driver Name"
                  value={selectedDriverName}
                  onChange={(e) => setSelectedDriverName(e.target.value)}
                >
                  {originalData.map((item, index) => (
                    <option key={index} value={item.driverName}>
                      {item.driverName}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Date</FormLabel>
                <Input
                  type="date"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Stage</FormLabel>
                <Select
                  placeholder="Select Stage"
                  value={selectedStage}
                  onChange={(e) => setSelectedStage(e.target.value)}
                >
                  <option value="Initiated">Initiated</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Completed">Completed</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Sites</FormLabel>
                <CheckboxGroup
                  colorScheme="teal"
                  value={selectedSites}
                  onChange={setSelectedSites}
                >
                  <Flex direction="column">
                    {Array.from(
                      new Set(originalData.flatMap((item) => item.sites))
                    ).map((site, index) => (
                      <Checkbox key={index} value={site}>
                        {site}
                      </Checkbox>
                    ))}
                  </Flex>
                </CheckboxGroup>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleApplyFilter}>
              Apply Filter
            </Button>
            <Button variant="ghost" onClick={handleClearFilter}>
              Clear Filter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Column Toggle Modal */}
      <Modal isOpen={isColumnModalOpen} onClose={onCloseColumnModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Toggle Columns</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="start">
              {petrolCostColumns.map((col) => (
                <FormControl
                  display="flex"
                  alignItems="center"
                  key={col.id}
                  mb={2}
                >
                  <FormLabel htmlFor={col.id} mb="0" flex="1">
                    {col.header}
                  </FormLabel>
                  <Switch
                    id={col.id}
                    isChecked={visibleColumns.includes(col.id)}
                    onChange={() => handleVisibleColumnsChange(col.id)}
                  />
                </FormControl>
              ))}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onCloseColumnModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Drawer for Adding New Petrol Cost Entry */}
      <Drawer
        isOpen={isDrawerOpen}
        placement="right"
        onClose={onCloseDrawer}
        finalFocusRef={btnRef}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add New Petrol Cost</DrawerHeader>

          <DrawerBody>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Driver Name</FormLabel>
                <Input
                  placeholder="Enter Driver Name"
                  value={newDriverName}
                  onChange={(e) => setNewDriverName(e.target.value)}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Date</FormLabel>
                <Input
                  type="date"
                  value={newDate}
                  onChange={(e) => setNewDate(e.target.value)}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Petrol Cost (AED)</FormLabel>
                <Input
                  type="number"
                  placeholder="Enter Petrol Cost"
                  value={newPetrolCost}
                  onChange={(e) => setNewPetrolCost(e.target.value)}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Sites</FormLabel>
                <CheckboxGroup
                  colorScheme="teal"
                  value={newSites}
                  onChange={setNewSites}
                >
                  <Flex direction="column">
                    {Array.from(
                      new Set(originalData.flatMap((item) => item.sites))
                    ).map((site, index) => (
                      <Checkbox key={index} value={site}>
                        {site}
                      </Checkbox>
                    ))}
                  </Flex>
                </CheckboxGroup>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Stage</FormLabel>
                <Select
                  placeholder="Select Stage"
                  value={newStage}
                  onChange={(e) => setNewStage(e.target.value)}
                >
                  <option value="Initiated">Initiated</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Completed">Completed</option>
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Bill Upload Photo</FormLabel>
                <Input
                  type="file"
                  accept="image/*"
                  onChange={(e) => setNewBillPhoto(e.target.files[0])}
                />
              </FormControl>
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onCloseDrawer}>
              Cancel
            </Button>
            <Button colorScheme="teal" onClick={handleAddPetrolCost}>
              Add
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      {/* Data Grid */}
      <Box>
        <GenericGrid
          columns={petrolCostColumns}
          data={filteredData}
          title="Petrol Costs"
          visibleColumnsState={visibleColumns}
          filterState={filters}
          onVisibleColumnsChange={handleVisibleColumnsChange}
        />
      </Box>
    </Box>
  );
};

export default PetrolCost;
