import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Button,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Divider,
} from "@chakra-ui/react";
import { IoCloseOutline } from "react-icons/io5";

const DetailsDrawer = ({ isOpen, onClose, type }) => {
  const dummyData = {
    manhour: {
      costList: [
        {
          skill: "Plumber",
          quantity: 8,
          date: "2024-09-20",
          totalAmount: "AED 1600",
        },
        {
          skill: "Steel Fixer",
          quantity: 6,
          date: "2024-09-21",
          totalAmount: "AED 1200",
        },
      ],
      totalCost: "AED 2800",
    },
    bills: {
      invoiceList: [
        {
          material: "Cement",
          quantity: 4,
          totalCost: "AED 2000",
          datePurchased: "2024-09-20",
          invoice: "2342342",
        },
        {
          material: "Sand",
          quantity: 10,
          totalCost: "AED 1500",
          datePurchased: "2024-09-21",
          invoice: "2342343",
        },
        {
          material: "Gravel",
          quantity: 5,
          totalCost: "AED 2500",
          datePurchased: "2024-09-22",
          invoice: "2342344",
        },
      ],
      totalCost: "AED 6000",
    },
  };

  const data = dummyData[type] || {
    costList: [],
    invoiceList: [],
    totalCost: "AED 0",
  };

  return (
    <Drawer isOpen={isOpen} placement="right" size={"xl"} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Text fontSize="lg" fontWeight="bold">
            {type === "manhour" ? "Actual Manhour Costs" : "Stage Bills"}
          </Text>
          <Button
            position="absolute"
            right="0"
            top="0"
            variant="outline"
            onClick={onClose}
            aria-label="Close"
            icon={<IoCloseOutline />}
          />
        </DrawerHeader>
        <DrawerBody>
          <Box mb={4}>
            {type === "manhour" ? (
              <>
                <Text fontSize="md" fontWeight="bold">
                  Manhour Costs
                </Text>
                <Table variant="simple" mt={4}>
                  <Thead>
                    <Tr>
                      <Th>Skill</Th>
                      <Th>Quantity</Th>
                      <Th>Date</Th>
                      <Th>Total Amount</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.costList.map((item, index) => (
                      <Tr key={index}>
                        <Td>{item.skill}</Td>
                        <Td>{item.quantity}</Td>
                        <Td>{item.date}</Td>
                        <Td>{item.totalAmount}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <Divider my={4} />
                <Text fontSize="lg" fontWeight="bold">
                  Total Cost: {data.totalCost}
                </Text>
              </>
            ) : type === "bills" ? (
              <>
                <Text fontSize="md" fontWeight="bold">
                  Invoice List
                </Text>
                <Table variant="simple" mt={4}>
                  <Thead>
                    <Tr>
                      <Th>Material</Th>
                      <Th>Quantity</Th>
                      <Th>Total Cost</Th>
                      <Th>Date Purchased</Th>
                      <Th>Invoice #</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.invoiceList.map((item, index) => (
                      <Tr key={index}>
                        <Td>{item.material}</Td>
                        <Td>{item.quantity}</Td>
                        <Td>{item.totalCost}</Td>
                        <Td>{item.datePurchased}</Td>
                        <Td>{item.invoice}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <Divider my={4} />
                <Text fontSize="lg" fontWeight="bold">
                  Total Cost: {data.totalCost}
                </Text>
              </>
            ) : (
              <Text>No data available for this type.</Text>
            )}
          </Box>
        </DrawerBody>
        <DrawerFooter>
          <Button variant="outline" onClick={onClose}>
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default DetailsDrawer;
