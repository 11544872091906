// EmployeeDetails.jsx
import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  VStack,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  NumberInput,
  NumberInputField,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Button,
  Heading,
  Menu,
  HStack,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Select,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Spinner,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GenericHeader from "../../commons/GenericHeader";
import { AddIcon, EditIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import DashboardBox from "../../commons/DashboardBox";
import { FaCalendarAlt, FaClock, FaTasks, FaTrophy } from "react-icons/fa";
import "../../styles/CustomDatePicker.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FaMoneyBill } from "react-icons/fa6";
import SalaryTransactionsTable from "./SalaryTransactionsTable";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const EmployeeDetails = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const certModalDisclosure = useDisclosure();
  const [processLoading, setProcessLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedCert, setSelectedCert] = useState(null);
  const [basicAmount, setBasicAmount] = useState(1000);
  const [salaryAdvance, setSalaryAdvance] = useState(0);
  const [bonus, setBonus] = useState(0);
  const [overtimeAmount, setOvertimeAmount] = useState(200);
  const [excellentWorkAllowance, setExcellentWorkAllowance] = useState(100);
  const [otherBenefits, setOtherBenefits] = useState(0);
  const absentDeduction = 100;
  const [remarks, setRemarks] = useState("");

  // New State Variables for Insurance and Visa
  const [insuranceHistory, setInsuranceHistory] = useState([]);
  const [isInsuranceDrawerOpen, setIsInsuranceDrawerOpen] = useState(false);
  const [insuranceDate, setInsuranceDate] = useState(new Date());
  const [insuranceCost, setInsuranceCost] = useState(0);
  const [insuranceRenewalDate, setInsuranceRenewalDate] = useState(new Date());
  const [insuranceStatus, setInsuranceStatus] = useState("");
  const [insuranceRemarks, setInsuranceRemarks] = useState("");

  const [visaHistory, setVisaHistory] = useState([]);
  const [isVisaDrawerOpen, setIsVisaDrawerOpen] = useState(false);
  const [visaDate, setVisaDate] = useState(new Date());
  const [visaExpiry, setVisaExpiry] = useState(new Date());
  const [visaRenewal, setVisaRenewal] = useState(new Date());
  const [visaDuration, setVisaDuration] = useState("");
  const [visaRemarks, setVisaRemarks] = useState("");

  const calculateNetSalary = () => {
    return (
      basicAmount +
      overtimeAmount +
      bonus +
      excellentWorkAllowance +
      otherBenefits -
      salaryAdvance -
      absentDeduction
    );
  };

  const handleProcessSalary = () => {
    setProcessLoading(true);
    setTimeout(() => {
      setProcessLoading(false);
    }, 2000);
  };

  const handleDisableEmployee = () => {
    setIsDisabled(true);
  };

  const employee = {
    name: "Abu Bakar",
    rate: "1000 AED",
    designation: "Software Engineer",
    joiningDate: "2022-01-15",
    bank: "ABC Bank",
    accountNo: "123456789",
    workArea: "Dubai",
    laborCard: "LC123456",
    visa: "VISA123456",
    passportNo: "P123456789",
    nationality: "UAE",
    passportExpiry: "2025-12-31",
    visaExpiry: "2024-12-31",
    selfContact: "1234567890",
    familyContact: "0987654321",
    performance: "Excellent",
    imageUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUIRuFTSeB2XwtcJ1gmYXSQLOrRjx43XEh_g&s",
  };

  const timesheet = Array.from({ length: 31 }, (_, index) => ({
    date: `2024-07-${String(index + 1).padStart(2, "0")}`,
    day: new Date(2024, 6, index + 1).toLocaleString("en-US", {
      weekday: "short",
    }),
    inTime: "09:00",
    outTime: "18:00",
    breakTime: "1:00",
    workingHours: 8,
    overtime: 2,
    performance: "Good",
  }));

  const accommodationHistory = Array.from({ length: 5 }, (_, index) => ({
    date: `2024-07-${String(index + 1).padStart(2, "0")}`,
    notes: `Room Change ${index + 1}`,
  }));

  const leaveRequests = Array.from({ length: 3 }, (_, index) => ({
    dateFrom: `2024-07-${String(index + 1).padStart(2, "0")}`,
    dateTo: `2024-07-${String(index + 1 + 4).padStart(2, "0")}`,
    noOfDays: 5,
    reason: "Personal Leave",
    status: index === 0 ? "Approved" : "Requested",
  }));

  const certificates = [
    {
      type: "Appreciation",
      dateIssued: "2024-07-15",
      bgColor: "green.200",
      icon: FaTrophy,
    },
    {
      type: "Warning",
      dateIssued: "2024-06-15",
      bgColor: "red.200",
      icon: FaTasks,
    },
  ];

  const medicalHistory = Array.from({ length: 2 }, (_, index) => ({
    date: `2024-07-${String(index + 1).padStart(2, "0")}`,
    description: `Medical Checkup ${index + 1}`,
    status: index === 0 ? "Completed" : "Pending",
    remarks: `All good ${index + 1}`,
  }));

  const allotments = Array.from({ length: 3 }, (_, index) => ({
    formanName: `Forman ${index + 1}`,
    projectName: `Project ${index + 1}`,
    date: `2024-07-${String(index + 1).padStart(2, "0")}`,
    endDate: `2024-07-${String(index + 1 + 10).padStart(2, "0")}`,
  }));

  const assetsIssued = Array.from({ length: 2 }, (_, index) => ({
    assetType: index === 0 ? "Uniform" : "Tool Bag",
    date: `2024-07-${String(index + 1).padStart(2, "0")}`,
    remarks: `Issued on ${`2024-07-${String(index + 1).padStart(2, "0")}`}`,
  }));

  const tickets = Array.from({ length: 2 }, (_, index) => ({
    date: `2024-07-${String(index + 1).padStart(2, "0")}`,
    ticketType: index === 0 ? "Business" : "Personal",
    tripType: index === 0 ? "Round Trip" : "Single Trip",
    cost: index === 0 ? "500 AED" : "300 AED",
  }));

  // Insurance History State
  const [insuranceHistoryState, setInsuranceHistoryState] = useState([]);

  // Visa History State
  const [visaHistoryState, setVisaHistoryState] = useState([]);

  const openDrawer = (type) => {
    setDrawerType(type);
    setIsDrawerOpen(true);
  };
  const openCertModal = (cert) => {
    setSelectedCert(cert);
    certModalDisclosure.onOpen();
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    // Reset Drawer-specific states if needed
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "sub",
    "super",
    "header",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "indent",
    "direction",
    "align",
    "link",
    "image",
    "video",
  ];

  const performanceData = {
    labels: ["Performance"],
    datasets: [
      {
        label: "Performance",
        data: [80, 20],
        backgroundColor: ["#4CAF50", "#ddd"],
        cutout: "70%",
      },
    ],
  };

  return (
    <>
      {/* Header Section */}
      <Flex justifyContent={"space-between"} alignItems="center">
        <Flex>
          <GenericHeader title="Employee Details" back={"/hr/employees"} />
          <Box>
            <Heading size="md" color="primary.500">
              For Month
            </Heading>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="MMM, yyyy"
              showMonthYearPicker
              showFullMonthYearPicker
              className="react-datepicker-wrapper"
            />
          </Box>
        </Flex>
        <Button colorScheme="teal" onClick={() => openDrawer("editEmployee")}>
          Edit Employee
        </Button>
      </Flex>

      {/* Employee Information Section */}
      <Box
        p={4}
        bg="white"
        borderRadius="md"
        mt={4}
        opacity={isDisabled ? 0.5 : 1}
        boxShadow="md"
      >
        <Flex justifyContent={"space-between"}>
          {/* Employee Image and Basic Info */}
          <Flex>
            <Image
              borderRadius="full"
              boxSize="100px"
              src={employee.imageUrl}
              alt={employee.name}
            />
            <Box ml={4}>
              <Text fontSize="2xl" fontWeight="bold">
                {employee.name}
              </Text>
              <Text>{employee.designation}</Text>
              <Text>
                Joined on:{" "}
                <Text as="span" fontWeight="bold" color="teal.500">
                  {employee.joiningDate}
                </Text>
              </Text>
              <Button mt={2} onClick={onOpen}>
                View Details
              </Button>
            </Box>
          </Flex>

          {/* Employee Additional Info */}
          <Flex>
            <Box ml={4}>
              <Flex justifyContent={"space-between"} flexDirection={"column"}>
                <Text fontSize="sm">Work Area:</Text>
                <Text fontWeight="bold">Site A</Text>
              </Flex>
              <Flex justifyContent={"space-between"} flexDirection={"column"}>
                <Text fontSize="sm">Bank:</Text>
                <Text fontWeight="bold">{employee.bank}</Text>
              </Flex>
              <Flex justifyContent={"space-between"} flexDirection={"column"}>
                <Text fontSize="sm">A/C No:</Text>
                <Text fontWeight="bold">{employee.accountNo}</Text>
              </Flex>
            </Box>
          </Flex>

          {/* Employee More Info */}
          <Flex>
            <Box ml={4}>
              <Flex justifyContent={"space-between"} flexDirection={"column"}>
                <Text fontSize="sm">Passport No:</Text>
                <Text fontWeight="bold">{employee.passportNo}</Text>
              </Flex>
              <Flex justifyContent={"space-between"} flexDirection={"column"}>
                <Text fontSize="sm">Labor Card No:</Text>
                <Text fontWeight="bold">{employee.laborCard}</Text>
              </Flex>
              <Flex justifyContent={"space-between"} flexDirection={"column"}>
                <Text fontSize="sm">Rate:</Text>
                <Text fontWeight="bold">{employee.rate}</Text>
              </Flex>
            </Box>
          </Flex>

          {/* Dashboard Boxes */}
          <Flex alignItems="center" mt={4}>
            <Flex alignItems="center" gap={5}>
              <CircularProgressbar
                value={80}
                text={`Good`}
                styles={buildStyles({
                  textColor: "#4CAF50",
                  pathColor: "#4CAF50",
                  trailColor: "#ddd",
                })}
              />

              <DashboardBox
                icon={FaTasks}
                name="Total Work Days"
                value="22"
                backgroundColor="#4a769d"
                type="number"
              />
              <DashboardBox
                icon={FaMoneyBill}
                name="Rate"
                value={employee.rate}
                backgroundColor="#738b19"
                type="text"
              />
            </Flex>
          </Flex>
        </Flex>

        {/* Tabs Section */}
        <Tabs mt={4} variant="enclosed">
          <TabList>
            <Tab
              borderRadius={20}
              _selected={{
                color: "white",
                bg: "primary.500",
              }}
            >
              Accommodation
            </Tab>
            <Tab
              borderRadius={20}
              _selected={{
                color: "white",
                bg: "primary.500",
              }}
            >
              Leave Request
            </Tab>
            <Tab
              borderRadius={20}
              _selected={{
                color: "white",
                bg: "primary.500",
              }}
            >
              Certificates
            </Tab>
            <Tab
              borderRadius={20}
              _selected={{
                color: "white",
                bg: "primary.500",
              }}
            >
              Salary Transactions
            </Tab>
            <Tab
              borderRadius={20}
              _selected={{
                color: "white",
                bg: "primary.500",
              }}
            >
              Medical
            </Tab>
            <Tab
              borderRadius={20}
              _selected={{
                color: "white",
                bg: "primary.500",
              }}
            >
              Insurance
            </Tab>
            <Tab
              borderRadius={20}
              _selected={{
                color: "white",
                bg: "primary.500",
              }}
            >
              Visa
            </Tab>
            <Tab
              borderRadius={20}
              _selected={{
                color: "white",
                bg: "primary.500",
              }}
            >
              Allotment
            </Tab>
            <Tab
              borderRadius={20}
              _selected={{
                color: "white",
                bg: "primary.500",
              }}
            >
              Asset Issued
            </Tab>
            <Tab
              borderRadius={20}
              _selected={{
                color: "white",
                bg: "primary.500",
              }}
            >
              Ticket
            </Tab>
          </TabList>

          <TabPanels>
            {/* Accommodation Tab */}
            <TabPanel>
              <Button
                colorScheme="blue"
                onClick={() => openDrawer("accommodation")}
                leftIcon={<AddIcon />}
              >
                Add Accommodation
              </Button>
              <Box overflowY="auto" maxHeight="200px" mt={4}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Date</Th>
                      <Th>Notes</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {accommodationHistory.map((entry, index) => (
                      <Tr key={index}>
                        <Td>{entry.date}</Td>
                        <Td>{entry.notes}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </TabPanel>

            {/* Leave Request Tab */}
            <TabPanel>
              <Button
                colorScheme="teal"
                onClick={() => openDrawer("leave")}
                leftIcon={<AddIcon />}
              >
                Add Leave Request
              </Button>
              <Box overflowY="auto" maxHeight="200px" mt={4}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Date From</Th>
                      <Th>Date To</Th>
                      <Th>No of Days</Th>
                      <Th>Reason</Th>
                      <Th>Status</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {leaveRequests.map((entry, index) => (
                      <Tr key={index}>
                        <Td>{entry.dateFrom}</Td>
                        <Td>{entry.dateTo}</Td>
                        <Td>{entry.noOfDays}</Td>
                        <Td>{entry.reason}</Td>
                        <Td>
                          <Badge
                            colorScheme={
                              entry.status === "Approved" ? "green" : "orange"
                            }
                          >
                            {entry.status}
                          </Badge>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </TabPanel>

            {/* Certificates Tab */}
            <TabPanel>
              <Button
                colorScheme="green"
                onClick={() => openDrawer("certificate")}
                leftIcon={<AddIcon />}
              >
                Add Certificate
              </Button>
              <Box mt={4}>
                <Flex gap={4}>
                  {certificates.map((cert, index) => (
                    <Box
                      key={index}
                      p={4}
                      bg={cert.bgColor}
                      borderRadius="md"
                      cursor="pointer"
                      onClick={() => openCertModal(cert)}
                      width="fit-content"
                      boxShadow="sm"
                    >
                      <IconButton
                        aria-label={`${cert.type} Icon`}
                        icon={<cert.icon />}
                        isRound
                        size="lg"
                        variant="ghost"
                        color="gray.700"
                      />
                      <Text fontSize="lg" fontWeight="bold">
                        {cert.type} Letter
                      </Text>
                      <Text>Date Issued: {cert.dateIssued}</Text>
                    </Box>
                  ))}
                </Flex>
              </Box>
            </TabPanel>

            {/* Salary Transactions Tab */}
            <TabPanel>
              <SalaryTransactionsTable />
            </TabPanel>

            {/* Medical Tab */}
            <TabPanel>
              <Button
                colorScheme="blue"
                onClick={() => openDrawer("medical")}
                leftIcon={<AddIcon />}
              >
                Add Medical
              </Button>
              <Box overflowY="auto" maxHeight="200px" mt={4}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Date</Th>
                      <Th>Description</Th>
                      <Th>Status</Th>
                      <Th>Remarks</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {medicalHistory.map((entry, index) => (
                      <Tr key={index}>
                        <Td>{entry.date}</Td>
                        <Td>{entry.description}</Td>
                        <Td>
                          <Badge
                            colorScheme={
                              entry.status === "Completed" ? "green" : "orange"
                            }
                          >
                            {entry.status}
                          </Badge>
                        </Td>
                        <Td>{entry.remarks}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </TabPanel>

            {/* Insurance Tab */}
            <TabPanel>
              <Button
                colorScheme="blue"
                onClick={() => setIsInsuranceDrawerOpen(true)}
                leftIcon={<AddIcon />}
              >
                Add Insurance
              </Button>
              <Box overflowY="auto" maxHeight="200px" mt={4}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Date</Th>
                      <Th>Cost</Th>
                      <Th>Renewal Date</Th>
                      <Th>Status</Th>
                      <Th>Remarks</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {insuranceHistoryState.map((entry, index) => (
                      <Tr key={index}>
                        <Td>{entry.date.toLocaleDateString()}</Td>
                        <Td>{entry.cost} AED</Td>
                        <Td>{entry.renewalDate.toLocaleDateString()}</Td>
                        <Td>
                          <Badge
                            colorScheme={
                              entry.status === "Active" ? "green" : "orange"
                            }
                          >
                            {entry.status}
                          </Badge>
                        </Td>
                        <Td>{entry.remarks}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>

              {/* Add Insurance Drawer */}
              <Drawer
                isOpen={isInsuranceDrawerOpen}
                placement="right"
                onClose={() => setIsInsuranceDrawerOpen(false)}
                size="md"
              >
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton />
                  <DrawerHeader>Add Insurance</DrawerHeader>
                  <DrawerBody>
                    <VStack align="start" spacing={4}>
                      <Text>Date:</Text>
                      <DatePicker
                        selected={insuranceDate}
                        onChange={(date) => setInsuranceDate(date)}
                        dateFormat="yyyy-MM-dd"
                      />
                      <Text>Cost (AED):</Text>
                      <NumberInput
                        value={insuranceCost}
                        onChange={(valueString) =>
                          setInsuranceCost(parseFloat(valueString) || 0)
                        }
                        min={0}
                      >
                        <NumberInputField placeholder="Enter cost" />
                      </NumberInput>
                      <Text>Renewal Date:</Text>
                      <DatePicker
                        selected={insuranceRenewalDate}
                        onChange={(date) => setInsuranceRenewalDate(date)}
                        dateFormat="yyyy-MM-dd"
                      />
                      <Text>Status:</Text>
                      <Select
                        placeholder="Select status"
                        value={insuranceStatus}
                        onChange={(e) => setInsuranceStatus(e.target.value)}
                      >
                        <option value="Active">Active</option>
                        <option value="Expired">Expired</option>
                        <option value="Pending">Pending</option>
                      </Select>
                      <Text>Remarks:</Text>
                      <Input
                        placeholder="Enter remarks"
                        value={insuranceRemarks}
                        onChange={(e) => setInsuranceRemarks(e.target.value)}
                      />
                      <Button
                        colorScheme="purple"
                        onClick={() => {
                          setInsuranceHistoryState([
                            ...insuranceHistoryState,
                            {
                              date: insuranceDate,
                              cost: insuranceCost,
                              renewalDate: insuranceRenewalDate,
                              status: insuranceStatus,
                              remarks: insuranceRemarks,
                            },
                          ]);
                          // Reset fields
                          setInsuranceDate(new Date());
                          setInsuranceCost(0);
                          setInsuranceRenewalDate(new Date());
                          setInsuranceStatus("");
                          setInsuranceRemarks("");
                          setIsInsuranceDrawerOpen(false);
                        }}
                        isDisabled={
                          !insuranceDate ||
                          !insuranceCost ||
                          !insuranceRenewalDate ||
                          !insuranceStatus
                        }
                      >
                        Add Insurance
                      </Button>
                    </VStack>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
            </TabPanel>

            {/* Visa Tab */}
            <TabPanel>
              <Button
                colorScheme="blue"
                onClick={() => setIsVisaDrawerOpen(true)}
                leftIcon={<AddIcon />}
              >
                Add Visa
              </Button>
              <Box overflowY="auto" maxHeight="200px" mt={4}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Date</Th>
                      <Th>Visa Expiry</Th>
                      <Th>Visa Renewal</Th>
                      <Th>Visa Duration</Th>
                      <Th>Remarks</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {visaHistoryState.map((entry, index) => (
                      <Tr key={index}>
                        <Td>{entry.date.toLocaleDateString()}</Td>
                        <Td>{entry.visaExpiry.toLocaleDateString()}</Td>
                        <Td>{entry.visaRenewal.toLocaleDateString()}</Td>
                        <Td>{entry.visaDuration}</Td>
                        <Td>{entry.remarks}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>

              {/* Add Visa Drawer */}
              <Drawer
                isOpen={isVisaDrawerOpen}
                placement="right"
                onClose={() => setIsVisaDrawerOpen(false)}
                size="md"
              >
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton />
                  <DrawerHeader>Add Visa</DrawerHeader>
                  <DrawerBody>
                    <VStack align="start" spacing={4}>
                      <Text>Date:</Text>
                      <DatePicker
                        selected={visaDate}
                        onChange={(date) => setVisaDate(date)}
                        dateFormat="yyyy-MM-dd"
                      />
                      <Text>Visa Expiry:</Text>
                      <DatePicker
                        selected={visaExpiry}
                        onChange={(date) => setVisaExpiry(date)}
                        dateFormat="yyyy-MM-dd"
                      />
                      <Text>Visa Renewal:</Text>
                      <DatePicker
                        selected={visaRenewal}
                        onChange={(date) => setVisaRenewal(date)}
                        dateFormat="yyyy-MM-dd"
                      />
                      <Text>Visa Duration:</Text>
                      <Input
                        placeholder="Enter visa duration"
                        value={visaDuration}
                        onChange={(e) => setVisaDuration(e.target.value)}
                      />
                      <Text>Remarks:</Text>
                      <Input
                        placeholder="Enter remarks"
                        value={visaRemarks}
                        onChange={(e) => setVisaRemarks(e.target.value)}
                      />
                      <Button
                        colorScheme="cyan"
                        onClick={() => {
                          setVisaHistoryState([
                            ...visaHistoryState,
                            {
                              date: visaDate,
                              visaExpiry: visaExpiry,
                              visaRenewal: visaRenewal,
                              visaDuration: visaDuration,
                              remarks: visaRemarks,
                            },
                          ]);
                          // Reset fields
                          setVisaDate(new Date());
                          setVisaExpiry(new Date());
                          setVisaRenewal(new Date());
                          setVisaDuration("");
                          setVisaRemarks("");
                          setIsVisaDrawerOpen(false);
                        }}
                        isDisabled={
                          !visaDate ||
                          !visaExpiry ||
                          !visaRenewal ||
                          !visaDuration
                        }
                      >
                        Add Visa
                      </Button>
                    </VStack>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
            </TabPanel>

            {/* Allotment Tab */}
            <TabPanel>
              <Button
                colorScheme="blue"
                onClick={() => openDrawer("allotment")}
                leftIcon={<AddIcon />}
              >
                Add Allotment
              </Button>
              <Box overflowY="auto" maxHeight="200px" mt={4}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Forman Name</Th>
                      <Th>Project Name</Th>
                      <Th>Date</Th>
                      <Th>End Date</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {allotments.map((entry, index) => (
                      <Tr key={index}>
                        <Td>{entry.formanName}</Td>
                        <Td>{entry.projectName}</Td>
                        <Td>{entry.date}</Td>
                        <Td>{entry.endDate}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </TabPanel>

            {/* Asset Issued Tab */}
            <TabPanel>
              <Button
                colorScheme="blue"
                onClick={() => openDrawer("asset")}
                leftIcon={<AddIcon />}
              >
                Add Asset Issued
              </Button>
              <Box overflowY="auto" maxHeight="200px" mt={4}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Type of Asset</Th>
                      <Th>Date</Th>
                      <Th>Remarks</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {assetsIssued.map((entry, index) => (
                      <Tr key={index}>
                        <Td>{entry.assetType}</Td>
                        <Td>{entry.date}</Td>
                        <Td>{entry.remarks}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </TabPanel>

            {/* Ticket Tab */}
            <TabPanel>
              <Button
                colorScheme="blue"
                onClick={() => openDrawer("ticket")}
                leftIcon={<AddIcon />}
              >
                Add Ticket
              </Button>
              <Box overflowY="auto" maxHeight="200px" mt={4}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Date</Th>
                      <Th>Ticket Type</Th>
                      <Th>Trip Type</Th>
                      <Th>Cost</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {tickets.map((entry, index) => (
                      <Tr key={index}>
                        <Td>{entry.date}</Td>
                        <Td>{entry.ticketType}</Td>
                        <Td>{entry.tripType}</Td>
                        <Td>{entry.cost}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      {/* Attendance Sheet Section */}
      <Box p={4} bg="white" borderRadius="md" mt={4} boxShadow="md">
        <Heading as="h3" size="lg" mb={4}>
          Attendance Sheet
        </Heading>
        <Box overflowY="auto" maxHeight="500px">
          <Table variant="simple">
            <Thead position="sticky" top="0" zIndex="1" bg="white">
              <Tr>
                <Th>Date</Th>
                <Th>Day</Th>
                <Th>In</Th>
                <Th>Out</Th>
                <Th>Break Time</Th>
                <Th>Working Hours</Th>
                <Th>Overtime</Th>
                <Th>Performance</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {timesheet.map((entry, index) => (
                <Tr key={index}>
                  <Td>{entry.date}</Td>
                  <Td>{entry.day}</Td>
                  <Td>{entry.inTime}</Td>
                  <Td>{entry.outTime}</Td>
                  <Td>{entry.breakTime}</Td>
                  <Td>{entry.workingHours}</Td>
                  <Td>{entry.overtime}</Td>
                  <Td>{entry.performance}</Td>
                  <Td>
                    <Button colorScheme="green" size="sm">
                      Approve
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>

      <Box p={6} bg="white" borderRadius="lg" mt={6} boxShadow="lg">
        <Heading as="h3" size="lg" mb={6} color="teal.600" textAlign="center">
          Salary Calculation
        </Heading>
        <Flex justifyContent="space-between" alignItems="flex-start" gap={6}>
          <Flex
            flexDirection="column"
            flex="1"
            bg="gray.50"
            p={4}
            borderRadius="md"
          >
            <Table variant="unstyled">
              <Tbody>
                <Tr>
                  <Td fontWeight="bold" color="gray.700">
                    Basic Amount:
                  </Td>
                  <Td>
                    <NumberInput
                      value={basicAmount}
                      onChange={(valueString) =>
                        setBasicAmount(parseFloat(valueString))
                      }
                      size="sm"
                      variant="flushed"
                    >
                      <NumberInputField />
                    </NumberInput>
                    <Badge mt={2} colorScheme="purple">
                      Rate per Hour: {(basicAmount / 22).toFixed(2)} AED
                    </Badge>
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold" color="gray.700">
                    Overtime Amount:
                  </Td>
                  <Td color="teal.500" fontWeight="bold">
                    {overtimeAmount} AED
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold" color="gray.700">
                    Salary Advance:
                  </Td>
                  <Td>
                    <NumberInput
                      value={salaryAdvance}
                      onChange={(valueString) =>
                        setSalaryAdvance(parseFloat(valueString))
                      }
                      size="sm"
                      variant="flushed"
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold" color="gray.700">
                    Other Benefits:
                  </Td>
                  <Td>
                    <NumberInput
                      value={otherBenefits}
                      onChange={(valueString) =>
                        setOtherBenefits(parseFloat(valueString))
                      }
                      size="sm"
                      variant="flushed"
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Flex>

          <Flex
            flexDirection="column"
            flex="1"
            bg="gray.50"
            p={4}
            borderRadius="md"
          >
            <Table variant="unstyled">
              <Tbody>
                <Tr>
                  <Td fontWeight="bold" color="gray.700">
                    Absent Deduction:
                  </Td>
                  <Td color="red.500" fontWeight="bold">
                    {absentDeduction} AED
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold" color="gray.700">
                    Excellent Work Allowance:
                  </Td>
                  <Td>
                    <NumberInput
                      value={excellentWorkAllowance}
                      onChange={(valueString) =>
                        setExcellentWorkAllowance(parseFloat(valueString))
                      }
                      size="sm"
                      variant="flushed"
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold" color="gray.700">
                    Bonus:
                  </Td>
                  <Td>
                    <NumberInput
                      value={bonus}
                      onChange={(valueString) =>
                        setBonus(parseFloat(valueString))
                      }
                      size="sm"
                      variant="flushed"
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold" color="gray.700">
                    Remarks:
                  </Td>
                  <Td>
                    <Input
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                      placeholder="Enter remarks"
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Flex>

          <Box
            flex="1"
            bg="teal.600"
            color="white"
            p={6}
            borderRadius="lg"
            textAlign="center"
            boxShadow="md"
          >
            <Text fontSize="lg" fontWeight="bold" mb={2}>
              Total Net Salary
            </Text>
            <Text fontSize="3xl" fontWeight="bold">
              {calculateNetSalary()} AED
            </Text>
            <Button
              mt={6}
              colorScheme="whiteAlpha"
              variant="outline"
              onClick={handleProcessSalary}
              isLoading={processLoading}
              loadingText="Processing"
              disabled={processLoading}
              width="100%"
              size="lg"
            >
              Process Salary
            </Button>
            {!processLoading && (
              <Text fontSize="xl" fontWeight="bold" mt={4}>
                Salary Processed
              </Text>
            )}
          </Box>
        </Flex>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Employee Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Name: {employee.name}</Text>
            <Text>Designation: {employee.designation}</Text>
            <Text>Joining Date: {employee.joiningDate}</Text>
            <Text>Bank: {employee.bank}</Text>
            <Text>Account No: {employee.accountNo}</Text>
            <Text>Work Area: {employee.workArea}</Text>
            <Text>Labor Card: {employee.laborCard}</Text>
            <Text>Visa: {employee.visa}</Text>
            <Text>Passport No: {employee.passportNo}</Text>
            <Text>Nationality: {employee.nationality}</Text>
            <Text>Passport Expiry: {employee.passportExpiry}</Text>
            <Text>Visa Expiry: {employee.visaExpiry}</Text>
            <Text>Self Contact: {employee.selfContact}</Text>
            <Text>Family Contact: {employee.familyContact}</Text>
            <Text>Performance: {employee.performance}</Text>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Drawer
        isOpen={isDrawerOpen && drawerType === "accommodation"}
        placement="right"
        onClose={closeDrawer}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Accommodation</DrawerHeader>
          <DrawerBody>
            <VStack align="start" spacing={4}>
              <Text>Notes:</Text>
              <Input placeholder="Enter notes" />
              <Button colorScheme="teal">Update</Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Drawer
        isOpen={isDrawerOpen && drawerType === "medical"}
        placement="right"
        onClose={closeDrawer}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add Medical</DrawerHeader>
          <DrawerBody>
            <VStack align="start" spacing={4}>
              <Text>Date:</Text>
              <DatePicker selected={selectedDate} onChange={setSelectedDate} />
              <Text>Description:</Text>
              <Input placeholder="Enter description" />
              <Text>Type:</Text>
              <Input placeholder="Enter type" />
              <Text>Renewal Date:</Text>
              <DatePicker selected={selectedDate} onChange={setSelectedDate} />
              <Text>Cost:</Text>
              <NumberInput>
                <NumberInputField placeholder="Enter cost" />
              </NumberInput>
              <Button colorScheme="teal">Add Medical</Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Drawer
        isOpen={isDrawerOpen && drawerType === "allotment"}
        placement="right"
        onClose={closeDrawer}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add Allotment</DrawerHeader>
          <DrawerBody>
            <VStack align="start" spacing={4}>
              <Text>Forman Name:</Text>
              <Input placeholder="Enter forman name" />
              <Text>Project Name:</Text>
              <Input placeholder="Enter project name" />
              <Text>Date:</Text>
              <DatePicker selected={selectedDate} onChange={setSelectedDate} />
              <Text>End Date:</Text>
              <DatePicker selected={selectedDate} onChange={setSelectedDate} />
              <Button colorScheme="teal">Add Allotment</Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Drawer
        isOpen={isDrawerOpen && drawerType === "asset"}
        placement="right"
        onClose={closeDrawer}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add Asset Issued</DrawerHeader>
          <DrawerBody>
            <VStack align="start" spacing={4}>
              <Text>Type of Asset:</Text>
              <Select placeholder="Select asset type">
                <option value="uniform">Uniform</option>
                <option value="tool bag">Tool Bag</option>
                <option value="safety equipment">Safety Equipment</option>
              </Select>
              <Text>Date:</Text>
              <DatePicker selected={selectedDate} onChange={setSelectedDate} />
              <Text>Remarks:</Text>
              <Input placeholder="Enter remarks" />
              <Button colorScheme="teal">Add Asset</Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Drawer
        isOpen={isDrawerOpen && drawerType === "ticket"}
        placement="right"
        onClose={closeDrawer}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add Ticket</DrawerHeader>
          <DrawerBody>
            <VStack align="start" spacing={4}>
              <Text>Date:</Text>
              <DatePicker selected={selectedDate} onChange={setSelectedDate} />
              <Text>Ticket Type:</Text>
              <Input placeholder="Enter ticket type" />
              <Text>Trip Type:</Text>
              <Select placeholder="Select trip type">
                <option value="single">Single Trip</option>
                <option value="round">Round Trip</option>
              </Select>
              <Text>Cost:</Text>
              <NumberInput>
                <NumberInputField placeholder="Enter cost" />
              </NumberInput>
              <Button colorScheme="teal">Add Ticket</Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Modal
        isOpen={certModalDisclosure.isOpen}
        onClose={certModalDisclosure.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedCert?.type} Letter</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight="bold" fontSize="lg">
              {selectedCert?.type} Letter
            </Text>
            <Text>Date Issued: {selectedCert?.dateIssued}</Text>
            <Text mt={4}>
              This is the content of the {selectedCert?.type} letter issued on{" "}
              {selectedCert?.dateIssued}.
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EmployeeDetails;
