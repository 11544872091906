import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

// Initial state for work done entries
const initialState = {
  works: [],
};

const workDoneSlice = createSlice({
  name: "workDone",
  initialState,
  reducers: {
    addWorkDone: (state, action) => {
      state.works.push({ id: uuidv4(), ...action.payload });
    },
    updateWorkDone: (state, action) => {
      const { id, updatedData } = action.payload;
      const existingWork = state.works.find((work) => work.id === id);
      if (existingWork) {
        Object.assign(existingWork, updatedData);
      }
    },
    deleteWorkDone: (state, action) => {
      const { id } = action.payload;
      state.works = state.works.filter((work) => work.id !== id);
    },
  },
});

export const { addWorkDone, updateWorkDone, deleteWorkDone } =
  workDoneSlice.actions;

export const selectWorkDone = (state) => state.workDone.works;
export const selectWorkDoneById = (state, id) =>
  state.workDone.works.find((work) => work.id === id);

export default workDoneSlice.reducer;
