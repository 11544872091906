// src/components/Todo.js
import React, { useState, useMemo } from "react";
import {
  Box,
  Button,
  VStack,
  Text,
  Flex,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  HStack,
  Checkbox,
  useToast,
  Spacer,
  IconButton,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import GenericHeader from "../../commons/GenericHeader";
import { LuListTodo } from "react-icons/lu";
import {
  addTask,
  toggleTaskCompletion,
  deleteTask,
  editTask,
} from "../../store/todosSlice";
import TaskItem from "./TaskItem";
import { v4 as uuidv4 } from "uuid"; // For unique IDs

const Todo = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const dispatch = useDispatch();
  const toast = useToast();

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    dueDate: "",
    priority: "Low",
    reminder: "",
    repeat: "None",
    color: "none", // Default color as 'none'
  });

  const tasks = useSelector((state) => state.todos.tasks);

  const [searchTerm, setSearchTerm] = useState("");
  const [filterPriority, setFilterPriority] = useState({
    High: false,
    Medium: false,
    Low: false,
  });
  const [filterDueDate, setFilterDueDate] = useState("");

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "High" || name === "Medium" || name === "Low") {
      setFilterPriority((prev) => ({
        ...prev,
        [name]: checked,
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleAddTask = () => {
    if (!formData.description || !formData.dueDate) {
      toast({
        title: "Incomplete Information",
        description: "Please fill out all required fields.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const newTask = {
      id: uuidv4(), // Use UUID for unique IDs
      title: formData.title, // Although title is removed, keeping it for potential future use
      description: formData.description,
      dueDate: formData.dueDate,
      priority: formData.priority,
      reminder: formData.reminder,
      repeat: formData.repeat,
      color: formData.color,
      completed: false,
    };

    dispatch(addTask(newTask));
    onClose();
    setFormData({
      title: "",
      description: "",
      dueDate: "",
      priority: "Low",
      reminder: "",
      repeat: "None",
      color: "none",
    });
    toast({
      title: "Task Added",
      description: `Task "${newTask.description}" has been added.`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleToggleCompletion = (id) => {
    dispatch(toggleTaskCompletion(id));
    toast({
      title: "Task Updated",
      description: "Task completion status has been toggled.",
      status: "info",
      duration: 2000,
      isClosable: true,
    });
  };

  const handleDeleteTask = (id) => {
    dispatch(deleteTask(id));
    toast({
      title: "Task Deleted",
      description: "The task has been removed.",
      status: "error",
      duration: 2000,
      isClosable: true,
    });
  };

  const openEditDrawer = (task) => {
    setCurrentTask(task);
    setFormData({
      title: task.title,
      description: task.description,
      dueDate: task.dueDate,
      priority: task.priority,
      reminder: task.reminder,
      repeat: task.repeat,
      color: task.color,
    });
    setIsEditOpen(true);
  };

  const handleEditTask = () => {
    if (!formData.description || !formData.dueDate) {
      toast({
        title: "Incomplete Information",
        description: "Please fill out all required fields.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const updatedTask = {
      id: currentTask.id,
      title: formData.title,
      description: formData.description,
      dueDate: formData.dueDate,
      priority: formData.priority,
      reminder: formData.reminder,
      repeat: formData.repeat,
      color: formData.color,
    };

    dispatch(editTask(updatedTask));
    setIsEditOpen(false);
    setCurrentTask(null);
    setFormData({
      title: "",
      description: "",
      dueDate: "",
      priority: "Low",
      reminder: "",
      repeat: "None",
      color: "none",
    });
    toast({
      title: "Task Updated",
      description: `Task "${updatedTask.description}" has been updated.`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  // Sorting tasks based on priority: High > Medium > Low
  const sortedTasks = useMemo(() => {
    const priorityOrder = { High: 1, Medium: 2, Low: 3 };
    return [...tasks].sort(
      (a, b) => priorityOrder[a.priority] - priorityOrder[b.priority]
    );
  }, [tasks]);

  // Filtering tasks based on search and filters
  const filteredTasks = useMemo(() => {
    return sortedTasks.filter((task) => {
      // Search Filter
      const matchesSearch = task.description
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

      // Priority Filter
      const activePriorities = Object.keys(filterPriority).filter(
        (key) => filterPriority[key]
      );
      const matchesPriority =
        activePriorities.length > 0
          ? activePriorities.includes(task.priority)
          : true;

      // Due Date Filter
      const matchesDueDate = filterDueDate
        ? task.dueDate === filterDueDate
        : true;

      return matchesSearch && matchesPriority && matchesDueDate;
    });
  }, [sortedTasks, searchTerm, filterPriority, filterDueDate]);

  // Split tasks into incomplete and completed
  const incompleteTasks = filteredTasks.filter((task) => !task.completed);
  const completedTasks = filteredTasks.filter((task) => task.completed);

  return (
    <Box minH="100vh">
      {/* Header */}
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <GenericHeader title="Todos" icon={<LuListTodo />} />
        <Button leftIcon={<AddIcon />} colorScheme="primary" onClick={onOpen}>
          Add New Task
        </Button>
      </Flex>

      {/* Filters and Search */}
      <Flex mb={6} alignItems="center">
        {/* Search */}
        <Input
          placeholder="Search tasks..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          maxW="300px"
        />
        <Spacer />
        {/* Priority Filters */}
        <HStack spacing={4}>
          <Checkbox
            isChecked={filterPriority.High}
            onChange={handleInputChange}
            name="High"
          >
            High
          </Checkbox>
          <Checkbox
            isChecked={filterPriority.Medium}
            onChange={handleInputChange}
            name="Medium"
          >
            Medium
          </Checkbox>
          <Checkbox
            isChecked={filterPriority.Low}
            onChange={handleInputChange}
            name="Low"
          >
            Low
          </Checkbox>
          {/* Due Date Filter */}
          <FormControl>
            <Input
              type="date"
              value={filterDueDate}
              onChange={(e) => setFilterDueDate(e.target.value)}
            />
          </FormControl>
        </HStack>
      </Flex>

      {/* Task List */}
      <VStack spacing={4} align="stretch">
        {incompleteTasks.length === 0 && completedTasks.length === 0 ? (
          <Text color="gray.500">
            No tasks available. Add a new task to get started!
          </Text>
        ) : (
          <>
            {/* Incomplete Tasks */}
            {incompleteTasks.length > 0 && (
              <VStack spacing={4} align="stretch">
                {incompleteTasks.map((task) => (
                  <TaskItem
                    key={task.id}
                    task={task}
                    onToggle={handleToggleCompletion}
                    onEdit={openEditDrawer}
                    onDelete={handleDeleteTask}
                  />
                ))}
              </VStack>
            )}

            {/* Completed Tasks */}
            {completedTasks.length > 0 && (
              <Box mt={6}>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                  Completed
                </Text>
                <VStack spacing={4} align="stretch">
                  {completedTasks.map((task) => (
                    <TaskItem
                      key={task.id}
                      task={task}
                      onToggle={handleToggleCompletion}
                      onEdit={openEditDrawer}
                      onDelete={handleDeleteTask}
                    />
                  ))}
                </VStack>
              </Box>
            )}
          </>
        )}
      </VStack>

      {/* Drawer for Adding New Todo */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Create a New Task</DrawerHeader>

          <DrawerBody>
            <FormControl isRequired mb={4}>
              {/* Title Field Removed as per request */}
              {/* <FormLabel>Title</FormLabel>
              <Input
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                placeholder="Enter task title"
              /> */}
            </FormControl>

            <FormControl isRequired mb={4}>
              <FormLabel>Description</FormLabel>
              <Textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Enter task description"
              />
            </FormControl>

            <FormControl isRequired mb={4}>
              <FormLabel>Due Date</FormLabel>
              <Input
                type="date"
                name="dueDate"
                value={formData.dueDate}
                onChange={handleInputChange}
              />
            </FormControl>

            <HStack spacing={4} mb={4}>
              <FormControl>
                <FormLabel>Priority</FormLabel>
                <Select
                  name="priority"
                  value={formData.priority}
                  onChange={handleInputChange}
                >
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Color</FormLabel>
                <Select
                  name="color"
                  value={formData.color}
                  onChange={handleInputChange}
                >
                  <option value="none">No Color</option>
                  <option value="blue">Blue</option>
                  <option value="green">Green</option>
                  <option value="red">Red</option>
                  <option value="yellow">Yellow</option>
                  <option value="purple">Purple</option>
                  <option value="teal">Teal</option>
                </Select>
              </FormControl>
            </HStack>

            <FormControl mb={4}>
              <FormLabel>Remind Me</FormLabel>
              <Input
                type="datetime-local"
                name="reminder"
                value={formData.reminder}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Repeat</FormLabel>
              <Select
                name="repeat"
                value={formData.repeat}
                onChange={handleInputChange}
              >
                <option value="None">None</option>
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
                <option value="Yearly">Yearly</option>
              </Select>
            </FormControl>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="teal" onClick={handleAddTask}>
              Add Task
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      {/* Drawer for Editing Task */}
      <Drawer
        isOpen={isEditOpen}
        placement="right"
        onClose={() => setIsEditOpen(false)}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Edit Task</DrawerHeader>

          <DrawerBody>
            <FormControl isRequired mb={4}>
              {/* Title Field Removed as per request */}
              {/* <FormLabel>Title</FormLabel>
              <Input
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                placeholder="Enter task title"
              /> */}
            </FormControl>

            <FormControl isRequired mb={4}>
              <FormLabel>Description</FormLabel>
              <Textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Enter task description"
              />
            </FormControl>

            <FormControl isRequired mb={4}>
              <FormLabel>Due Date</FormLabel>
              <Input
                type="date"
                name="dueDate"
                value={formData.dueDate}
                onChange={handleInputChange}
              />
            </FormControl>

            <HStack spacing={4} mb={4}>
              <FormControl>
                <FormLabel>Priority</FormLabel>
                <Select
                  name="priority"
                  value={formData.priority}
                  onChange={handleInputChange}
                >
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Color</FormLabel>
                <Select
                  name="color"
                  value={formData.color}
                  onChange={handleInputChange}
                >
                  <option value="none">No Color</option>
                  <option value="blue">Blue</option>
                  <option value="green">Green</option>
                  <option value="red">Red</option>
                  <option value="yellow">Yellow</option>
                  <option value="purple">Purple</option>
                  <option value="teal">Teal</option>
                </Select>
              </FormControl>
            </HStack>

            <FormControl mb={4}>
              <FormLabel>Remind Me</FormLabel>
              <Input
                type="datetime-local"
                name="reminder"
                value={formData.reminder}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Repeat</FormLabel>
              <Select
                name="repeat"
                value={formData.repeat}
                onChange={handleInputChange}
              >
                <option value="None">None</option>
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
                <option value="Yearly">Yearly</option>
              </Select>
            </FormControl>
          </DrawerBody>

          <DrawerFooter>
            <Button
              variant="outline"
              mr={3}
              onClick={() => setIsEditOpen(false)}
            >
              Cancel
            </Button>
            <Button colorScheme="teal" onClick={handleEditTask}>
              Save Changes
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Todo;
