// CertificateCard.jsx
import React from "react";
import { Box, Text, Tag, Flex } from "@chakra-ui/react";

const CertificateCard = ({ certificate, onClick }) => {
  return (
    <Box
      border="1px solid #ccc"
      borderRadius="md"
      bg={"white"}
      p={4}
      boxShadow="md"
      transition="transform 0.2s ease-in-out"
      _hover={{ transform: "scale(1.05)", cursor: "pointer" }}
      position="relative"
      onClick={onClick}
      minWidth="250px"
      height="150px"
    >
      <Tag colorScheme="blackAlpha" position="absolute" top={1} right={1}>
        AED {certificate.cost}
      </Tag>
      <Text fontSize="lg" fontWeight="bold" mt={2} mb={2}>
        {certificate.name}
      </Text>
      <Text fontSize="sm" mb={2}>
        {certificate.remarks}
      </Text>
      <Flex justifyContent="space-between" mt="auto">
        <Text color="gray.300" fontSize="sm">
          Issuance
        </Text>
        <Text color="gray.300" fontSize="sm">
          Expiry
        </Text>
      </Flex>
      <Flex justifyContent="space-between" mt="auto">
        <Text color="blue.500" fontSize="sm" fontWeight={700}>
          {certificate.issuance}
        </Text>
        <Text color="red.500" fontSize="sm" fontWeight={700}>
          {certificate.expiry}
        </Text>
      </Flex>
    </Box>
  );
};

export default CertificateCard;
