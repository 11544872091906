import React, { useState, useEffect } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  Select,
} from "@chakra-ui/react";

const DrawerForm = ({ isOpen, onClose, isEdit, item, section, onSave }) => {
  const [formState, setFormState] = useState({});

  useEffect(() => {
    if (item) {
      setFormState(item);
    } else {
      setFormState({});
    }
  }, [item]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSave = () => {
    onSave(formState);
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{isEdit ? "Edit Item" : "Add New Item"}</DrawerHeader>

        <DrawerBody>
          {/* Equipment & Materials Form */}
          {section === "equipment" && (
            <>
              <FormControl isRequired>
                <FormLabel>Equipment</FormLabel>
                <Input
                  name="equipment"
                  value={formState.equipment || ""}
                  onChange={handleInputChange}
                  placeholder="Enter Equipment Type"
                />
              </FormControl>
              <FormControl isRequired mt={4}>
                <FormLabel>Quantity</FormLabel>
                <Input
                  name="quantity"
                  type="number"
                  value={formState.quantity || ""}
                  onChange={handleInputChange}
                  placeholder="Enter Quantity"
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Assigned To</FormLabel>
                <Input
                  name="assignedTo"
                  value={formState.assignedTo || ""}
                  onChange={handleInputChange}
                  placeholder="Enter Assigned To"
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Planned Start Date</FormLabel>
                <Input
                  name="startDate"
                  type="date"
                  value={formState.startDate || ""}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Planned End Date</FormLabel>
                <Input
                  name="endDate"
                  type="date"
                  value={formState.endDate || ""}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Status</FormLabel>
                <Select
                  name="status"
                  value={formState.status || ""}
                  onChange={handleInputChange}
                >
                  <option value="In Use">In Use</option>
                  <option value="Ordered">Ordered</option>
                </Select>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Notes</FormLabel>
                <Input
                  name="notes"
                  value={formState.notes || ""}
                  onChange={handleInputChange}
                  placeholder="Enter Notes"
                />
              </FormControl>
            </>
          )}

          {/* Personnel Form */}
          {section === "personnel" && (
            <>
              <FormControl isRequired>
                <FormLabel>Skill</FormLabel>
                <Input
                  name="skill"
                  value={formState.skill || ""}
                  onChange={handleInputChange}
                  placeholder="Enter Skill"
                />
              </FormControl>
              <FormControl isRequired mt={4}>
                <FormLabel>Quantity</FormLabel>
                <Input
                  name="quantity"
                  type="number"
                  value={formState.quantity || ""}
                  onChange={handleInputChange}
                  placeholder="Enter Quantity"
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Planned Start Date</FormLabel>
                <Input
                  name="startDate"
                  type="date"
                  value={formState.startDate || ""}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Planned End Date</FormLabel>
                <Input
                  name="endDate"
                  type="date"
                  value={formState.endDate || ""}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Status</FormLabel>
                <Select
                  name="status"
                  value={formState.status || ""}
                  onChange={handleInputChange}
                >
                  <option value="Active">Active</option>
                  <option value="On Leave">On Leave</option>
                </Select>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Notes</FormLabel>
                <Input
                  name="notes"
                  value={formState.notes || ""}
                  onChange={handleInputChange}
                  placeholder="Enter Notes"
                />
              </FormControl>
            </>
          )}

          {/* Finances Form */}
          {section === "finance" && (
            <>
              <FormControl isRequired>
                <FormLabel>Expense Description</FormLabel>
                <Input
                  name="expenseDescription"
                  value={formState.expenseDescription || ""}
                  onChange={handleInputChange}
                  placeholder="Enter Expense Description"
                />
              </FormControl>
              <FormControl isRequired mt={4}>
                <FormLabel>Expected Amount</FormLabel>
                <Input
                  name="expectedAmount"
                  type="number"
                  value={formState.expectedAmount || ""}
                  onChange={handleInputChange}
                  placeholder="Enter Expected Amount"
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Planned Start Date</FormLabel>
                <Input
                  name="startDate"
                  type="date"
                  value={formState.startDate || ""}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Planned End Date</FormLabel>
                <Input
                  name="endDate"
                  type="date"
                  value={formState.endDate || ""}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Status</FormLabel>
                <Select
                  name="status"
                  value={formState.status || ""}
                  onChange={handleInputChange}
                >
                  <option value="Paid">Paid</option>
                  <option value="Pending">Pending</option>
                </Select>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Notes</FormLabel>
                <Input
                  name="notes"
                  value={formState.notes || ""}
                  onChange={handleInputChange}
                  placeholder="Enter Notes"
                />
              </FormControl>
            </>
          )}
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSave}>
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerForm;
