// AddAccountDrawer.jsx
import React, { useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  Select,
} from "@chakra-ui/react";

const AddAccountDrawer = ({ isOpen, onClose }) => {
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [category, setCategory] = useState("");

  const handleAddAccount = () => {
    // Logic to add the account, e.g., call to the backend or state update
    console.log("Account Added:", { name, type, category });
    onClose();
  };

  // Define category options as an array for better maintainability
  const categoryOptions = [
    "Subcontractor",
    "Employee",
    "Inventory",
    "Transport",
    "Customer",
    "Bank",
    "Utility Bills",
    "Home Expenses",
    "Remittance",
    "Loan",
    "Project Commissions",
    "Different Parties Accounts",
    "Individual Account",
    "Personal Category",
    "Asset",
  ];

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add New Account</DrawerHeader>

        <DrawerBody>
          <FormControl isRequired>
            <FormLabel>Account Name</FormLabel>
            <Input
              placeholder="Enter account name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>

          <FormControl mt={4} isRequired>
            <FormLabel>Account Type</FormLabel>
            <Select
              placeholder="Select account type"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="Asset">Asset</option>
              <option value="Liability">Liability</option>
              <option value="Expense">Expense</option>
              <option value="Revenue">Revenue</option>
              <option value="Equity">Equity</option>
            </Select>
          </FormControl>

          <FormControl mt={4} isRequired>
            <FormLabel>Category</FormLabel>
            <Select
              placeholder="Select category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              {categoryOptions.map((cat, index) => (
                <option key={index} value={cat}>
                  {cat}
                </option>
              ))}
            </Select>
          </FormControl>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleAddAccount}
            isDisabled={!name || !type || !category} // Disable button if required fields are empty
          >
            Add Account
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default AddAccountDrawer;
