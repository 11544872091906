import React, { useState, useCallback, useMemo } from "react";
import {
  Box,
  Flex,
  Button,
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
  FormControl,
  FormLabel,
  Switch,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import debounce from "lodash/debounce";
import { GoColumns, GoQuote } from "react-icons/go";
import { GrFilter } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import DashboardBox from "../commons/DashboardBox";
import SearchInput from "../commons/SearchInput";
import GenericGrid from "../components/Procurement/GenericGrid";
import GenericHeader from "../commons/GenericHeader";

const QuotationManagementList = () => {
  const [quotations, setQuotations] = useState([
    {
      id: 1,
      date: "2024-01-01",
      clientName: "ABC Company",
      projectDescription: "Electrical Wiring Project",
      totalAmount: 5000,
      status: "Pending",
    },
    {
      id: 2,
      date: "2024-01-02",
      clientName: "ABC Company",
      projectDescription: "Plumbing Installation",
      totalAmount: 3000,
      status: "Approved",
    },
    {
      id: 3,
      date: "2024-01-03",
      clientName: "XYZ Company",
      projectDescription: "Roof Repair",
      totalAmount: 2000,
      status: "Rejected",
    },
  ]);

  const [filteredData, setFilteredData] = useState(quotations);
  const [originalData] = useState(quotations);
  const [searchText, setSearchText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [visibleColumns, setVisibleColumns] = useState([
    "id",
    "date",
    "clientName",
    "projectDescription",
    "totalAmount",
    "status",
    "actions",
  ]);

  const toast = useToast();
  const navigate = useNavigate();
  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();
  const {
    isOpen: isColumnModalOpen,
    onOpen: onOpenColumnModal,
    onClose: onCloseColumnModal,
  } = useDisclosure();

  const debouncedApplySearchFilter = useCallback(
    debounce((text) => applySearchFilter(text), 300),
    []
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedApplySearchFilter(text);
  };

  const applySearchFilter = (text) => {
    const filtered = originalData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(text.toLowerCase())
      )
    );
    setFilteredData(filtered);
  };

  const applyFilter = () => {
    const filtered = originalData.filter((quotation) =>
      selectedStatus ? quotation.status === selectedStatus : true
    );
    setFilteredData(filtered);
    toast({
      title: "Filters Applied",
      description: "Data has been filtered",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const clearFilter = () => {
    setSelectedStatus("");
    setFilteredData(originalData);
    toast({
      title: "Filters Cleared",
      description: "Filters have been cleared",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const handleVisibleColumnsChange = (columnId) => {
    const updatedColumns = visibleColumns.includes(columnId)
      ? visibleColumns.filter((col) => col !== columnId)
      : [...visibleColumns, columnId];
    setVisibleColumns(updatedColumns);
  };

  const handleDelete = (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this quotation?"
    );
    if (confirmed) {
      setFilteredData(filteredData.filter((quote) => quote.id !== id));
      toast({
        title: "Quotation deleted.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const columns = [
    {
      id: "id",
      header: "ID",
      accessor: (item) => item.id,
      show: true,
    },
    {
      id: "date",
      header: "Date",
      accessor: (item) => item.date,
      show: true,
    },
    {
      id: "clientName",
      header: "Client Name",
      accessor: (item) => item.clientName,
      show: true,
    },
    {
      id: "projectDescription",
      header: "Project Description",
      accessor: (item) => item.projectDescription,
      show: true,
    },
    {
      id: "totalAmount",
      header: "Total Amount (AED)",
      accessor: (item) => item.totalAmount,
      show: true,
    },
    {
      id: "status",
      header: "Status",
      accessor: (item) => item.status,
      show: true,
    },
  ];

  // Dashboard calculations
  const totalQuotations = originalData.length;
  const pendingQuotations = originalData.filter(
    (quote) => quote.status === "Pending"
  ).length;
  const approvedQuotations = originalData.filter(
    (quote) => quote.status === "Approved"
  ).length;
  const rejectedQuotations = originalData.filter(
    (quote) => quote.status === "Rejected"
  ).length;

  const filters = useMemo(
    () => ({
      status: selectedStatus,
    }),
    [selectedStatus]
  );

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <GenericHeader title="Quotation Management" icon={<GoQuote />} />
        <Button
          leftIcon={<AddIcon />}
          colorScheme="teal"
          onClick={() => navigate("/quotations/add")}
        >
          Add Quotation
        </Button>
      </Flex>

      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Flex
          alignItems="center"
          justifyContent="space-around"
          width="100%"
          gap={2}
        >
          <DashboardBox
            name="Total Quotations"
            value={totalQuotations}
            backgroundColor="blue.300"
          />
          <DashboardBox
            name="Pending Quotations"
            value={pendingQuotations}
            backgroundColor="yellow.300"
          />
          <DashboardBox
            name="Approved Quotations"
            value={approvedQuotations}
            backgroundColor="green.300"
          />
          <DashboardBox
            name="Rejected Quotations"
            value={rejectedQuotations}
            backgroundColor="red.300"
          />
        </Flex>
      </Flex>

      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Button leftIcon={<GrFilter />} onClick={onOpenFilterModal}>
          Filter
        </Button>
        <SearchInput
          placeholder="Search Quotations"
          value={searchText}
          onChange={handleSearchInputChange}
        />
        <Button leftIcon={<GoColumns />} onClick={onOpenColumnModal}>
          Toggle Columns
        </Button>
      </Flex>

      <Flex mb={4}>
        <Flex>
          {filters.status && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Status: ${filters.status}`}</TagLabel>
              <TagCloseButton onClick={() => setSelectedStatus("")} />
            </Tag>
          )}
        </Flex>
      </Flex>

      <GenericGrid
        columns={columns.filter((col) => visibleColumns.includes(col.id))}
        data={filteredData}
        title="Quotation List"
        visibleColumnsState={visibleColumns}
        filterState={filters} // Pass filters to GenericGrid
      />

      {/* Filter Modal */}
      <Modal isOpen={isFilterModalOpen} onClose={onCloseFilterModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select
              placeholder="Select Status"
              onChange={(e) => setSelectedStatus(e.target.value)}
              mb={4}
              value={selectedStatus}
            >
              <option value="Pending">Pending</option>
              <option value="Approved">Approved</option>
              <option value="Rejected">Rejected</option>
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={applyFilter} mr={3}>
              Apply Filter
            </Button>
            <Button variant="ghost" onClick={clearFilter}>
              Clear Filter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Column Toggle Modal */}
      <Modal isOpen={isColumnModalOpen} onClose={onCloseColumnModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Toggle Columns</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {columns.map((col) => (
              <FormControl
                display="flex"
                alignItems="center"
                key={col.id}
                mb={2}
              >
                <FormLabel htmlFor={col.id} mb="0" flex="1">
                  {col.header}
                </FormLabel>
                <Switch
                  id={col.id}
                  isChecked={visibleColumns.includes(col.id)}
                  onChange={() => handleVisibleColumnsChange(col.id)}
                />
              </FormControl>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onCloseColumnModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default QuotationManagementList;
