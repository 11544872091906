// CertificateList.jsx
import React, { useState } from "react";
import { Box, Flex, Button, Wrap, WrapItem } from "@chakra-ui/react";
import CertificateCard from "./CertificateCard";
import CertificateDetail from "./CertificateDetail";
import CertificateDrawer from "./CertificateDrawer";
import GenericHeader from "../../commons/GenericHeader";
import { FaPlus } from "react-icons/fa";
import { GrCertificate } from "react-icons/gr";

const certificatesData = [
  {
    id: 1,
    name: "Office Ejari Contract",
    issuance: "2023-01-01",
    expiry: "2024-01-01",
    cost: 500,
    remarks: "Annual contract",
  },
  {
    id: 2,
    name: "Company Trade License",
    issuance: "2023-05-01",
    expiry: "2024-05-01",
    cost: 2000,
    remarks: "License renewal",
  },
  // Other certificates
];

const CertificateList = () => {
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isAddMode, setAddMode] = useState(false);

  const handleCardClick = (certificate) => {
    setSelectedCertificate(certificate);
    setAddMode(false);
  };

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setAddMode(false);
  };

  const handleBack = () => {
    setSelectedCertificate(null);
  };

  const handleAddCertificate = () => {
    setAddMode(true);
    setSelectedCertificate(null);
    setDrawerOpen(true);
  };

  const handleUpdateCertificate = () => {
    setAddMode(false);
    setDrawerOpen(true);
  };

  return (
    <Box>
      {!selectedCertificate && (
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <GenericHeader
            title="Yearly Renewal Certificates"
            icon={<GrCertificate size="24px" />}
          />
          <Button
            leftIcon={<FaPlus />}
            colorScheme="primary"
            onClick={handleAddCertificate}
          >
            Add Certificate
          </Button>
        </Flex>
      )}
      <Box>
        {selectedCertificate ? (
          <CertificateDetail
            certificate={selectedCertificate}
            onOpenDrawer={handleUpdateCertificate}
            onBack={handleBack}
          />
        ) : (
          <Wrap spacing="20px">
            {certificatesData.map((cert) => (
              <WrapItem key={cert.id}>
                <CertificateCard
                  certificate={cert}
                  onClick={() => handleCardClick(cert)}
                />
              </WrapItem>
            ))}
          </Wrap>
        )}
      </Box>
      <CertificateDrawer
        isOpen={isDrawerOpen}
        onClose={handleCloseDrawer}
        certificate={isAddMode ? null : selectedCertificate}
        isAddMode={isAddMode}
      />
    </Box>
  );
};

export default CertificateList;
