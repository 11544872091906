import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "./themeSlice";
import sidebarReducer from "./sidebarSlice";
import projectReducer from "./projectsSlice";
import loadingReducer from "./loadingSlice";
import billsReducer from "./billsSlice";
import subcontractorsReducer from "./subcontractorsSlice";
import vehiclesReducer from "./vehiclesSlice";
import toolsReducer from "./toolsSlice";
import dutiesPlanningReducer from "./dutiesPlanningSlice";
import authReducer from "./authSlice";
import employeesReducer from "./employeesSlice";
import materialsReducer from "./materialsSlice";
import billImagesReducer from "./billImagesSlice";
import transportReducer from "./transportSlice";
import weeklyMaterialRequirementReducer from "./weeklyMaterialRequirementSlice";
import professionsReducer from "./professionsSlice";
import documentReducer from "./documentSlice";
import stageTasksReducer from "./stageTasksSlice";
import logRequirementsReducer from "./logRequirementSlice";
import workDoneReducer from "./workDoneSlice";
import todosReducer from "./todosSlice";
import chatReducer from "./chatSlice";
import petrolCostsReducer from "./petrolCostsSlice";
import hiringReducer from "./hiringSlice";

const store = configureStore({
  reducer: {
    theme: themeReducer,
    sidebar: sidebarReducer,
    projectList: projectReducer,
    loading: loadingReducer,
    bills: billsReducer,
    subcontractors: subcontractorsReducer,
    vehicles: vehiclesReducer,
    tools: toolsReducer,
    dutiesPlanning: dutiesPlanningReducer,
    auth: authReducer,
    employees: employeesReducer,
    materials: materialsReducer,
    billImages: billImagesReducer,
    transport: transportReducer,
    weeklyMaterialRequirement: weeklyMaterialRequirementReducer,
    professions: professionsReducer,
    document: documentReducer,
    stageTasks: stageTasksReducer,
    logRequirements: logRequirementsReducer,
    workDone: workDoneReducer,
    todos: todosReducer,
    chat: chatReducer,
    petrolCosts: petrolCostsReducer,
    hiring: hiringReducer,
  },
});

export default store;
