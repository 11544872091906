import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Select,
  Textarea,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Heading,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addLogRequirement,
  updateLogRequirement,
  selectLogRequirementById,
} from "../../store/logRequirementSlice"; // Import the updated slice
import GenericHeader from "../../commons/GenericHeader";
import PrimaryButton from "../../commons/PrimaryButton";
import { IoAddCircleOutline } from "react-icons/io5";
import { DeleteIcon } from "@chakra-ui/icons"; // Import the Delete icon

const AddLogRequirementForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const isEditMode = !!id;
  const logRequirement = useSelector((state) =>
    selectLogRequirementById(state, id)
  );

  const [formData, setFormData] = useState({
    project: "",
    stage: "",
    activity: "",
    items: [], // To store multiple items
    description: "",
    qty: 0,
    shop: "",
    remarks: "",
    notes: "",
  });

  const [items, setItems] = useState([]); // Track the added items

  useEffect(() => {
    if (isEditMode && logRequirement) {
      setFormData(logRequirement);
      setItems(logRequirement.items || []);
    }
  }, [isEditMode, logRequirement]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNumberChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Add new item to the items list
  const addItem = () => {
    const newItem = {
      description: formData.description,
      qty: formData.qty,
      shop: formData.shop,
      remarks: formData.remarks,
    };
    setItems((prevItems) => [...prevItems, newItem]);
    setFormData({
      ...formData,
      description: "",
      qty: 0,
      shop: "",
      remarks: "",
    });
  };

  // Remove item from the items list
  const deleteItem = (index) => {
    setItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const logData = {
      project: formData.project,
      stage: formData.stage,
      activity: formData.activity,
      items,
      notes: formData.notes,
    };

    if (isEditMode) {
      dispatch(updateLogRequirement({ id, updatedData: logData }));
    } else {
      dispatch(addLogRequirement(logData));
    }
    navigate("/log-requirements");
  };

  return (
    <Box>
      <GenericHeader
        title={isEditMode ? "Update Log Requirement" : "Add Log Requirement"}
        icon={<IoAddCircleOutline />}
        back={"/material-required"}
      />
      <Box
        p={4}
        borderRadius="20"
        background={"white"}
        border={"3px solid gray"}
      >
        <form onSubmit={handleSubmit}>
          <Flex mb={4}>
            <FormControl isRequired mr={4}>
              <FormLabel>Project</FormLabel>
              <Input
                name="project"
                value={formData.project}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl isRequired mr={4}>
              <FormLabel>Stage</FormLabel>
              <Select
                name="stage"
                value={formData.stage}
                onChange={handleInputChange}
              >
                <option value="">Select Stage</option>
                <option value="Initial">Initial</option>
                <option value="Ongoing">Ongoing</option>
                <option value="Completed">Completed</option>
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Activity</FormLabel>
              <Input
                name="activity"
                value={formData.activity}
                onChange={handleInputChange}
              />
            </FormControl>
          </Flex>

          {/* Item Input Form */}
          <Heading size="md" mb={4}>
            Add Items
          </Heading>
          <Flex mb={4}>
            <FormControl isRequired mr={4}>
              <FormLabel>Description / Item</FormLabel>
              <Input
                name="description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl isRequired mr={4}>
              <FormLabel>Quantity</FormLabel>
              <NumberInput
                min={0}
                name="qty"
                value={formData.qty}
                onChange={(value) => handleNumberChange("qty", value)}
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>
            <FormControl isRequired mr={4}>
              <FormLabel>Shop Name</FormLabel>
              <Input
                name="shop"
                value={formData.shop}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Remarks</FormLabel>
              <Input
                name="remarks"
                value={formData.remarks}
                onChange={handleInputChange}
              />
            </FormControl>
          </Flex>

          <Button onClick={addItem} colorScheme="teal" mb={4}>
            Add Item
          </Button>

          {/* Display added items */}
          {items.length > 0 && (
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Description</Th>
                  <Th>Quantity</Th>
                  <Th>Shop</Th>
                  <Th>Remarks</Th>
                  <Th>Action</Th> {/* New column for delete button */}
                </Tr>
              </Thead>
              <Tbody>
                {items.map((item, index) => (
                  <Tr key={index}>
                    <Td>{item.description}</Td>
                    <Td>{item.qty}</Td>
                    <Td>{item.shop}</Td>
                    <Td>{item.remarks}</Td>
                    <Td>
                      <IconButton
                        icon={<DeleteIcon />}
                        onClick={() => deleteItem(index)} // Delete item on click
                        aria-label="Delete item"
                        colorScheme="red"
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}

          {/* Notes */}
          <FormControl mt={4}>
            <FormLabel>Notes</FormLabel>
            <Textarea
              name="notes"
              value={formData.notes}
              onChange={handleInputChange}
            />
          </FormControl>

          <Flex justifyContent="center" mt={4}>
            <PrimaryButton
              type="submit"
              title={
                isEditMode ? "Update Log Requirement" : "Add Log Requirement"
              }
            />
          </Flex>
        </form>
      </Box>
    </Box>
  );
};

export default AddLogRequirementForm;
