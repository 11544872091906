// src/store/hiringSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hires: [
    {
      sno: 1,
      candidateName: "Aisha",
      position: "Software Engineer",
      department: "IT",
      dateOfApplication: "2024-07-15",
      vaccinationStatus: "Fully Vaccinated",
      remarks: "Strong technical skills.",
      status: "In Progress",
    },
    {
      sno: 2,
      candidateName: "Bilal",
      position: "Project Manager",
      department: "Operations",
      dateOfApplication: "2024-07-10",
      vaccinationStatus: "Partially Vaccinated",
      remarks: "Excellent leadership qualities.",
      status: "Hired",
    },
    // Add more dummy data as needed
  ],
};

const hiringSlice = createSlice({
  name: "hiring",
  initialState,
  reducers: {
    addHire: (state, action) => {
      const newHire = { id: state.hires.length + 1, ...action.payload };
      state.hires.push(newHire);
    },
    updateHire: (state, action) => {
      const index = state.hires.findIndex(
        (hire) => hire.id === action.payload.id
      );
      if (index !== -1) {
        state.hires[index] = action.payload;
      }
    },
    deleteHire: (state, action) => {
      state.hires = state.hires.filter((hire) => hire.id !== action.payload);
    },
  },
});

export const { addHire, updateHire, deleteHire } = hiringSlice.actions;

export default hiringSlice.reducer;
