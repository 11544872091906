// src/features/chat/chatSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  users: [
    // Example users
    // { id: 'user1', name: 'Alice', avatar: 'url_to_avatar' },
    // { id: 'user2', name: 'Bob', avatar: 'url_to_avatar' },
  ],
  chats: [
    // Example chat
    // {
    //   id: 'chat1',
    //   name: 'Group Chat',
    //   type: 'group', // 'direct'
    //   participants: ['user1', 'user2'],
    //   messages: [
    //     {
    //       id: 'msg1',
    //       senderId: 'user1',
    //       content: 'Hello!',
    //       type: 'text',
    //       timestamp: Date.now(),
    //     },
    //   ],
    // },
  ],
  activeChatId: null,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setActiveChat: (state, action) => {
      state.activeChatId = action.payload;
    },
    addChat: (state, action) => {
      state.chats.push(action.payload);
    },
    addMessage: (state, action) => {
      const { chatId, message } = action.payload;
      const chat = state.chats.find((c) => c.id === chatId);
      if (chat) {
        chat.messages.push(message);
      }
    },
    pinMessage: (state, action) => {
      const { chatId, messageId } = action.payload;
      const chat = state.chats.find((c) => c.id === chatId);
      if (chat && !chat.pinnedMessages) {
        chat.pinnedMessages = [];
      }
      if (chat && !chat.pinnedMessages.includes(messageId)) {
        chat.pinnedMessages.push(messageId);
      }
    },
    unpinMessage: (state, action) => {
      const { chatId, messageId } = action.payload;
      const chat = state.chats.find((c) => c.id === chatId);
      if (chat && chat.pinnedMessages) {
        chat.pinnedMessages = chat.pinnedMessages.filter(
          (id) => id !== messageId
        );
      }
    },
    deleteMessage: (state, action) => {
      const { chatId, messageId } = action.payload;
      const chat = state.chats.find((c) => c.id === chatId);
      if (chat) {
        chat.messages = chat.messages.filter((msg) => msg.id !== messageId);
        if (chat.pinnedMessages) {
          chat.pinnedMessages = chat.pinnedMessages.filter(
            (id) => id !== messageId
          );
        }
      }
    },
  },
});

export const {
  setUsers,
  setActiveChat,
  addChat,
  addMessage,
  pinMessage,
  unpinMessage,
  deleteMessage,
} = chatSlice.actions;

export default chatSlice.reducer;
