// CertificateDetail.jsx
import React from "react";
import {
  Box,
  Text,
  Button,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Grid,
  GridItem,
  Badge,
  Icon,
  VStack,
} from "@chakra-ui/react";
import { FiArrowLeft, FiEdit } from "react-icons/fi";
import GenericHeader from "../../commons/GenericHeader";

const CertificateDetail = ({ certificate, onOpenDrawer, onBack }) => {
  const renewalHistory = [
    { date: "2023-01-01", cost: 500 },
    { date: "2022-01-01", cost: 400 },
    // More history
  ];

  return (
    <Box>
      {/* Header */}
      <Flex alignItems="center" mb={6}>
        <GenericHeader title={certificate.name} back={onBack} />
        <Button
          leftIcon={<FiEdit />}
          colorScheme="primary"
          onClick={onOpenDrawer}
        >
          Update Renewal
        </Button>
      </Flex>

      {/* Certificate Details */}
      <Grid
        templateColumns={{ base: "1fr", md: "1fr 1fr" }}
        gap={6}
        mb={8}
        bg="white"
        borderRadius="md"
        p={5}
      >
        <GridItem>
          <VStack align="start" spacing={3}>
            <Box>
              <Text fontWeight="bold">Issuance Date:</Text>
              <Text color="gray.600">{certificate.issuance}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Expiry Date:</Text>
              <Text color="gray.600">{certificate.expiry}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Cost:</Text>
              <Badge colorScheme="green" fontSize="1em">
                AED {certificate.cost}
              </Badge>
            </Box>
            <Box>
              <Text fontWeight="bold">Remarks:</Text>
              <Text color="gray.600">{certificate.remarks}</Text>
            </Box>
          </VStack>
        </GridItem>
        {/* Additional Information or Actions can be placed here */}
      </Grid>

      {/* Renewal History */}
      <Box bg="white" borderRadius="md" p={5}>
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          Renewal History
        </Text>
        <Table variant="simple" size="md">
          <Thead bg="gray.100">
            <Tr>
              <Th>Date</Th>
              <Th>Cost</Th>
            </Tr>
          </Thead>
          <Tbody>
            {renewalHistory.map((renewal, index) => (
              <Tr key={index}>
                <Td>{renewal.date}</Td>
                <Td>AED {renewal.cost}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default CertificateDetail;
