import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Input,
  Textarea,
  Select,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  SimpleGrid,
} from "@chakra-ui/react";
import GenericHeader from "../../commons/GenericHeader";
import DashboardBox from "../../commons/DashboardBox";
import { FaMoneyBill, FaWrench, FaUserTie } from "react-icons/fa";
import {
  addFine,
  changeDriver,
  addMaintenance,
  selectVehicleBySno,
} from "../../store/vehiclesSlice";

const VehicleDetails = () => {
  const { sno } = useParams();
  const vehicle = useSelector((state) =>
    selectVehicleBySno(state, parseInt(sno))
  );
  const dispatch = useDispatch();

  const [drawerType, setDrawerType] = useState(null);
  const [formData, setFormData] = useState({});

  // Calculate total fines and total maintenance cost
  const totalFines = useMemo(
    () => vehicle.fines.reduce((acc, fine) => acc + fine.amount, 0),
    [vehicle.fines]
  );

  const totalMaintenanceCost = useMemo(
    () =>
      vehicle.maintenanceHistory.reduce((acc, maint) => acc + maint.cost, 0),
    [vehicle.maintenanceHistory]
  );

  const handleOpenDrawer = (type) => {
    setDrawerType(type);
    setFormData({});
  };

  const handleCloseDrawer = () => {
    setDrawerType(null);
  };

  const handleSave = () => {
    switch (drawerType) {
      case "fine":
        dispatch(addFine({ sno: vehicle.sno, fine: formData }));
        break;
      case "driver":
        dispatch(
          changeDriver({ sno: vehicle.sno, newDriver: formData.newDriver })
        );
        break;
      case "maintenance":
        const newMaintenance = {
          type: formData.type || "Other",
          date: formData.date || new Date().toISOString().split("T")[0],
          cost: formData.cost || 0,
          description: formData.description || "",
        };
        dispatch(
          addMaintenance({ sno: vehicle.sno, maintenance: newMaintenance })
        );
        break;
      default:
        break;
    }
    handleCloseDrawer();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={6}
      >
        <GenericHeader
          title={`Vehicle Details: ${vehicle.plateNo}`}
          back={"/vehicles"}
        />
        <Flex>
          <Button
            onClick={() => handleOpenDrawer("fine")}
            colorScheme="teal"
            mr={2}
          >
            Add Fine
          </Button>
          <Button
            onClick={() => handleOpenDrawer("driver")}
            colorScheme="blue"
            mr={2}
          >
            Change Driver
          </Button>
          <Button
            onClick={() => handleOpenDrawer("maintenance")}
            colorScheme="purple"
          >
            Add Maintenance
          </Button>
        </Flex>
      </Box>

      {/* Dashboard Boxes */}
      <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={5} mb={6}>
        <DashboardBox
          icon={FaMoneyBill}
          name="Total Fines"
          value={`AED ${totalFines.toLocaleString()}`}
          backgroundColor="#FC8181"
        />
        <DashboardBox
          icon={FaWrench}
          name="Total Maintenance Cost"
          value={`AED ${totalMaintenanceCost.toLocaleString()}`}
          backgroundColor="#68D391"
        />
        <DashboardBox
          icon={FaUserTie}
          name="Current Driver"
          value={vehicle.driverName}
          backgroundColor="#4299E1"
        />
      </SimpleGrid>

      {/* Tabs for different sections */}
      <Tabs variant="enclosed">
        <TabList>
          <Tab
            borderRadius={20}
            _selected={{
              color: "white",
              bg: "primary.500",
            }}
          >
            Details
          </Tab>
          <Tab
            borderRadius={20}
            _selected={{
              color: "white",
              bg: "primary.500",
            }}
          >
            Fines
          </Tab>
          <Tab
            borderRadius={20}
            _selected={{
              color: "white",
              bg: "primary.500",
            }}
          >
            Driver History
          </Tab>
          <Tab
            borderRadius={20}
            _selected={{
              color: "white",
              bg: "primary.500",
            }}
          >
            Maintenance History
          </Tab>
        </TabList>

        <TabPanels>
          {/* Vehicle Details Tab */}
          <TabPanel>
            <Table variant="simple" background={"white"} borderRadius="md">
              <Tbody>
                <Tr>
                  <Td>SNo</Td>
                  <Td>{vehicle.sno}</Td>
                </Tr>
                <Tr>
                  <Td>Type</Td>
                  <Td>{vehicle.type}</Td>
                </Tr>
                <Tr>
                  <Td>Plate No</Td>
                  <Td>{vehicle.plateNo}</Td>
                </Tr>
                <Tr>
                  <Td>Mulkya Expiry</Td>
                  <Td>{vehicle.mulkyaExpiry}</Td>
                </Tr>
                <Tr>
                  <Td>Insurance Expiry</Td>
                  <Td>{vehicle.insuranceExpiry}</Td>
                </Tr>
                <Tr>
                  <Td>Date Handover</Td>
                  <Td>{vehicle.dateHandover}</Td>
                </Tr>
                <Tr>
                  <Td>Date Return</Td>
                  <Td>{vehicle.dateReturn}</Td>
                </Tr>
                <Tr>
                  <Td>Total Cost</Td>
                  <Td>{vehicle.totalCost}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TabPanel>

          {/* Fines Tab */}
          <TabPanel>
            <Table variant="simple" background={"white"} borderRadius="md">
              <Thead>
                <Tr>
                  <Th>Fine Amount</Th>
                  <Th>Date</Th>
                  <Th>Reason</Th>
                </Tr>
              </Thead>
              <Tbody>
                {vehicle.fines.map((fine, index) => (
                  <Tr key={index}>
                    <Td>{fine.amount}</Td>
                    <Td>{fine.date}</Td>
                    <Td>{fine.reason}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TabPanel>

          {/* Driver History Tab */}
          <TabPanel>
            <Table variant="simple" background={"white"} borderRadius="md">
              <Thead>
                <Tr>
                  <Th>Driver Name</Th>
                  <Th>Date Handover</Th>
                  <Th>Date Return</Th>
                </Tr>
              </Thead>
              <Tbody>
                {vehicle.driverHistory.map((history, index) => (
                  <Tr key={index}>
                    <Td>{history.driverName}</Td>
                    <Td>{history.dateHandover}</Td>
                    <Td>{history.dateReturn}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TabPanel>

          {/* Maintenance History Tab */}
          <TabPanel>
            <Table variant="simple" background={"white"} borderRadius="md">
              <Thead>
                <Tr>
                  <Th>Type</Th>
                  <Th>Date</Th>
                  <Th>Cost (AED)</Th>
                  <Th>Description</Th>
                </Tr>
              </Thead>
              <Tbody>
                {vehicle.maintenanceHistory.map((maint, index) => (
                  <Tr key={index}>
                    <Td>{maint.type}</Td>
                    <Td>{maint.date}</Td>
                    <Td>{maint.cost}</Td>
                    <Td>{maint.description}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/* Drawer for Adding Fines, Drivers, Maintenance */}
      <Drawer
        isOpen={Boolean(drawerType)}
        placement="right"
        onClose={handleCloseDrawer}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {drawerType === "fine" && "Add Fine"}
            {drawerType === "driver" && "Change Driver"}
            {drawerType === "maintenance" && "Add Maintenance"}
          </DrawerHeader>
          <DrawerBody>
            {drawerType === "fine" && (
              <>
                <Input
                  placeholder="Date"
                  name="date"
                  value={
                    formData.date || new Date().toISOString().split("T")[0]
                  }
                  onChange={handleChange}
                  mb={4}
                />
                <Input
                  placeholder="Amount"
                  name="amount"
                  value={formData.amount || ""}
                  onChange={handleChange}
                  mb={4}
                />
                <Textarea
                  placeholder="Reason"
                  name="reason"
                  value={formData.reason || ""}
                  onChange={handleChange}
                  mb={4}
                />
              </>
            )}
            {drawerType === "driver" && (
              <>
                <Input
                  placeholder="Current Driver"
                  name="currentDriver"
                  value={vehicle.driverName}
                  isReadOnly
                  mb={4}
                />
                <Select
                  placeholder="New Driver"
                  name="newDriver"
                  value={formData.newDriver || ""}
                  onChange={handleChange}
                  mb={4}
                >
                  <option value="Driver 1">Driver 1</option>
                  <option value="Driver 2">Driver 2</option>
                </Select>
                <Input
                  placeholder="Date Handover"
                  name="dateHandover"
                  value={
                    formData.dateHandover ||
                    new Date().toISOString().split("T")[0]
                  }
                  onChange={handleChange}
                  mb={4}
                />
              </>
            )}
            {drawerType === "maintenance" && (
              <>
                <Select
                  placeholder="Select Maintenance Type"
                  name="type"
                  value={formData.type || ""}
                  onChange={handleChange}
                  mb={4}
                >
                  <option value="Oil Change">Oil Change</option>
                  <option value="Service">Service</option>
                  <option value="Brake Pad">Brake Pad</option>
                  <option value="Other">Other</option>
                </Select>
                <Input
                  type="date"
                  name="date"
                  value={
                    formData.date || new Date().toISOString().split("T")[0]
                  }
                  onChange={handleChange}
                  mb={4}
                />
                <Input
                  placeholder="Cost"
                  name="cost"
                  value={formData.cost || ""}
                  onChange={handleChange}
                  mb={4}
                />
                <Textarea
                  placeholder="Description"
                  name="description"
                  value={formData.description || ""}
                  onChange={handleChange}
                  mb={4}
                />
              </>
            )}
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={handleCloseDrawer}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleSave}>
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default VehicleDetails;
