// CertificateDrawer.jsx
import React, { useState, useEffect } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  VStack,
  useToast,
} from "@chakra-ui/react";

const CertificateDrawer = ({ isOpen, onClose, certificate, isAddMode }) => {
  const [formData, setFormData] = useState({
    issuance: "",
    expiry: "",
    cost: "",
    remarks: "",
  });

  const toast = useToast();

  // Reset form data when drawer opens or mode changes
  useEffect(() => {
    if (isAddMode) {
      setFormData({
        issuance: "",
        expiry: "",
        cost: "",
        remarks: "",
      });
    } else if (certificate) {
      setFormData({
        issuance: certificate.issuance,
        expiry: certificate.expiry,
        cost: certificate.cost,
        remarks: certificate.remarks,
      });
    }
  }, [isOpen, isAddMode, certificate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {
    // Basic form validation
    const { issuance, expiry, cost } = formData;
    if (!issuance || !expiry || !cost) {
      toast({
        title: "Missing Information",
        description: "Please fill out all required fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (isAddMode) {
      // Handle adding new certificate
      // Example: API call to add certificate
      console.log("Adding Certificate:", formData);
      toast({
        title: "Certificate Added.",
        description: "Your certificate has been added successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      // Handle updating existing certificate
      // Example: API call to update certificate
      console.log("Updating Certificate:", formData);
      toast({
        title: "Certificate Updated.",
        description: "Your certificate has been updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
    onClose();
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          {isAddMode ? "Add Certificate" : "Update Certificate"}
        </DrawerHeader>
        <DrawerBody>
          <VStack spacing={4} align="stretch">
            <FormControl id="issuance" isRequired>
              <FormLabel>Issuance Date</FormLabel>
              <Input
                type="date"
                name="issuance"
                value={formData.issuance}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl id="expiry" isRequired>
              <FormLabel>Expiry Date</FormLabel>
              <Input
                type="date"
                name="expiry"
                value={formData.expiry}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl id="cost" isRequired>
              <FormLabel>Cost</FormLabel>
              <Input
                type="number"
                name="cost"
                value={formData.cost}
                onChange={handleInputChange}
                min="0"
              />
            </FormControl>
            <FormControl id="remarks">
              <FormLabel>Remarks</FormLabel>
              <Input
                name="remarks"
                value={formData.remarks}
                onChange={handleInputChange}
              />
            </FormControl>
            <Button colorScheme="blue" onClick={handleSave}>
              Save
            </Button>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default CertificateDrawer;
