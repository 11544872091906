import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Image,
  IconButton,
  useToast,
  Heading,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addWorkDone,
  updateWorkDone,
  selectWorkDoneById,
} from "../../store/workDoneSlice";
import { DeleteIcon } from "@chakra-ui/icons";
import PrimaryButton from "../../commons/PrimaryButton";
import GenericHeader from "../../commons/GenericHeader";

const WorkDoneForm = () => {
  const { id } = useParams();
  const isEditMode = !!id;
  const workDone = useSelector((state) => selectWorkDoneById(state, id));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const [formData, setFormData] = useState({
    date: new Date().toISOString().split("T")[0], // Set current date by default
    empName: "",
    workDescription: "",
    images: [],
  });

  const [images, setImages] = useState([]);

  useEffect(() => {
    if (isEditMode && workDone) {
      setFormData(workDone);
      setImages(workDone.images || []);
    }
  }, [isEditMode, workDone]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const fileURLs = files.map((file) => URL.createObjectURL(file));
    setImages([...images, ...fileURLs]);
  };

  const handleImageDelete = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const dataToSubmit = {
      ...formData,
      images,
    };

    if (isEditMode) {
      dispatch(updateWorkDone({ id, updatedData: dataToSubmit }));
      toast({ title: "Work updated", status: "success", duration: 3000 });
    } else {
      dispatch(addWorkDone(dataToSubmit));
      toast({ title: "Work added", status: "success", duration: 3000 });
    }

    navigate("/daily-work-reporting");
  };

  return (
    <Box>
      <GenericHeader
        title={isEditMode ? "Update Work Done" : "Add Work Done"}
        back={"/daily-work-reporting"}
      />
      <Box
        p={4}
        borderRadius="20"
        background={"white"}
        border={"3px solid gray"}
      >
        <Heading size="md" mb={4}>
          Details
        </Heading>
        <form onSubmit={handleSubmit}>
          <Flex mb={4}>
            <FormControl isRequired mr={4}>
              <FormLabel>Date</FormLabel>
              <Input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Employee Name</FormLabel>
              <Input
                name="empName"
                value={formData.empName}
                onChange={handleInputChange}
              />
            </FormControl>
          </Flex>

          <Flex gap={5}>
            <FormControl isRequired mb={4}>
              <FormLabel>Work Done Description</FormLabel>
              <Textarea
                name="workDescription"
                value={formData.workDescription}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Upload Images</FormLabel>
              <Input
                type="file"
                multiple
                accept="image/*"
                onChange={handleImageUpload}
              />
              <Flex mt={4}>
                {images.length > 0 &&
                  images.map((image, index) => (
                    <Box key={index} position="relative" mr={4}>
                      <Image
                        src={image}
                        alt={`Uploaded ${index}`}
                        boxSize="100px"
                        objectFit="cover"
                      />
                      <IconButton
                        icon={<DeleteIcon />}
                        onClick={() => handleImageDelete(index)}
                        size="sm"
                        position="absolute"
                        top="0"
                        right="0"
                        colorScheme="red"
                      />
                    </Box>
                  ))}
              </Flex>
            </FormControl>
          </Flex>

          <Flex justifyContent={"center"} mb={4}>
            <PrimaryButton
              type="submit"
              colorScheme="primary"
              title={isEditMode ? "Update Work Done" : "Add Work Done"}
            />
          </Flex>
        </form>
      </Box>
    </Box>
  );
};

export default WorkDoneForm;
