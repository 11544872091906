import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

// Sample initial state for the log requirements (material requirements)
const initialState = {
  logs: [
    {
      id: uuidv4(),
      sno: 1,
      description: "Cement",
      qty: 20,
      remarks: "For foundation",
      stage: "Initial",
      activity: "Foundation Laying",
      amount: 5000,
      shop: "ABC Supplies",
      notes: "Urgent delivery needed",
    },
    {
      id: uuidv4(),
      sno: 2,
      description: "Bricks",
      qty: 1000,
      remarks: "For wall construction",
      stage: "Ongoing",
      activity: "Wall Construction",
      amount: 20000,
      shop: "XYZ Supplies",
      notes: "Delivery expected in 2 days",
    },
  ],
};
const logRequirementSlice = createSlice({
  name: "logRequirements",
  initialState,
  reducers: {
    addLogRequirement: (state, action) => {
      // Adding multiple items to the log requirement
      const { project, stage, activity, items, notes } = action.payload;
      state.logs.push({
        id: uuidv4(), // Auto-generated ID
        sno: state.logs.length + 1, // Auto-generated serial number
        project,
        stage,
        activity,
        items, // Multiple items array
        notes,
      });
    },
    updateLogRequirement: (state, action) => {
      const { id, updatedData } = action.payload;
      const existingLog = state.logs.find((log) => log.id === id);
      if (existingLog) {
        Object.assign(existingLog, updatedData);
      }
    },
    deleteLogRequirement: (state, action) => {
      const { id } = action.payload;
      state.logs = state.logs.filter((log) => log.id !== id);
    },
  },
});

export const { addLogRequirement, updateLogRequirement, deleteLogRequirement } =
  logRequirementSlice.actions;

export const selectLogRequirements = (state) => state.logRequirements.logs;
export const selectLogRequirementById = (state, id) =>
  state.logRequirements.logs.find((log) => log.id === id);

export default logRequirementSlice.reducer;
