import React, { useEffect, useState } from "react";
import "../styles/MainContent.css";
import { Route, Routes } from "react-router-dom";
import SkeletonLoading from "../commons/SkeletonLoading";
import ProjectList from "./Project/ProjectList";
import AddSiteForm from "./Project/AddProject";
import ProjectDetails from "./Project/ProjectDetails";
import BillsList from "./Procurement/BillsList";
import AddBill from "./Procurement/AddBill";
import SubcontractorsList from "./Subcontractor/SubcontractorsList";
import AddSubcontractor from "./Subcontractor/SubcontractorForm";
import VehicleDetails from "./Vehicle/VehicleDetails";
import VehicleList from "./Vehicle/VehicleList";
import AddVehicle from "./Vehicle/AddVehicle";
import ToolsList from "./OwnAndRentalTool/ToolsList";
import AddOwnTool from "./OwnAndRentalTool/AddOwnTool";
import AddRentalTool from "./OwnAndRentalTool/AddRentalTool";
import ForemanReportingList from "./DailyReporting/ForemanReportingList";
import AddNextDayPlan from "./DailyReporting/AddNextDayPlan";
import Dashboard from "./Dashboard/Dashboard";
import LoginPage from "./Auth/Login";
import ProfilePage from "../commons/ProfilePage";
import SubcontractorDetails from "./Subcontractor/SubcontractorDetails";
import EmployeeList from "./Employees/EmployeeList";
import AddEmployee from "./Employees/AddEmployee";
import EmployeeDetails from "./Employees/EmployeeDetails";
import EmployeeDailyDutiesList from "./DailyReporting/EmployeeDailyDutiesList";
import MaterialDetails from "./Materials/MaterialDetails";
import AddMaterial from "./Materials/AddMaterial";
import MaterialsList from "./Materials/MaterialsList";
import TransportList from "./Transport/TransportList";
import SitesWeeklyTargetList from "./DailyReporting/WorkRequiredList";
import AddSitesWeeklyTargets from "./DailyReporting/AddWorkRequired";
import ProfessionsList from "./Professions/ProfesionsList";
import DailyManpowerList from "./DailyManpower/DailyManpowerList";
import AttendanceRecord from "./Employees/AttendenceRecord";
import AccountsList from "./Finance/Accounts/AccountsList";
import LedgerList from "./Finance/Ledger/LedgerList";
import TrialBalance from "./Finance/Accounts/FinancialStatements";
import BalanceSheet from "./Finance/Accounts/ProjectFinancialManagement";
import FinancialAnalytics from "./Finance/Analytics/FinancialAnalytics ";
import HRModules from "./Employees/HRModules";
import UnderConstruction from "../commons/UnderConstruction";
import TransitionWrapper from "../commons/TransitionWrapper";
import AddManpowerForm from "./DailyManpower/AddManpowerForm";
import AddTransportForm from "./Transport/AddTransportForm";
import PayrollList from "./Finance/Analytics/Payroll/PayrollList";
import ProjectFinancialManagement from "./Finance/Accounts/ProjectFinancialManagement";
import FinancialStatements from "./Finance/Accounts/FinancialStatements";
import FinanceDashboard from "./Finance/Accounts/FinanceDashboard";
import TestingUI from "./Finance/Accounts/TestingUI";
import BillsTabContainer from "./Procurement/BillsContainer";
import DocumentList from "./DocumentManagement/DocumentList";
import AddDocumentForm from "./DocumentManagement/AddDocumentForm";
import DocumentDetails from "./DocumentManagement/DocumentDetails";
import CostManagement from "./Project/CostManagement";
import SubcontractorForm from "./Subcontractor/SubcontractorForm";
import EmployeePayrollDetails from "./Finance/Analytics/Payroll/EmployeePayrollDetails";
import UserAccessManagement from "./UserAccessManagement/UserAccessManagement";
import Signup from "./Auth/Signup";
import QuotationManagementList from "../QuotationManagement/QuotationManagementList";
import AddQuotationForm from "../QuotationManagement/AddQuotationForm";
import Todo from "./Todo/Todo";
import LogRequirment from "./DailyReporting/LogRequirment";
import AddLogRequirementForm from "./DailyReporting/AddLogRequirementForm";
import WorkDoneList from "./DailyReporting/WorkDoneList";
import WorkDoneForm from "./DailyReporting/WorkDoneForm";
import LaborAccommodationList from "./Employees/Accommodation/LaborAccommodationList";
import LaborAccommodationForm from "./Employees/Accommodation/LaborAccommodationForm";
import LaborAccommodationDetail from "./Employees/Accommodation/LaborAccommodationDetail";
import MarketingCampaignList from "./Marketing/MarketingCampaignList";
import MessagingApp from "./Chat/MessagingApp";
import PettyCashRecord from "./PettyCash/PettyCashRecord";
import CertificateList from "./YearlyCertificates/CertificateList";
import PetrolCost from "./Procurement/PetrolCost";
import HRHiringList from "./Employees/HRHiring/HRHiringList";
import HRDashboard from "./Employees/Dashboard/HRDashboard";

const MainContent = ({ collapsed }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <main className={`main-content  ${collapsed ? "collapsed" : ""}`}>
      {loading ? (
        <SkeletonLoading /> // Show skeleton loading while data is loading
      ) : (
        <TransitionWrapper>
          <div className="">
            <Routes>
              <Route path="/" exact element={<Dashboard />} />
              <Route path="/projects" exact element={<ProjectList />} />
              <Route
                path="/projects/:projectName"
                exact
                element={<ProjectDetails />}
              />
              <Route path="/add-site" exact element={<AddSiteForm />} />
              <Route path="/documents" exact element={<DocumentList />} />
              <Route
                path="/documents/add"
                exact
                element={<AddDocumentForm />}
              />
              <Route
                path="/documents/details/:sno"
                exact
                element={<DocumentDetails />}
              />
              <Route path="/bills" exact element={<BillsTabContainer />} />
              <Route path="/bills/add" exact element={<AddBill />} />
              <Route path="/bills/edit/:sno" exact element={<AddBill />} />
              <Route
                path="/subcontractors"
                exact
                element={<SubcontractorsList />}
              />
              <Route
                path="/add-subcontractor"
                exact
                element={<SubcontractorForm />}
              />
              <Route
                path="/user-access"
                exact
                element={<UserAccessManagement />}
              />
              <Route
                path="/subcontractors/details/:id"
                element={<SubcontractorForm />}
              />

              <Route path="/vehicles" element={<VehicleList />} />
              <Route path="/add-vehicle" element={<AddVehicle />} />
              <Route
                path="/vehicle-details/:sno"
                element={<VehicleDetails />}
              />
              <Route path="/tools" element={<ToolsList />} />
              <Route path="/tools/own/add" element={<AddOwnTool />} />
              <Route path="/tools/own/edit" element={<AddOwnTool />} />
              <Route path="/tools/rental/add" element={<AddRentalTool />} />
              <Route
                path="/tools/rental/edit/:id"
                element={<AddRentalTool />}
              />
              <Route
                path="/foreman-reporting"
                element={<ForemanReportingList />}
              />
              <Route
                path="/foreman-reporting/add"
                element={<AddNextDayPlan />}
              />
              <Route
                path="/foreman-reporting/edit/:id"
                element={<AddNextDayPlan />}
              />
              <Route path="/transport" element={<TransportList />} />
              <Route path="/transport/add" element={<AddTransportForm />} />
              <Route
                path="/transport/edit/:id"
                element={<AddTransportForm />}
              />
              <Route path="/profile" element={<ProfilePage />} />
              <Route
                path="/work-required"
                element={<SitesWeeklyTargetList />}
              />
              <Route path="/daily-manpower" element={<DailyManpowerList />} />
              <Route path="/daily-manpower/add" element={<AddManpowerForm />} />
              <Route
                path="/daily-manpower/edit/:id"
                element={<AddManpowerForm />}
              />
              <Route path="/hr/attendence" element={<AttendanceRecord />} />
              <Route path="/accounts" element={<AccountsList />} />
              <Route path="/costing" element={<CostManagement />} />
              <Route path="/ledger" element={<LedgerList />} />
              <Route
                path="/financial-statements"
                element={<FinancialStatements />}
              />
              <Route
                path="/project-financials"
                element={<ProjectFinancialManagement />}
              />
              <Route path="/analytics" element={<FinancialAnalytics />} />
              <Route
                path="/work-required/add-new"
                element={<AddSitesWeeklyTargets />}
              />
              <Route
                path="/weekly-targets/:id"
                element={<AddSitesWeeklyTargets />}
              />
              <Route path="/hr/wheel" element={<HRModules />} />
              <Route path="/hr/employees" element={<EmployeeList />} />
              <Route path="/hr/employees/add" element={<AddEmployee />} />
              <Route
                path="/employees/details/:id"
                element={<EmployeeDetails />}
              />
              <Route
                path="/employee-duties"
                element={<EmployeeDailyDutiesList />}
              />
              <Route path="/material-required" element={<LogRequirment />} />
              <Route
                path="/material-required/add"
                element={<AddLogRequirementForm />}
              />
              <Route
                path="/material-required/edit"
                element={<AddLogRequirementForm />}
              />
              <Route path="/daily-work-reporting" element={<WorkDoneList />} />
              <Route
                path="/daily-work-reporting/add"
                element={<WorkDoneForm />}
              />
              <Route
                path="/daily-work-reporting/edit"
                element={<WorkDoneForm />}
              />

              <Route path="/todo" element={<Todo />} />
              <Route
                path="/hr/accommodations"
                element={<LaborAccommodationList />}
              />
              <Route
                path="/hr/accommodations/add"
                element={<LaborAccommodationForm />}
              />
              <Route
                path="/hr/accommodations/edit"
                element={<LaborAccommodationForm />}
              />
              <Route path="/marketing" element={<MarketingCampaignList />} />
              <Route path="/chat" element={<MessagingApp />} />
              <Route
                path="/hr/accommodations/details/:id"
                element={<LaborAccommodationDetail />}
              />
              <Route path="/hr/dashboard" element={<HRDashboard />} />
              <Route path="/hr/hiring" element={<HRHiringList />} />
              <Route path="/petty-cash" element={<PettyCashRecord />} />
              <Route path="/petrol" element={<PetrolCost />} />
              <Route path="/hr/certificates" element={<CertificateList />} />
              <Route path="/quotations" element={<QuotationManagementList />} />
              <Route path="/quotations/add" element={<AddQuotationForm />} />
              <Route path="/materials" element={<MaterialsList />} />
              <Route path="/add-material" element={<AddMaterial />} />
              <Route
                path="/materials/details/:id"
                element={<MaterialDetails />}
              />
              <Route path="/professions" element={<ProfessionsList />} />

              <Route path="/hr/recruitment" element={<UnderConstruction />} />
              <Route path="/hr/onboarding" element={<UnderConstruction />} />
              <Route path="/hr/compliance" element={<UnderConstruction />} />
              <Route path="/hr/payroll" element={<PayrollList />} />
              <Route path="/hr/performance" element={<UnderConstruction />} />
              <Route path="/hr/employees" element={<UnderConstruction />} />
              <Route path="/hr/health-safety" element={<UnderConstruction />} />
              <Route path="/hr/relations" element={<UnderConstruction />} />
              <Route
                path="/hr/administration"
                element={<UnderConstruction />}
              />
              <Route path="/hr/planning" element={<UnderConstruction />} />
              <Route path="/auth/signup" element={<Signup />} />
              <Route
                path="/hr/responsibilities"
                element={<UnderConstruction />}
              />
              <Route path="/hr/well-being" element={<UnderConstruction />} />

              <Route path="/payroll" element={<PayrollList />} />
              <Route
                path="/payroll/details/:id"
                element={<EmployeePayrollDetails />}
              />
              <Route path="/finance-dashboard" element={<FinanceDashboard />} />
              <Route path="/testing" element={<TestingUI />} />
            </Routes>
          </div>
        </TransitionWrapper>
      )}
    </main>
  );
};

export default MainContent;
