import React from "react";
import { Box, SimpleGrid, Button } from "@chakra-ui/react";
import DashboardBox from "../../../commons/DashboardBox";

const LedgerDetailView = ({ account, onClose }) => {
  // Dummy data for ledger entries. You'd replace this with real data from a backend or state.
  const ledgerEntries = [
    {
      date: "2024-01-01",
      description: "Payment Received",
      credit: 50000,
      debit: 0,
    },
    {
      date: "2024-01-05",
      description: "Material Purchase",
      credit: 0,
      debit: 20000,
    },
    {
      date: "2024-01-10",
      description: "Service Payment",
      credit: 0,
      debit: 15000,
    },
  ];

  const totalCredit = ledgerEntries.reduce(
    (sum, entry) => sum + entry.credit,
    0
  );
  const totalDebit = ledgerEntries.reduce((sum, entry) => sum + entry.debit, 0);
  const balance = totalCredit - totalDebit;

  return (
    <Box>
      <Button onClick={onClose}>Back to Accounts</Button>
      <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={5} mt={5}>
        <DashboardBox
          icon="FaMoneyCheck"
          name="Total Credit"
          value={`AED ${totalCredit.toLocaleString()}`}
          backgroundColor="#68D391"
        />
        <DashboardBox
          icon="FaMoneyBill"
          name="Total Debit"
          value={`AED ${totalDebit.toLocaleString()}`}
          backgroundColor="#FC8181"
        />
        <DashboardBox
          icon="FaBook"
          name="Balance"
          value={`AED ${balance.toLocaleString()}`}
          backgroundColor={balance >= 0 ? "#4299E1" : "#FC8181"}
        />
      </SimpleGrid>

      <Box mt={8}>
        <h3>Ledger Entries for {account.name}</h3>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "10px",
          }}
        >
          <thead>
            <tr>
              <th style={{ padding: "8px", border: "1px solid #ccc" }}>Date</th>
              <th style={{ padding: "8px", border: "1px solid #ccc" }}>
                Description
              </th>
              <th style={{ padding: "8px", border: "1px solid #ccc" }}>
                Credit (AED)
              </th>
              <th style={{ padding: "8px", border: "1px solid #ccc" }}>
                Debit (AED)
              </th>
            </tr>
          </thead>
          <tbody>
            {ledgerEntries.map((entry, index) => (
              <tr key={index}>
                <td style={{ padding: "8px", border: "1px solid #ccc" }}>
                  {entry.date}
                </td>
                <td style={{ padding: "8px", border: "1px solid #ccc" }}>
                  {entry.description}
                </td>
                <td style={{ padding: "8px", border: "1px solid #ccc" }}>
                  {entry.credit.toLocaleString()}
                </td>
                <td style={{ padding: "8px", border: "1px solid #ccc" }}>
                  {entry.debit.toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

export default LedgerDetailView;
