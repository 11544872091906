import React, { useState } from "react";
import {
  Box,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Heading,
  VStack,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Container,
  Checkbox,
  Button,
  Link as ChakraLink,
} from "@chakra-ui/react";
import {
  FaProjectDiagram,
  FaFileAlt,
  FaUsers,
  FaDollarSign,
  FaTruck,
  FaTools,
  FaBuilding,
} from "react-icons/fa";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import DashboardBox from "../../commons/DashboardBox";
import GenericHeader from "../../commons/GenericHeader";
import { RiDashboard3Line } from "react-icons/ri";
import { Link } from "react-router-dom"; // For navigation

const Dashboard = () => {
  const lightColors = [
    "#e0e0e0",
    "#d6eaf8",
    "#cce7ff",
    "#d4ffcc",
    "#ffffcc",
    "#ffd6e8",
    "#e5e5c0",
    "#e0fff0",
    "#f0e68c",
    "#d1c4e9",
    "#b2dfdb",
    "#ffe0b2",
  ];

  // Existing To-Do List State
  const [todos, setTodos] = useState([
    {
      id: 1,
      text: "Complete project proposal",
      date: "2024-06-30",
      completed: false,
    },
    {
      id: 2,
      text: "Prepare for client meeting",
      date: "2024-07-02",
      completed: true,
    },
    {
      id: 3,
      text: "Review financial reports",
      date: "2024-07-01",
      completed: false,
    },
    {
      id: 4,
      text: "Update project milestones",
      date: "2024-06-29",
      completed: false,
    },
  ]);

  const toggleComplete = (id) => {
    setTodos((prevTodos) =>
      prevTodos.map((todo) =>
        todo.id === id ? { ...todo, completed: !todo.completed } : todo
      )
    );
  };

  // Existing Stats
  const stats = [
    { icon: FaProjectDiagram, label: "Total Active Projects", value: "34" },
    { icon: FaProjectDiagram, label: "Total Completed Projects", value: "120" },
    { icon: FaFileAlt, label: "Quotations", value: "56" },
    { icon: FaUsers, label: "Total Employees", value: "250" },
    {
      icon: FaDollarSign,
      label: "Gross Project Profits",
      value: "AED 1,200,000",
    },
    { icon: FaDollarSign, label: "Expense", value: "AED 800,000" },
    { icon: FaTruck, label: "Total Vehicles", value: "50" },
    { icon: FaTools, label: "Total Materials", value: "150" },
    { icon: FaBuilding, label: "Subcontractor Projects", value: "20" },
  ];

  // Existing Charts Data
  const projectForecastingData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Projects",
        data: [3, 2, 2, 5, 6, 5, 7, 6, 8, 7, 10, 9],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const billPurchasesData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Bill Purchases",
        data: [
          2000, 2500, 1500, 3000, 3500, 4000, 4500, 5000, 5500, 6000, 6500,
          7000,
        ],
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const subcontractorProjectsData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Subcontractor Projects",
        data: [1, 3, 2, 4, 3, 5, 4, 6, 5, 7, 6, 8],
        backgroundColor: "rgba(153, 102, 255, 0.6)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 1,
      },
    ],
  };

  // New Charts Data

  // 1. Attendance Chart (Absent, Present, Leave)
  const attendanceData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "Present",
        data: [150, 160, 170, 180, 175, 190],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
      {
        label: "Absent",
        data: [5, 3, 4, 2, 6, 3],
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
      {
        label: "Leave",
        data: [10, 12, 8, 7, 9, 11],
        backgroundColor: "rgba(255, 206, 86, 0.6)",
      },
    ],
  };

  // 2. Employee Salary State
  const employeeSalaryData = {
    labels: ["Alice", "Bilal", "Carmen", "Dawud", "Elias"],
    datasets: [
      {
        label: "Salary (AED)",
        data: [5000, 6000, 5500, 6200, 5800],
        backgroundColor: "rgba(54, 162, 235, 0.6)",
      },
    ],
  };

  // 3. Monthly Purchase Overall Sites
  const monthlyPurchaseOverallData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "Site A",
        data: [2000, 2500, 2200, 3000, 2800, 3500],
        backgroundColor: "rgba(255, 159, 64, 0.6)",
      },
      {
        label: "Site B",
        data: [1500, 1800, 1600, 2000, 1900, 2300],
        backgroundColor: "rgba(153, 102, 255, 0.6)",
      },
      {
        label: "Site C",
        data: [1000, 1200, 1100, 1500, 1400, 1800],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  // 4. Site Stage-wise Profit Loss (Two Weeks Interval)
  const profitLossData = {
    labels: ["Week 1", "Week 2"],
    datasets: [
      {
        label: "Site A - Profit",
        data: [5000, 7000],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
      {
        label: "Site B - Loss",
        data: [-2000, -1500],
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
      {
        label: "Site C - Profit",
        data: [3000, 4000],
        backgroundColor: "rgba(54, 162, 235, 0.6)",
      },
    ],
  };

  // 5. Revenue/Income Growth State Percentage
  const revenueGrowthData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "Revenue (AED)",
        data: [100000, 120000, 110000, 130000, 125000, 140000],
        backgroundColor: "rgba(255, 206, 86, 0.6)",
      },
      {
        label: "Growth (%)",
        data: [10, 15, 12, 18, 16, 20],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        type: "line",
        yAxisID: "y1",
      },
    ],
  };

  // Active Duties Data
  const duties = [
    { sno: 1, forman: "Akram", site: "Site A", date: "2024-06-29" },
    { sno: 2, forman: "Asghar", site: "Site B", date: "2024-06-28" },
    { sno: 3, forman: "Bilal", site: "Site C", date: "2024-06-27" },
    // Add more active duties as needed
  ];

  return (
    <Box p={4} bg="gray.100" minH="100vh">
      <GenericHeader title="Dashboard" icon={<RiDashboard3Line />} />

      {/* Statistics Section */}
      <SimpleGrid columns={[1, 2, 3]} spacing={4} mt={4}>
        {stats.map((stat, index) => (
          <DashboardBox
            key={index}
            icon={stat.icon}
            name={stat.label}
            value={stat.value}
            backgroundColor={lightColors[index % lightColors.length]}
          />
        ))}
      </SimpleGrid>

      {/* Charts Section */}
      <SimpleGrid columns={[1, 2]} spacing={4} mt={4}>
        {/* Project Forecasting */}
        <Box p={4} bg="white" borderWidth="1px" borderRadius="md">
          <Flex justify="space-between" align="center" mb={4}>
            <Heading size="md">Project Forecasting</Heading>
            <Button
              size="sm"
              colorScheme="teal"
              as={Link}
              to="/details/project-forecasting"
            >
              View Details
            </Button>
          </Flex>
          <Bar data={projectForecastingData} />
        </Box>

        {/* Active Shift Duties */}
        <Box p={4} bg="white" borderWidth="1px" borderRadius="md">
          <Flex justify="space-between" align="center" mb={4}>
            <Heading size="md">Active Shift Duties</Heading>
            <Button
              size="sm"
              colorScheme="teal"
              as={Link}
              to="/details/shift-duties"
            >
              View Details
            </Button>
          </Flex>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>S.No</Th>
                <Th>Forman Name</Th>
                <Th>Site</Th>
                <Th>Date</Th>
              </Tr>
            </Thead>
            <Tbody>
              {duties.map((duty) => (
                <Tr key={duty.sno}>
                  <Td>{duty.sno}</Td>
                  <Td>{duty.forman}</Td>
                  <Td>{duty.site}</Td>
                  <Td>{duty.date}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </SimpleGrid>

      {/* Additional Charts */}
      <SimpleGrid columns={[1, 2, 3]} spacing={4} mt={4}>
        {/* Attendance Chart */}
        <Box p={4} bg="white" borderWidth="1px" borderRadius="md">
          <Flex justify="space-between" align="center" mb={4}>
            <Heading size="md">Attendance Overview</Heading>
            <Button
              size="sm"
              colorScheme="teal"
              as={Link}
              to="/details/attendance"
            >
              View Details
            </Button>
          </Flex>
          <Bar data={attendanceData} />
        </Box>

        {/* Employee Salary State */}
        <Box p={4} bg="white" borderWidth="1px" borderRadius="md">
          <Flex justify="space-between" align="center" mb={4}>
            <Heading size="md">Employee Salaries</Heading>
            <Button
              size="sm"
              colorScheme="teal"
              as={Link}
              to="/details/employee-salaries"
            >
              View Details
            </Button>
          </Flex>
          <Bar data={employeeSalaryData} />
        </Box>

        {/* Monthly Purchase Overall Sites */}
        <Box p={4} bg="white" borderWidth="1px" borderRadius="md">
          <Flex justify="space-between" align="center" mb={4}>
            <Heading size="md">Monthly Purchases - Sites Overview</Heading>
            <Button
              size="sm"
              colorScheme="teal"
              as={Link}
              to="/details/monthly-purchases"
            >
              View Details
            </Button>
          </Flex>
          <Bar data={monthlyPurchaseOverallData} />
        </Box>
      </SimpleGrid>

      <SimpleGrid columns={[1, 2]} spacing={4} mt={4}>
        {/* Profit/Loss Chart */}
        <Box p={4} bg="white" borderWidth="1px" borderRadius="md">
          <Flex justify="space-between" align="center" mb={4}>
            <Heading size="md">Profit/Loss - Site Stages</Heading>
            <Button
              size="sm"
              colorScheme="teal"
              as={Link}
              to="/details/profit-loss"
            >
              View Details
            </Button>
          </Flex>
          <Bar data={profitLossData} />
        </Box>

        {/* Revenue Growth Chart */}
        <Box p={4} bg="white" borderWidth="1px" borderRadius="md">
          <Flex justify="space-between" align="center" mb={4}>
            <Heading size="md">Revenue Growth</Heading>
            <Button
              size="sm"
              colorScheme="teal"
              as={Link}
              to="/details/revenue-growth"
            >
              View Details
            </Button>
          </Flex>
          <Bar
            data={revenueGrowthData}
            options={{
              responsive: true,
              scales: {
                y1: {
                  type: "linear",
                  position: "right",
                  ticks: {
                    callback: (value) => `${value}%`,
                  },
                  grid: {
                    drawOnChartArea: false,
                  },
                },
              },
            }}
          />
        </Box>
      </SimpleGrid>

      {/* Monthly Bill Purchases */}
      <Box p={4} bg="white" borderWidth="1px" borderRadius="md" mt={4}>
        <Flex justify="space-between" align="center" mb={4}>
          <Heading size="md">Monthly Bill Purchases</Heading>
          <Button
            size="sm"
            colorScheme="teal"
            as={Link}
            to="/details/bill-purchases"
          >
            View Details
          </Button>
        </Flex>
        <Bar data={billPurchasesData} />
      </Box>

      {/* Monthly Subcontractor Projects */}
      <Box p={4} bg="white" borderWidth="1px" borderRadius="md" mt={4}>
        <Flex justify="space-between" align="center" mb={4}>
          <Heading size="md">Monthly Subcontractor Projects</Heading>
          <Button
            size="sm"
            colorScheme="teal"
            as={Link}
            to="/details/subcontractor-projects"
          >
            View Details
          </Button>
        </Flex>
        <Bar data={subcontractorProjectsData} />
      </Box>

      {/* To-Do List */}
      <Box p={4} bg="white" borderWidth="1px" borderRadius="md" mt={4}>
        <Flex justify="space-between" align="center" mb={4}>
          <Heading size="md">To-Do List</Heading>
          <Button size="sm" colorScheme="teal" as={Link} to="/details/todo">
            View Details
          </Button>
        </Flex>
        <Box mt={4}>
          {todos.map((todo) => (
            <Box
              key={todo.id}
              textDecoration={todo.completed ? "line-through" : "none"}
              cursor="pointer"
              onClick={() => toggleComplete(todo.id)}
              _hover={{ textDecoration: todo.completed ? "none" : "underline" }}
              display="flex"
              alignItems="center"
              p={2}
              borderBottom="1px"
              borderColor="gray.200"
            >
              <Checkbox
                isChecked={todo.completed}
                onChange={() => toggleComplete(todo.id)}
                mr={2}
              />
              <span>
                {todo.text} - {todo.date}
              </span>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
