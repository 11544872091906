// src/components/MessagingApp.js
import React from "react";
import { Flex } from "@chakra-ui/react";
import ChatList from "./ChatList";
import ChatWindow from "./ChatWindow";
import ChatApp from "./ChatApp";

const MessagingApp = () => {
  return (
    <Flex>
      <ChatApp />
      {/* <ChatWindow /> */}
    </Flex>
  );
};

export default MessagingApp;
