// src/components/ResourceTable.js

import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td, IconButton } from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";

const ResourceTable = ({ data, columns, section, onEdit, onDelete }) => {
  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          {columns.map((col) => (
            <Th key={col}>{col}</Th>
          ))}
          <Th>Actions</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((item) => (
          <Tr key={item.id}>
            {/* Map each column to the appropriate data key */}
            {columns.map((col) => {
              // Explicit mapping based on column names
              let value;
              if (col === "Equipment" || col === "Skill")
                value = item.equipment || item.skill;
              else if (col === "Quantity") value = item.quantity;
              else if (col === "Assigned To") value = item.assignedTo;
              else if (col === "Planned Start Date") value = item.startDate;
              else if (col === "Planned End Date") value = item.endDate;
              else if (col === "Days") value = item.days;
              else if (col === "Status") value = item.status;
              else if (col === "Notes") value = item.notes;
              else if (col === "Expense Description")
                value = item.expenseDescription;
              else if (col === "Expected Amount") value = item.expectedAmount;

              return <Td key={col}>{value}</Td>;
            })}
            <Td>
              <IconButton
                aria-label="Edit"
                icon={<EditIcon />}
                mr={2}
                onClick={() => onEdit(item, section)}
              />
              <IconButton
                aria-label="Delete"
                icon={<DeleteIcon />}
                colorScheme="red"
                onClick={() => onDelete(item.id, section)}
              />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default ResourceTable;
