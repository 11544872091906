import React, { useState } from "react";
import { Box, Heading, Flex, Text, IconButton } from "@chakra-ui/react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

const CostComparisonChart = () => {
  // Dummy data for planned vs actual material and manpower costs
  const [isOpen, setIsOpen] = useState(false);
  const data = {
    labels: ["Material Cost", "Manpower Cost"],
    datasets: [
      {
        label: "Planned Cost (AED)",
        data: [50000, 60000], // Planned costs for material and manpower
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "Actual Cost (AED)",
        data: [55000, 65000], // Actual costs for material and manpower
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  // Calculating total planned and actual cost
  const totalPlannedCost = data.datasets[0].data.reduce(
    (acc, value) => acc + value,
    0
  );
  const totalActualCost = data.datasets[1].data.reduce(
    (acc, value) => acc + value,
    0
  );
  // Toggle visibility
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Box p={4} bg="primary.100" borderRadius="md" borderWidth="1px">
      <Flex justify="space-between" align="center" mb={4}>
        <Heading size="md">Cost Comparison: Planned vs Actual</Heading>
        <IconButton
          icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          onClick={toggleOpen}
          variant="outline"
          aria-label="Toggle chart visibility"
        />
      </Flex>

      {/* Conditionally render the chart and total costs */}
      {isOpen && (
        <>
          {/* Bar Chart */}
          <Box bg="white" p={4} borderRadius="md" borderWidth="1px">
            <Bar
              data={data}
              options={{
                responsive: true,
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
              }}
            />
          </Box>

          {/* Displaying Total Planned vs Actual Costs */}
          <Box mt={4} p={4} bg="white" borderRadius="md" borderWidth="1px">
            <Text fontSize="lg" fontWeight="bold">
              Total Planned Cost: AED {totalPlannedCost.toLocaleString()}
            </Text>
            <Text fontSize="lg" fontWeight="bold" color="red.500">
              Total Actual Cost: AED {totalActualCost.toLocaleString()}
            </Text>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CostComparisonChart;
