import React, { useState, useMemo, useCallback } from "react";
import {
  Box,
  Button,
  Flex,
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  useDisclosure,
  Select,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import debounce from "lodash/debounce";
import GenericHeader from "../../commons/GenericHeader";
import SearchInput from "../../commons/SearchInput";
import GenericGrid from "../Procurement/GenericGrid";
import { useNavigate } from "react-router-dom";
import DashboardBox from "../../commons/DashboardBox";

const LogRequirment = () => {
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([
    {
      sno: 1,
      description: "Cement",
      qty: 20,
      remarks: "For foundation",
      stage: "Initial",
      activity: "Foundation Laying",
      amount: 5000,
      shop: "ABC Supplies",
      notes: "Urgent delivery needed",
    },
    {
      sno: 2,
      description: "Bricks",
      qty: 1000,
      remarks: "For wall construction",
      stage: "Ongoing",
      activity: "Wall Construction",
      amount: 20000,
      shop: "XYZ Supplies",
      notes: "Delivery expected in 2 days",
    },
  ]);
  const [originalData] = useState(rowData);
  const [sortConfig, setSortConfig] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(rowData);

  const toast = useToast();
  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();
  const {
    isOpen: isColumnModalOpen,
    onOpen: onOpenColumnModal,
    onClose: onCloseColumnModal,
  } = useDisclosure();

  const [selectedActivity, setSelectedActivity] = useState("");
  const [selectedStage, setSelectedStage] = useState("");

  const filters = useMemo(
    () => ({
      activity: selectedActivity,
      stage: selectedStage,
    }),
    [selectedActivity, selectedStage]
  );

  const debouncedApplySearchFilter = useCallback(
    debounce((text) => applySearchFilter(text), 300),
    []
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedApplySearchFilter(text);
  };

  const applySearchFilter = (text) => {
    const filteredData = originalData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(text.toLowerCase())
      )
    );
    setFilteredData(filteredData);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredData(originalData);
  };

  const handleTagClose = (filterType) => {
    let newSelectedActivity = selectedActivity;
    let newSelectedStage = selectedStage;

    switch (filterType) {
      case "activity":
        newSelectedActivity = "";
        break;
      case "stage":
        newSelectedStage = "";
        break;
      default:
        break;
    }

    const filteredData = originalData.filter((row) => {
      return (
        (!newSelectedActivity || row.activity === newSelectedActivity) &&
        (!newSelectedStage || row.stage === newSelectedStage)
      );
    });

    setSelectedActivity(newSelectedActivity);
    setSelectedStage(newSelectedStage);
    setRowData(filteredData);
    setFilteredData(filteredData);

    toast({
      title: "Filter Removed",
      description: `Filter for ${filterType} has been removed.`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const applyFilter = () => {
    const filteredData = originalData.filter((row) => {
      return (
        (!selectedActivity || row.activity === selectedActivity) &&
        (!selectedStage || row.stage === selectedStage)
      );
    });

    setRowData(filteredData);
    setFilteredData(filteredData);
    toast({
      title: "Filters Applied",
      description: "Data has been filtered",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const clearFilter = () => {
    setSelectedActivity("");
    setSelectedStage("");
    setRowData(originalData);
    setFilteredData(originalData);
    toast({
      title: "Filters Cleared",
      description: "Filters have been cleared",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const handleRowClick = (row) => {
    navigate(`/material-required/edit/${row.id}`);
  };

  const materialColumns = [
    {
      id: "sno",
      header: "Sno",
      accessor: (item) => item.sno,
      show: true,
      width: 20,
    },
    {
      id: "description",
      header: "Description",
      accessor: (item) => item.description,
      show: true,
      width: 150,
    },
    {
      id: "qty",
      header: "Quantity",
      accessor: (item) => item.qty,
      show: true,
      width: 100,
    },
    {
      id: "remarks",
      header: "Remarks",
      accessor: (item) => item.remarks,
      show: true,
      width: 250,
    },
    {
      id: "stage",
      header: "Stage",
      accessor: (item) => item.stage,
      show: true,
      width: 150,
    },
    {
      id: "activity",
      header: "Activity",
      accessor: (item) => item.activity,
      show: true,
      width: 150,
    },
    {
      id: "amount",
      header: "Amount",
      accessor: (item) => item.amount,
      show: true,
      width: 150,
    },
    {
      id: "shop",
      header: "Shop",
      accessor: (item) => item.shop,
      show: true,
      width: 150,
    },
    {
      id: "notes",
      header: "Notes",
      accessor: (item) => item.notes,
      show: true,
      width: 250,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    materialColumns.filter((col) => col.show).map((col) => col.id)
  );

  const handleVisibleColumnsChange = (columnId) => {
    const updatedColumns = visibleColumns.includes(columnId)
      ? visibleColumns.filter((col) => col !== columnId)
      : [...visibleColumns, columnId];
    setVisibleColumns(updatedColumns);
  };

  const handleSort = (column) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === column &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key: column, direction: direction });

    const sortedData = [...rowData].sort((a, b) => {
      if (a[column] < b[column]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[column] > b[column]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredData(sortedData);
  };

  const totalAmount = useMemo(() => {
    return rowData.reduce((total, row) => total + row.amount, 0);
  }, [rowData]);

  return (
    <Box>
      <GenericHeader
        title="Material Requirement List"
        to={"/material-required/add"}
        addButtonName={"Material Requirement"}
        icon={<AddIcon />} // Replace with any relevant icon
      />
      <Flex justifyContent="space-between" mb={4} mt={4} gap={2}>
        <DashboardBox
          icon={AddIcon}
          name="Total Amount"
          value={totalAmount}
          type="money"
          backgroundColor="teal.300"
        />
      </Flex>
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        <Button leftIcon={<AddIcon />} onClick={onOpenFilterModal}>
          Filter
        </Button>
        <SearchInput
          placeholder="Search Material"
          value={searchText}
          onChange={handleSearchInputChange}
          onClear={handleClearSearch}
        />
        <Button onClick={onOpenColumnModal}>Toggle Columns</Button>
      </Flex>
      <Flex mb={4}>
        <Flex>
          {filters.activity && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Activity: ${filters.activity}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("activity")} />
            </Tag>
          )}
          {filters.stage && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Stage: ${filters.stage}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("stage")} />
            </Tag>
          )}
        </Flex>
      </Flex>
      <Modal isOpen={isFilterModalOpen} onClose={onCloseFilterModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Select
                placeholder="Select Activity"
                onChange={(e) => setSelectedActivity(e.target.value)}
                mb={4}
                value={selectedActivity}
              >
                {originalData.map((row) => (
                  <option key={row.activity} value={row.activity}>
                    {row.activity}
                  </option>
                ))}
              </Select>
              <Select
                placeholder="Select Stage"
                onChange={(e) => setSelectedStage(e.target.value)}
                value={selectedStage}
              >
                {originalData.map((row) => (
                  <option key={row.stage} value={row.stage}>
                    {row.stage}
                  </option>
                ))}
              </Select>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={applyFilter} mr={3}>
              Apply Filter
            </Button>
            <Button variant="ghost" onClick={clearFilter}>
              Clear Filter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isColumnModalOpen} onClose={onCloseColumnModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Toggle Columns</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {materialColumns.map((col) => (
                <FormControl
                  display="flex"
                  alignItems="center"
                  key={col.id}
                  mb={2}
                >
                  <FormLabel htmlFor={col.id} mb="0" flex="1">
                    {col.header}
                  </FormLabel>
                  <Switch
                    id={col.id}
                    isChecked={visibleColumns.includes(col.id)}
                    onChange={() => handleVisibleColumnsChange(col.id)}
                  />
                </FormControl>
              ))}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onCloseColumnModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box>
        <GenericGrid
          columns={materialColumns}
          data={filteredData}
          title="Material Requirement List"
          visibleColumnsState={visibleColumns}
          filterState={filters}
          onVisibleColumnsChange={handleVisibleColumnsChange}
          onSort={handleSort}
          sortConfig={sortConfig}
          onRowClick={handleRowClick}
        />
      </Box>
    </Box>
  );
};

export default LogRequirment;
