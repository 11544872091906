// src/components/Bills/BillsTabContainer.jsx

import React from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import BillsList from "./BillsList";
import ItemsSearch from "./ItemsSearch";
import GenericHeader from "../../commons/GenericHeader";
import { CiMoneyBill } from "react-icons/ci";
import PetrolCost from "./PetrolCost";
import OverheadCosts from "./OverheadCosts";

const BillsTabContainer = () => {
  return (
    <>
      <GenericHeader
        title="Bills"
        addButtonName="Bill"
        to="/bills/add"
        icon={<CiMoneyBill />}
      />
      <Tabs variant="enclosed">
        <TabList>
          <Tab
            borderRadius={20}
            _selected={{
              color: "white",
              bg: "primary.500",
            }}
          >
            Invoices
          </Tab>
          <Tab
            borderRadius={20}
            __css={{ borderRadius: "20" }}
            _selected={{ color: "white", bg: "primary.500" }}
          >
            Items Search
          </Tab>
          <Tab
            borderRadius={20}
            __css={{ borderRadius: "20" }}
            _selected={{ color: "white", bg: "primary.500" }}
          >
            Petrol Costs
          </Tab>
          <Tab
            borderRadius={20}
            __css={{ borderRadius: "20" }}
            _selected={{ color: "white", bg: "primary.500" }}
          >
            Overhead Cost
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <BillsList />
          </TabPanel>
          <TabPanel>
            <ItemsSearch />
          </TabPanel>
          <TabPanel>
            <PetrolCost />
          </TabPanel>
          <TabPanel>
            <OverheadCosts />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default BillsTabContainer;
