import React, { useState } from "react";
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  SimpleGrid,
  Button,
} from "@chakra-ui/react";
import {
  FaMoneyCheck,
  FaBook,
  FaMoneyBill,
  FaMoneyCheckAlt,
} from "react-icons/fa";
import GenericHeader from "../../../commons/GenericHeader";
import DashboardBox from "../../../commons/DashboardBox";
import GenericGrid from "../../Procurement/GenericGrid";
import LedgerManagement from "./LedgerManagement";
import AddAccountDrawer from "./AddAccountDrawer";
import LedgerDetailView from "./LedgerDetailView"; // New component for ledger details

const AccountsList = () => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  // Sample accounts data
  const accountsData = [
    { id: 1, name: "Cash", type: "Asset", balance: 50000, category: "Office" },
    {
      id: 2,
      name: "Accounts Receivable",
      type: "Asset",
      balance: 150000,
      category: "Clients",
    },
    {
      id: 3,
      name: "Subcontractor ABC",
      type: "Liability",
      balance: 80000,
      category: "Subcontractor",
    },
    {
      id: 4,
      name: "Project XYZ",
      type: "Expense",
      balance: 200000,
      category: "Projects",
    },
    {
      id: 5,
      name: "Employee John Salary",
      type: "Expense",
      balance: 50000,
      category: "Salary",
    },
  ];

  const columns = [
    { id: "id", header: "ID", accessor: (row) => row.id },
    { id: "name", header: "Name", accessor: (row) => row.name },
    { id: "type", header: "Type", accessor: (row) => row.type },
    { id: "category", header: "Category", accessor: (row) => row.category },
    {
      id: "balance",
      header: "Balance",
      accessor: (row) => `AED ${row.balance.toLocaleString()}`,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    columns.map((col) => col.id)
  );

  const handleRowClick = (account) => {
    setSelectedAccount(account);
  };

  return (
    <Box>
      <GenericHeader title="Accounts Management" />

      {!selectedAccount ? (
        <Tabs>
          <TabList>
            <Tab
              borderRadius={20}
              _selected={{ color: "white", bg: "primary.500" }}
            >
              Chart of Accounts
            </Tab>
            <Tab
              borderRadius={20}
              _selected={{ color: "white", bg: "primary.500" }}
            >
              General Ledger
            </Tab>
            <Tab
              borderRadius={20}
              _selected={{ color: "white", bg: "primary.500" }}
            >
              Accounts Payable
            </Tab>
            <Tab
              borderRadius={20}
              _selected={{ color: "white", bg: "primary.500" }}
            >
              Accounts Receivable
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Button
                onClick={() => setDrawerOpen(true)}
                colorScheme="blue"
                mb={4}
              >
                Add New Account
              </Button>
              <AddAccountDrawer
                isOpen={isDrawerOpen}
                onClose={() => setDrawerOpen(false)}
              />

              <SimpleGrid columns={{ sm: 1, md: 4 }} spacing={5}>
                <DashboardBox
                  icon={FaMoneyCheck}
                  name="Total Assets"
                  value="200,000"
                  backgroundColor="#68D391"
                />
                <DashboardBox
                  icon={FaMoneyBill}
                  name="Total Liabilities"
                  value="80,000"
                  backgroundColor="#FC8181"
                />
                <DashboardBox
                  icon={FaMoneyCheckAlt}
                  name="Total Revenue"
                  value="300,000"
                  backgroundColor="#4299E1"
                />
                <DashboardBox
                  icon={FaBook}
                  name="Total Equity"
                  value="120,000"
                  backgroundColor="#F6AD55"
                />
              </SimpleGrid>

              <Box mt={8}>
                <GenericGrid
                  columns={columns}
                  data={accountsData}
                  title="Chart of Accounts"
                  visibleColumnsState={visibleColumns}
                  onVisibleColumnsChange={setVisibleColumns}
                  filterState={{}}
                  onSort={() => {}}
                  sortConfig={{}}
                  maxHeight="400px"
                  onRowClick={handleRowClick}
                  onSelectedRowsChange={() => {}}
                />
              </Box>
            </TabPanel>

            <TabPanel>
              <LedgerManagement />
            </TabPanel>

            <TabPanel>
              <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={5}>
                <DashboardBox
                  icon={FaMoneyBill}
                  name="Total Payables"
                  value="80,000"
                  backgroundColor="#FC8181"
                />
                <DashboardBox
                  icon={FaBook}
                  name="Pending Payments"
                  value="20"
                  backgroundColor="#4299E1"
                />
              </SimpleGrid>
              <Box mt={8}>
                <GenericGrid
                  columns={columns}
                  data={accountsData.filter((acc) => acc.type === "Liability")}
                  title="Accounts Payable"
                  visibleColumnsState={visibleColumns}
                  onVisibleColumnsChange={setVisibleColumns}
                  filterState={{}}
                  onSort={() => {}}
                  sortConfig={{}}
                  maxHeight="400px"
                  onRowClick={handleRowClick}
                  onSelectedRowsChange={() => {}}
                />
              </Box>
            </TabPanel>

            <TabPanel>
              <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={5}>
                <DashboardBox
                  icon={FaMoneyCheckAlt}
                  name="Total Receivables"
                  value="150,000"
                  backgroundColor="#68D391"
                />
                <DashboardBox
                  icon={FaBook}
                  name="Pending Invoices"
                  value="15"
                  backgroundColor="#4299E1"
                />
              </SimpleGrid>
              <Box mt={8}>
                <GenericGrid
                  columns={columns}
                  data={accountsData.filter((acc) => acc.type === "Asset")}
                  title="Accounts Receivable"
                  visibleColumnsState={visibleColumns}
                  onVisibleColumnsChange={setVisibleColumns}
                  filterState={{}}
                  onSort={() => {}}
                  sortConfig={{}}
                  maxHeight="400px"
                  onRowClick={handleRowClick}
                  onSelectedRowsChange={() => {}}
                />
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      ) : (
        <LedgerDetailView
          account={selectedAccount}
          onClose={() => setSelectedAccount(null)}
        />
      )}
    </Box>
  );
};

export default AccountsList;
