// src/components/TaskItem.js
import React from "react";
import {
  Box,
  Text,
  HStack,
  Checkbox,
  IconButton,
  Badge,
  useColorModeValue,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";

const TaskItem = ({ task, onToggle, onEdit, onDelete }) => {
  const borderColorMap = {
    blue: "blue.500",
    green: "green.500",
    red: "red.500",
    yellow: "yellow.500",
    purple: "purple.500",
    teal: "teal.500",
    none: "transparent",
  };

  return (
    <Box
      p={4}
      borderLeft="4px solid"
      borderColor={borderColorMap[task.color] || "transparent"}
      borderRadius="md"
      boxShadow="sm"
      bg={useColorModeValue("white", "gray.700")}
    >
      <HStack justifyContent="space-between" alignItems="center">
        <HStack>
          <Checkbox
            isChecked={task.completed}
            onChange={() => onToggle(task.id)}
            colorScheme="teal"
          />
          <Box>
            <Text
              fontSize="lg"
              fontWeight="bold"
              textDecoration={task.completed ? "line-through" : "none"}
            >
              {task.description}
            </Text>
            <HStack spacing={2} mt={2}>
              <Badge colorScheme="purple">{task.priority}</Badge>
              {task.reminder && (
                <Badge colorScheme="orange">
                  Reminder: {new Date(task.reminder).toLocaleString()}
                </Badge>
              )}
              {task.repeat !== "None" && (
                <Badge colorScheme="teal">Repeat: {task.repeat}</Badge>
              )}
            </HStack>
          </Box>
        </HStack>
        <HStack spacing={2}>
          <IconButton
            aria-label="Edit Task"
            icon={<EditIcon />}
            size="sm"
            colorScheme="yellow"
            onClick={() => onEdit(task)}
          />
          <IconButton
            aria-label="Delete Task"
            icon={<DeleteIcon />}
            size="sm"
            colorScheme="red"
            onClick={() => onDelete(task.id)}
          />
        </HStack>
      </HStack>
    </Box>
  );
};

export default TaskItem;
