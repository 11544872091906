import React, { useState, useMemo, useCallback } from "react";
import {
  Box,
  Button,
  Flex,
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  useDisclosure,
  Select,
  FormControl,
  FormLabel,
  Switch,
  Input,
  NumberInput,
  NumberInputField,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import debounce from "lodash/debounce";
import GenericGrid from "../../Procurement/GenericGrid";
import DashboardBox from "../../../commons/DashboardBox";
import SearchInput from "../../../commons/SearchInput";

const LedgerManagement = () => {
  // Initial transaction data
  const [rowData, setRowData] = useState([
    {
      sno: 1,
      date: "2024-08-01",
      accountFrom: "Account 1",
      accountTo: "Account 2",
      description: "Payment for services",
      amount: 500,
    },
    {
      sno: 2,
      date: "2024-08-02",
      accountFrom: "Account 2",
      accountTo: "Account 3",
      description: "Invoice payment",
      amount: 1500,
    },
    {
      sno: 3,
      date: "2024-08-03",
      accountFrom: "Account 1",
      accountTo: "Account 3",
      description: "Transfer",
      amount: 200,
    },
    {
      sno: 4,
      date: "2024-08-04",
      accountFrom: "Account 3",
      accountTo: "Account 2",
      description: "Refund",
      amount: 300,
    },
  ]);

  const [originalData] = useState(rowData);
  const [sortConfig, setSortConfig] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(rowData);
  const toast = useToast();

  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();
  const {
    isOpen: isColumnModalOpen,
    onOpen: onOpenColumnModal,
    onClose: onCloseColumnModal,
  } = useDisclosure();
  const {
    isOpen: isDrawerOpen,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();

  const [newTransaction, setNewTransaction] = useState({
    date: "",
    accountFrom: "",
    accountTo: "",
    description: "",
    amount: "",
  });

  const [selectedAccountFrom, setSelectedAccountFrom] = useState("");
  const [selectedAccountTo, setSelectedAccountTo] = useState("");

  const filters = useMemo(
    () => ({
      accountFrom: selectedAccountFrom,
      accountTo: selectedAccountTo,
    }),
    [selectedAccountFrom, selectedAccountTo]
  );

  const debouncedApplySearchFilter = useCallback(
    debounce((text) => applySearchFilter(text), 300),
    []
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedApplySearchFilter(text);
  };

  const applySearchFilter = (text) => {
    const filteredData = originalData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(text.toLowerCase())
      )
    );
    setFilteredData(filteredData);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredData(originalData);
  };

  const handleTagClose = (filterType) => {
    let newSelectedAccountFrom = selectedAccountFrom;
    let newSelectedAccountTo = selectedAccountTo;

    switch (filterType) {
      case "accountFrom":
        newSelectedAccountFrom = "";
        break;
      case "accountTo":
        newSelectedAccountTo = "";
        break;
      default:
        break;
    }

    const filteredData = originalData.filter((row) => {
      return (
        (!newSelectedAccountFrom ||
          row.accountFrom === newSelectedAccountFrom) &&
        (!newSelectedAccountTo || row.accountTo === newSelectedAccountTo)
      );
    });

    setSelectedAccountFrom(newSelectedAccountFrom);
    setSelectedAccountTo(newSelectedAccountTo);
    setRowData(filteredData);
    setFilteredData(filteredData);

    toast({
      title: "Filter Removed",
      description: `Filter for ${filterType} has been removed.`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const applyFilter = () => {
    const filteredData = originalData.filter((row) => {
      return (
        (!selectedAccountFrom || row.accountFrom === selectedAccountFrom) &&
        (!selectedAccountTo || row.accountTo === selectedAccountTo)
      );
    });

    setRowData(filteredData);
    setFilteredData(filteredData);
    toast({
      title: "Filters Applied",
      description: "Data has been filtered",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const clearFilter = () => {
    setSelectedAccountFrom("");
    setSelectedAccountTo("");
    setRowData(originalData);
    setFilteredData(originalData);
    toast({
      title: "Filters Cleared",
      description: "Filters have been cleared",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const ledgerColumns = [
    { id: "sno", header: "Sno", accessor: (row) => row.sno, show: true },
    { id: "date", header: "Date", accessor: (row) => row.date, show: true },
    {
      id: "accountFrom",
      header: "Account From",
      accessor: (row) => row.accountFrom,
      show: true,
    },
    {
      id: "accountTo",
      header: "Account To",
      accessor: (row) => row.accountTo,
      show: true,
    },
    {
      id: "description",
      header: "Description",
      accessor: (row) => row.description,
      show: true,
    },
    {
      id: "amount",
      header: "Amount",
      accessor: (row) => row.amount,
      show: true,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    ledgerColumns.filter((col) => col.show).map((col) => col.id)
  );

  const handleVisibleColumnsChange = (columnId) => {
    const updatedColumns = visibleColumns.includes(columnId)
      ? visibleColumns.filter((col) => col !== columnId)
      : [...visibleColumns, columnId];
    setVisibleColumns(updatedColumns);
  };

  const handleSort = (column) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === column &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key: column, direction: direction });

    const sortedData = [...rowData].sort((a, b) => {
      if (a[column] < b[column]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[column] > b[column]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredData(sortedData);
  };

  const totalAmount = useMemo(() => {
    return rowData.reduce((total, row) => total + row.amount, 0);
  }, [rowData]);

  const averageAmount = useMemo(() => {
    return rowData.length
      ? (
          rowData.reduce((total, row) => total + row.amount, 0) / rowData.length
        ).toFixed(2)
      : 0;
  }, [rowData]);

  // Handle Drawer Form Submit
  const handleAddTransaction = () => {
    setRowData([
      ...rowData,
      {
        sno: rowData.length + 1,
        date: newTransaction.date,
        accountFrom: newTransaction.accountFrom,
        accountTo: newTransaction.accountTo,
        description: newTransaction.description,
        amount: parseFloat(newTransaction.amount),
      },
    ]);
    onCloseDrawer();
    setNewTransaction({
      date: "",
      accountFrom: "",
      accountTo: "",
      description: "",
      amount: "",
    });
    toast({
      title: "Transaction Added",
      description: "New transaction added successfully",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Box>
      <Button leftIcon={<AddIcon />} onClick={onOpenDrawer}>
        Add Transaction
      </Button>
      <Flex justifyContent="space-between" mb={4} mt={4} gap={2}>
        <DashboardBox
          icon={AddIcon} // You can choose any relevant icon
          name="Total Amount"
          value={totalAmount}
          type="money"
          backgroundColor="teal.300"
        />
        <DashboardBox
          icon={AddIcon} // You can choose any relevant icon
          name="Average Amount"
          value={averageAmount}
          backgroundColor="blue.300"
        />
      </Flex>
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        <Button onClick={onOpenFilterModal}>Filter</Button>
        <SearchInput
          placeholder="Search Transactions"
          value={searchText}
          onChange={handleSearchInputChange}
          onClear={handleClearSearch}
        />
        <Button onClick={onOpenColumnModal}>Toggle Columns</Button>
      </Flex>
      <Flex mb={4}>
        <Flex>
          {filters.accountFrom && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Account From: ${filters.accountFrom}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("accountFrom")} />
            </Tag>
          )}
          {filters.accountTo && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Account To: ${filters.accountTo}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("accountTo")} />
            </Tag>
          )}
        </Flex>
      </Flex>

      {/* Filter Modal */}
      <Modal isOpen={isFilterModalOpen} onClose={onCloseFilterModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Select
                placeholder="Select Account From"
                onChange={(e) => setSelectedAccountFrom(e.target.value)}
                mb={4}
                value={selectedAccountFrom}
              >
                {originalData.map((row) => (
                  <option key={row.accountFrom} value={row.accountFrom}>
                    {row.accountFrom}
                  </option>
                ))}
              </Select>
              <Select
                placeholder="Select Account To"
                onChange={(e) => setSelectedAccountTo(e.target.value)}
                value={selectedAccountTo}
              >
                {originalData.map((row) => (
                  <option key={row.accountTo} value={row.accountTo}>
                    {row.accountTo}
                  </option>
                ))}
              </Select>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={applyFilter} mr={3}>
              Apply Filter
            </Button>
            <Button variant="ghost" onClick={clearFilter}>
              Clear Filter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Column Toggle Modal */}
      <Modal isOpen={isColumnModalOpen} onClose={onCloseColumnModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Toggle Columns</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {ledgerColumns.map((col) => (
                <FormControl
                  display="flex"
                  alignItems="center"
                  key={col.id}
                  mb={2}
                >
                  <FormLabel htmlFor={col.id} mb="0" flex="1">
                    {col.header}
                  </FormLabel>
                  <Switch
                    id={col.id}
                    isChecked={visibleColumns.includes(col.id)}
                    onChange={() => handleVisibleColumnsChange(col.id)}
                  />
                </FormControl>
              ))}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onCloseColumnModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box>
        <GenericGrid
          columns={ledgerColumns}
          data={filteredData}
          title="Ledger List"
          visibleColumnsState={visibleColumns}
          filterState={filters}
          onVisibleColumnsChange={handleVisibleColumnsChange}
          onSort={handleSort}
          sortConfig={sortConfig}
        />
      </Box>

      {/* Drawer for Adding New Transaction */}
      <Drawer isOpen={isDrawerOpen} placement="right" onClose={onCloseDrawer}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add New Transaction</DrawerHeader>
          <DrawerBody>
            <Input
              placeholder="Date"
              mb={4}
              name="date"
              value={newTransaction.date}
              onChange={(e) =>
                setNewTransaction({ ...newTransaction, date: e.target.value })
              }
            />
            <Input
              placeholder="Account From"
              mb={4}
              name="accountFrom"
              value={newTransaction.accountFrom}
              onChange={(e) =>
                setNewTransaction({
                  ...newTransaction,
                  accountFrom: e.target.value,
                })
              }
            />
            <Input
              placeholder="Account To"
              mb={4}
              name="accountTo"
              value={newTransaction.accountTo}
              onChange={(e) =>
                setNewTransaction({
                  ...newTransaction,
                  accountTo: e.target.value,
                })
              }
            />
            <Input
              placeholder="Description"
              mb={4}
              name="description"
              value={newTransaction.description}
              onChange={(e) =>
                setNewTransaction({
                  ...newTransaction,
                  description: e.target.value,
                })
              }
            />
            <NumberInput
              mb={4}
              name="amount"
              value={newTransaction.amount}
              onChange={(valueString) =>
                setNewTransaction({
                  ...newTransaction,
                  amount: parseFloat(valueString),
                })
              }
              min={0}
            >
              <NumberInputField placeholder="Amount" />
            </NumberInput>
          </DrawerBody>
          <DrawerFooter>
            <Button colorScheme="teal" onClick={handleAddTransaction}>
              Submit
            </Button>
            <Button variant="ghost" onClick={onCloseDrawer}>
              Cancel
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default LedgerManagement;
