import React, { useState, useMemo, useCallback } from "react";
import { Box, Button, Flex, useToast, useDisclosure } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { AddIcon } from "@chakra-ui/icons";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";
import GenericGrid from "../../Procurement/GenericGrid";
import SearchInput from "../../../commons/SearchInput";
import GenericHeader from "../../../commons/GenericHeader";

const HRHiringList = () => {
  const navigate = useNavigate();
  const hiringData = useSelector((state) => state.hiring.hires || []); // Ensure data is always an array
  const [rowData, setRowData] = useState(hiringData);
  const [originalData] = useState(hiringData);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(hiringData);
  const [sortConfig, setSortConfig] = useState(null);

  const toast = useToast();
  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();

  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  // Memoize the filters
  const filterState = useMemo(
    () => ({
      position: selectedPosition,
      status: selectedStatus,
    }),
    [selectedPosition, selectedStatus]
  );

  const debouncedApplySearchFilter = useCallback(
    debounce((text) => applySearchFilter(text), 300),
    []
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedApplySearchFilter(text);
  };

  const applySearchFilter = (text) => {
    const filteredData = originalData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(text.toLowerCase())
      )
    );
    setFilteredData(filteredData);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredData(originalData);
  };

  const handleRowClick = (row) => {
    navigate(`/hiring/edit/${row.id}`);
  };

  const hiringColumns = [
    { id: "id", header: "ID", accessor: (item) => item.id ?? "", show: true }, // Ensure ID is always available
    {
      id: "candidateName",
      header: "Candidate Name",
      accessor: (item) => item.candidateName ?? "N/A",
      show: true,
    },
    {
      id: "position",
      header: "Position",
      accessor: (item) => item.position ?? "N/A",
      show: true,
    },
    {
      id: "department",
      header: "Department",
      accessor: (item) => item.department ?? "N/A",
      show: true,
    },
    {
      id: "dateOfApplication",
      header: "Date of Application",
      accessor: (item) => item.dateOfApplication ?? "N/A",
      show: true,
    },
    {
      id: "vaccinationStatus",
      header: "Vaccination Status",
      accessor: (item) => item.vaccinationStatus ?? "N/A",
      show: true,
    },
    {
      id: "remarks",
      header: "Remarks",
      accessor: (item) => item.remarks ?? "N/A",
      show: true,
    },
    {
      id: "status",
      header: "Status",
      accessor: (item) => item.status ?? "N/A",
      show: true,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    hiringColumns.filter((col) => col.show).map((col) => col.id)
  );
  const handleSort = (column) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === column &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key: column, direction: direction });

    const sortedData = [...rowData].sort((a, b) => {
      if (a[column] < b[column]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[column] > b[column]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredData(sortedData);
  };
  return (
    <Box>
      <GenericHeader
        title="HR Hiring List"
        to="/hiring/add"
        addButtonName="Add Candidate"
        icon={<AddIcon />}
      />
      <Flex justifyContent="space-between" mb={4} mt={4} gap={2}>
        <SearchInput
          placeholder="Search Candidates"
          value={searchText}
          onChange={handleSearchInputChange}
          onClear={handleClearSearch}
        />
        <Button onClick={onOpenFilterModal}>Filter</Button>
      </Flex>

      <GenericGrid
        columns={hiringColumns}
        data={filteredData || []} // Always pass an array
        title="HR Hiring List"
        visibleColumnsState={visibleColumns}
        filterState={filterState} // Pass the filter state
        onVisibleColumnsChange={setVisibleColumns}
        onSort={handleSort}
        sortConfig={sortConfig}
        onRowClick={handleRowClick}
      />
    </Box>
  );
};

export default HRHiringList;
