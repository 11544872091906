import React, { useState, useMemo, useCallback } from "react";
import {
  Box,
  Flex,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  FormControl,
  FormLabel,
  Select,
  useToast,
  useDisclosure,
  Input,
  Textarea,
  Tag,
  TagLabel,
  TagCloseButton,
  Switch,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import debounce from "lodash/debounce";
import GenericHeader from "../../commons/GenericHeader"; // Adjust the path as needed
import SearchInput from "../../commons/SearchInput"; // Adjust the path as needed
import GenericGrid from "../Procurement/GenericGrid"; // Adjust the path as needed
import DashboardBox from "../../commons/DashboardBox"; // Adjust the path as needed
import { TbCash } from "react-icons/tb";
import { CiMoneyBill } from "react-icons/ci";
import { FaMoneyBill } from "react-icons/fa";

const PettyCashRecord = () => {
  const initialRecords = [
    {
      sno: 1,
      description: "Office Supplies",
      applicantName: "Ali Khan",
      amount: 150,
      uploadedFile: "receipt1.jpg",
      project: "Project A",
      stage: "Stage 1",
      status: "Pending",
      remarks: "Needed for the office.",
    },
    {
      sno: 2,
      description: "Transport",
      applicantName: "Fatima Ahmed",
      amount: 200,
      uploadedFile: "receipt2.jpg",
      project: "Project B",
      stage: "Stage 2",
      status: "Approved",
      remarks: "Transport for site visit.",
    },
    {
      sno: 3,
      description: "Food and Beverages",
      applicantName: "Zainab Malik",
      amount: 100,
      uploadedFile: "receipt3.jpg",
      project: "Project C",
      stage: "Stage 3",
      status: "Rejected",
      remarks: "Catering for a meeting.",
    },
  ];

  const [records, setRecords] = useState(initialRecords);
  const [originalData] = useState(initialRecords);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(records);
  const [description, setDescription] = useState("");
  const [applicantName, setApplicantName] = useState("");
  const [amount, setAmount] = useState("");
  const [status, setStatus] = useState("Pending");
  const [remarks, setRemarks] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [project, setProject] = useState("");
  const [stage, setStage] = useState("");

  const toast = useToast();
  const {
    isOpen: isAddDrawerOpen,
    onOpen: onOpenAddDrawer,
    onClose: onCloseAddDrawer,
  } = useDisclosure();
  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();
  const {
    isOpen: isColumnModalOpen,
    onOpen: onOpenColumnModal,
    onClose: onCloseColumnModal,
  } = useDisclosure();

  const [selectedStatus, setSelectedStatus] = useState("");

  const debouncedApplySearchFilter = useCallback(
    debounce((text) => applySearchFilter(text), 300),
    []
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedApplySearchFilter(text);
  };

  const applySearchFilter = (text) => {
    const filteredData = originalData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(text.toLowerCase())
      )
    );
    setFilteredData(filteredData);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredData(originalData);
  };

  const handleTagClose = (filterType) => {
    let newSelectedStatus = selectedStatus;

    switch (filterType) {
      case "status":
        newSelectedStatus = "";
        break;
      default:
        break;
    }

    const filteredData = originalData.filter((row) => {
      return !newSelectedStatus || row.status === newSelectedStatus;
    });

    setSelectedStatus(newSelectedStatus);
    setFilteredData(filteredData);

    toast({
      title: "Filter Removed",
      description: `Filter for ${filterType} has been removed.`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const applyFilter = () => {
    const filteredData = originalData.filter((row) => {
      return !selectedStatus || row.status === selectedStatus;
    });

    setFilteredData(filteredData);
    toast({
      title: "Filters Applied",
      description: "Data has been filtered",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const clearFilter = () => {
    setSelectedStatus("");
    setFilteredData(originalData);
    toast({
      title: "Filters Cleared",
      description: "Filters have been cleared",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const handleAddRecord = () => {
    if (
      !description ||
      !applicantName ||
      !amount ||
      !uploadedFile ||
      !project ||
      !stage
    ) {
      toast({
        title: "Error",
        description: "Please fill all the fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const newRecord = {
      sno: records.length + 1,
      description,
      applicantName,
      amount,
      uploadedFile: uploadedFile.name, // Display the uploaded file name
      project,
      stage,
      status,
      remarks,
    };

    setRecords([...records, newRecord]);
    setFilteredData([...records, newRecord]);
    setDescription("");
    setApplicantName("");
    setAmount("");
    setStatus("Pending");
    setRemarks("");
    setUploadedFile(null);
    setProject("");
    setStage("");

    toast({
      title: "Record Added",
      description: "The petty cash record has been added successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseAddDrawer();
  };

  const pettyCashColumns = [
    { id: "sno", header: "S.No", accessor: (item) => item.sno, show: true },
    {
      id: "description",
      header: "Description",
      accessor: (item) => item.description,
      show: true,
    },
    {
      id: "applicantName",
      header: "Applicant Name",
      accessor: (item) => item.applicantName,
      show: true,
    },
    {
      id: "amount",
      header: "Amount",
      accessor: (item) => item.amount,
      show: true,
    },
    {
      id: "uploadedFile",
      header: "Uploaded Bill",
      accessor: (item) => item.uploadedFile,
      show: true,
    },
    {
      id: "project",
      header: "Project/Site",
      accessor: (item) => item.project,
      show: true,
    },
    {
      id: "stage",
      header: "Stage",
      accessor: (item) => item.stage,
      show: true,
    },
    {
      id: "status",
      header: "Status",
      accessor: (item) => item.status,
      show: true,
    },
    {
      id: "remarks",
      header: "Remarks",
      accessor: (item) => item.remarks,
      show: true,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    pettyCashColumns.filter((col) => col.show).map((col) => col.id)
  );

  const handleVisibleColumnsChange = (columnId) => {
    const updatedColumns = visibleColumns.includes(columnId)
      ? visibleColumns.filter((col) => col !== columnId)
      : [...visibleColumns, columnId];
    setVisibleColumns(updatedColumns);
  };

  // Calculating totals for the dashboard
  const totalPettyCash = useMemo(() => {
    return records.reduce((total, record) => total + record.amount, 0);
  }, [records]);

  const mostPettyCashPerson = useMemo(() => {
    const totals = records.reduce((acc, record) => {
      acc[record.applicantName] =
        (acc[record.applicantName] || 0) + record.amount;
      return acc;
    }, {});

    const maxPerson = Object.keys(totals).reduce((a, b) =>
      totals[a] > totals[b] ? a : b
    );
    return { name: maxPerson, amount: totals[maxPerson] };
  }, [records]);

  const leastPettyCashPerson = useMemo(() => {
    const totals = records.reduce((acc, record) => {
      acc[record.applicantName] =
        (acc[record.applicantName] || 0) + record.amount;
      return acc;
    }, {});

    const minPerson = Object.keys(totals).reduce((a, b) =>
      totals[a] < totals[b] ? a : b
    );
    return { name: minPerson, amount: totals[minPerson] };
  }, [records]);

  return (
    <Box>
      <Flex justifyContent={"space-between"}>
        <GenericHeader title="Petty Cash" icon={<TbCash />} />
        <Button
          colorScheme="primary"
          leftIcon={<AddIcon />}
          onClick={onOpenAddDrawer}
        >
          Add Petty Cash
        </Button>
      </Flex>
      <Flex justifyContent="space-between" mb={4} mt={4} gap={2}>
        <DashboardBox
          name="Total Petty Cash"
          value={totalPettyCash}
          backgroundColor="teal.300"
          icon={FaMoneyBill}
        />
        <DashboardBox
          name="Most Petty Cash Person"
          value={`${mostPettyCashPerson.name} (${mostPettyCashPerson.amount})`}
          backgroundColor="blue.300"
          icon={FaMoneyBill}
        />
        <DashboardBox
          name="Least Petty Cash Person"
          value={`${leastPettyCashPerson.name} (${leastPettyCashPerson.amount})`}
          backgroundColor="red.300"
          icon={FaMoneyBill}
        />
      </Flex>
      <Flex justifyContent="space-between" mb={4}>
        <Button leftIcon={<AddIcon />} onClick={onOpenFilterModal}>
          Filter
        </Button>
        <SearchInput
          placeholder="Search Petty Cash Records"
          value={searchText}
          onChange={handleSearchInputChange}
          onClear={handleClearSearch}
        />
        <Button onClick={onOpenColumnModal}>Toggle Columns</Button>
      </Flex>
      <Flex mb={4}>
        <Flex>
          {selectedStatus && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Status: ${selectedStatus}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("status")} />
            </Tag>
          )}
        </Flex>
      </Flex>
      <Drawer
        isOpen={isAddDrawerOpen}
        placement="right"
        onClose={onCloseAddDrawer}
        size={"md"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>Add Petty Cash</DrawerHeader>
          <DrawerBody>
            <FormControl mb={4}>
              <FormLabel>Description</FormLabel>
              <Input
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter description"
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Applicant Name</FormLabel>
              <Input
                type="text"
                value={applicantName}
                onChange={(e) => setApplicantName(e.target.value)}
                placeholder="Enter applicant name"
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Amount</FormLabel>
              <Input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Enter amount"
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Upload Bill</FormLabel>
              <Input
                type="file"
                onChange={(e) => setUploadedFile(e.target.files[0])}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Project/Site</FormLabel>
              <Input
                type="text"
                value={project}
                onChange={(e) => setProject(e.target.value)}
                placeholder="Enter project/site name"
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Stage</FormLabel>
              <Input
                type="text"
                value={stage}
                onChange={(e) => setStage(e.target.value)}
                placeholder="Enter stage"
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Status</FormLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="Pending">Pending</option>
                <option value="Approved">Approved</option>
                <option value="Rejected">Rejected</option>
              </Select>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Remarks</FormLabel>
              <Textarea
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                placeholder="Enter any remarks"
              />
            </FormControl>
          </DrawerBody>
          <DrawerFooter>
            <Button colorScheme="primary" onClick={handleAddRecord}>
              Save
            </Button>
            <Button variant="ghost" onClick={onCloseAddDrawer}>
              Cancel
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Box>
        <GenericGrid
          columns={pettyCashColumns}
          data={filteredData}
          title="Petty Cash List"
          visibleColumnsState={visibleColumns}
          filterState={{ status: selectedStatus }} // Ensuring filter state is passed
          onVisibleColumnsChange={handleVisibleColumnsChange}
          onSort={null}
          sortConfig={null}
          onRowClick={null}
        />
      </Box>
    </Box>
  );
};

export default PettyCashRecord;
