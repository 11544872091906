import React, { useState } from "react";
import {
  Box,
  VStack,
  HStack,
  Input,
  Button,
  IconButton,
  Textarea,
  useToast,
  Avatar,
} from "@chakra-ui/react";
import { AttachmentIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { FaPhotoVideo, FaFileAlt } from "react-icons/fa";

// Sample Chat Message Component
const ChatMessage = ({ message, sender }) => {
  return (
    <HStack align="start" spacing={3}>
      <Avatar name={sender} />
      <Box bg="gray.100" p={3} borderRadius="lg">
        {message.type === "text" ? (
          <p>{message.content}</p>
        ) : message.type === "image" ? (
          <img
            src={message.content}
            alt="sent media"
            style={{ maxWidth: "150px" }}
          />
        ) : message.type === "video" ? (
          <video width="150" controls>
            <source src={message.content} type="video/mp4" />
          </video>
        ) : message.type === "file" ? (
          <a href={message.content} target="_blank" rel="noopener noreferrer">
            <FaFileAlt /> Download File
          </a>
        ) : null}
      </Box>
    </HStack>
  );
};

const ChatApp = () => {
  const [message, setMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [file, setFile] = useState(null);
  const toast = useToast();

  const handleSendMessage = (type, content) => {
    if (content || file) {
      const newMessage = {
        type,
        content,
        sender: "You",
      };
      setChatMessages([...chatMessages, newMessage]);
      setMessage("");
      setFile(null);
    } else {
      toast({
        title: "Message or file can't be empty",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      const fileUrl = URL.createObjectURL(uploadedFile);
      const fileType = uploadedFile.type.includes("image")
        ? "image"
        : uploadedFile.type.includes("video")
        ? "video"
        : "file";

      handleSendMessage(fileType, fileUrl);
    }
  };

  return (
    <VStack p={4} spacing={4} align="stretch">
      {/* Chat Display */}
      <Box
        h="400px"
        overflowY="auto"
        bg="gray.50"
        p={4}
        borderRadius="lg"
        shadow="md"
      >
        {chatMessages.length === 0 ? (
          <p>No messages yet</p>
        ) : (
          chatMessages.map((msg, idx) => (
            <ChatMessage key={idx} message={msg} sender={msg.sender} />
          ))
        )}
      </Box>

      {/* Message Input */}
      <HStack spacing={3} align="center">
        <IconButton
          icon={<AttachmentIcon />}
          onClick={() => document.getElementById("file-input").click()}
        />
        <Input
          id="file-input"
          type="file"
          display="none"
          onChange={handleFileUpload}
        />
        <Textarea
          placeholder="Type your message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button
          onClick={() => handleSendMessage("text", message)}
          rightIcon={<ArrowRightIcon />}
          colorScheme="blue"
        >
          Send
        </Button>
      </HStack>
    </VStack>
  );
};

export default ChatApp;
