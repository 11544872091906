import React, { useState } from "react";
import {
  Box,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Input,
  Textarea,
  Select,
  useDisclosure,
  Flex,
  Text,
} from "@chakra-ui/react";
import { IoAddOutline } from "react-icons/io5";
import ResourceManager from "./ResourceManagement/ResourceManager";

const ProjectManagementTabs = () => {
  const [risks, setRisks] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [budgets, setBudgets] = useState([]); // State to manage budget items

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [drawerType, setDrawerType] = useState("");
  const [formData, setFormData] = useState({});

  const handleOpenDrawer = (type) => {
    setDrawerType(type);
    setFormData({});
    onOpen();
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    if (drawerType === "Risk Management") {
      setRisks([...risks, formData]);
    } else if (drawerType === "Milestones") {
      setMilestones([...milestones, formData]);
    } else if (drawerType === "Budget Tracking") {
      setBudgets([...budgets, formData]); // Save budget items
    }
    onClose();
  };

  return (
    <Box>
      <Tabs variant="soft-rounded" colorScheme="blue">
        <TabList>
          <Tab
            borderRadius={20}
            _selected={{
              color: "white",
              bg: "primary.500",
            }}
          >
            Resource Management
          </Tab>
          <Tab
            borderRadius={20}
            _selected={{
              color: "white",
              bg: "primary.500",
            }}
          >
            Risk Management
          </Tab>
          <Tab
            borderRadius={20}
            _selected={{
              color: "white",
              bg: "primary.500",
            }}
          >
            Milestones
          </Tab>
          <Tab
            borderRadius={20}
            _selected={{
              color: "white",
              bg: "primary.500",
            }}
          >
            Budget Tracking
          </Tab>
        </TabList>

        <TabPanels>
          {/* Resource Management Tab */}
          <TabPanel>
            <Box>
              <ResourceManager />
            </Box>
          </TabPanel>

          {/* Risk Management Tab */}
          <TabPanel>
            <Flex justifyContent="space-between" mb={4}>
              <Text fontWeight="bold">Risk Management</Text>
              <IconButton
                icon={<IoAddOutline />}
                onClick={() => handleOpenDrawer("Risk Management")}
              />
            </Flex>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Risk Description</Th>
                  <Th>Likelihood</Th>
                  <Th>Impact</Th>
                  <Th>Mitigation Plan</Th>
                  <Th>Status</Th>
                  <Th>Owner</Th>
                  <Th>Notes</Th>
                </Tr>
              </Thead>
              <Tbody>
                {risks.map((risk, index) => (
                  <Tr key={index}>
                    <Td>{risk.description}</Td>
                    <Td>{risk.likelihood}</Td>
                    <Td>{risk.impact}</Td>
                    <Td>{risk.mitigation}</Td>
                    <Td>{risk.status}</Td>
                    <Td>{risk.owner}</Td>
                    <Td>{risk.notes}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TabPanel>

          {/* Milestones Tab */}
          <TabPanel>
            <Flex justifyContent="space-between" mb={4}>
              <Text fontWeight="bold">Milestones</Text>
              <IconButton
                icon={<IoAddOutline />}
                onClick={() => handleOpenDrawer("Milestones")}
              />
            </Flex>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Milestone Description</Th>
                  <Th>Due Date</Th>
                  <Th>Time Remaining</Th>
                  <Th>Late Days</Th>
                  <Th>Completed Date</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {milestones.map((milestone, index) => (
                  <Tr key={index}>
                    <Td>{milestone.description}</Td>
                    <Td>{milestone.dueDate}</Td>
                    <Td>{milestone.timeRemaining}</Td>
                    <Td>{milestone.lateDays}</Td>
                    <Td>{milestone.completedDate}</Td>
                    <Td>{milestone.status}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TabPanel>

          {/* Budget Tracking Tab */}
          <TabPanel>
            <Flex justifyContent="space-between" mb={4}>
              <Text fontWeight="bold">Budget Tracking</Text>
              <IconButton
                icon={<IoAddOutline />}
                onClick={() => handleOpenDrawer("Budget Tracking")}
              />
            </Flex>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Budget Item</Th>
                  <Th>Estimated Cost</Th>
                  <Th>Actual Cost</Th>
                  <Th>Variance</Th>
                  <Th>Status</Th>
                  <Th>Notes</Th>
                </Tr>
              </Thead>
              <Tbody>
                {budgets.map((budget, index) => (
                  <Tr key={index}>
                    <Td>{budget.budgetItem}</Td>
                    <Td>{budget.estimatedCost}</Td>
                    <Td>{budget.actualCost}</Td>
                    <Td>{budget.variance}</Td>
                    <Td>{budget.status}</Td>
                    <Td>{budget.notes}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/* Drawer for adding Risk, Milestone, or Budget Item */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {drawerType === "Risk Management"
              ? "Add New Risk"
              : drawerType === "Milestones"
              ? "Add New Milestone"
              : "Add New Budget Item"}
          </DrawerHeader>

          <DrawerBody>
            {drawerType === "Risk Management" && (
              <>
                <Input
                  placeholder="Risk Description"
                  name="description"
                  value={formData.description || ""}
                  onChange={handleInputChange}
                  mb={4}
                />
                <Select
                  placeholder="Likelihood"
                  name="likelihood"
                  value={formData.likelihood || ""}
                  onChange={handleInputChange}
                  mb={4}
                >
                  <option value="Low">Low</option>
                  <option value="Medium">Medium</option>
                  <option value="High">High</option>
                </Select>
                <Select
                  placeholder="Impact"
                  name="impact"
                  value={formData.impact || ""}
                  onChange={handleInputChange}
                  mb={4}
                >
                  <option value="Low">Low</option>
                  <option value="Medium">Medium</option>
                  <option value="High">High</option>
                </Select>
                <Textarea
                  placeholder="Mitigation Plan"
                  name="mitigation"
                  value={formData.mitigation || ""}
                  onChange={handleInputChange}
                  mb={4}
                />
                <Input
                  placeholder="Status"
                  name="status"
                  value={formData.status || ""}
                  onChange={handleInputChange}
                  mb={4}
                />
                <Input
                  placeholder="Owner"
                  name="owner"
                  value={formData.owner || ""}
                  onChange={handleInputChange}
                  mb={4}
                />
                <Textarea
                  placeholder="Notes"
                  name="notes"
                  value={formData.notes || ""}
                  onChange={handleInputChange}
                />
              </>
            )}

            {drawerType === "Milestones" && (
              <>
                <Input
                  placeholder="Milestone Description"
                  name="description"
                  value={formData.description || ""}
                  onChange={handleInputChange}
                  mb={4}
                />
                <Input
                  placeholder="Due Date"
                  type="date"
                  name="dueDate"
                  value={formData.dueDate || ""}
                  onChange={handleInputChange}
                  mb={4}
                />
                <Input
                  placeholder="Time Remaining"
                  name="timeRemaining"
                  value={formData.timeRemaining || ""}
                  onChange={handleInputChange}
                  mb={4}
                />
                <Input
                  placeholder="Late Days"
                  name="lateDays"
                  value={formData.lateDays || ""}
                  onChange={handleInputChange}
                  mb={4}
                />
                <Input
                  placeholder="Completed Date"
                  type="date"
                  name="completedDate"
                  value={formData.completedDate || ""}
                  onChange={handleInputChange}
                  mb={4}
                />
                <Select
                  placeholder="Status"
                  name="status"
                  value={formData.status || ""}
                  onChange={handleInputChange}
                  mb={4}
                >
                  <option value="Pending">Pending</option>
                  <option value="Completed">Completed</option>
                  <option value="Overdue">Overdue</option>
                </Select>
              </>
            )}

            {drawerType === "Budget Tracking" && (
              <>
                <Input
                  placeholder="Budget Item"
                  name="budgetItem"
                  value={formData.budgetItem || ""}
                  onChange={handleInputChange}
                  mb={4}
                />
                <Input
                  placeholder="Estimated Cost"
                  name="estimatedCost"
                  value={formData.estimatedCost || ""}
                  onChange={handleInputChange}
                  mb={4}
                />
                <Input
                  placeholder="Actual Cost"
                  name="actualCost"
                  value={formData.actualCost || ""}
                  onChange={handleInputChange}
                  mb={4}
                />
                <Input
                  placeholder="Variance"
                  name="variance"
                  value={formData.variance || ""}
                  onChange={handleInputChange}
                  mb={4}
                />
                <Select
                  placeholder="Status"
                  name="status"
                  value={formData.status || ""}
                  onChange={handleInputChange}
                  mb={4}
                >
                  <option value="Completed">Completed</option>
                  <option value="Pending">Pending</option>
                </Select>
                <Textarea
                  placeholder="Notes"
                  name="notes"
                  value={formData.notes || ""}
                  onChange={handleInputChange}
                />
              </>
            )}
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="primary" onClick={handleSave}>
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default ProjectManagementTabs;
