import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Heading,
  IconButton,
  Grid,
  Text,
  GridItem,
  Button,
  DrawerFooter,
  DrawerCloseButton,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Drawer,
  Input,
  DrawerHeader,
} from "@chakra-ui/react";
import StageDetails from "./StageDetails";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import ProjectDetailsModal from "./ProjectDetailsModal";
import { GrMoney, GrOverview } from "react-icons/gr";
import DashboardBox from "../../commons/DashboardBox";
import { GiExpense, GiProfit } from "react-icons/gi";
import { PiMoneyThin } from "react-icons/pi";
import { GoChevronDown, GoChevronRight } from "react-icons/go";
import { Pie } from "react-chartjs-2";
import "chart.js/auto"; // Needed for react-chartjs-2
import GenericHeader from "../../commons/GenericHeader";

const ProjectDetails = () => {
  const { projectName } = useParams();
  const navigate = useNavigate();
  const projects = useSelector((state) => state.projectList.projects);
  const project = projects.find((proj) => proj.name === projectName);
  const { stages } = project;
  const [expandedStage, setExpandedStage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const totalBudget = stages.reduce(
    (total, stage) => total + (stage.budget ? parseFloat(stage.budget) : 0),
    0
  );

  const totalGrossRevenue = stages.reduce(
    (total, stage) =>
      total + (stage.grossRevenue ? parseFloat(stage.grossRevenue) : 0),
    0
  );
  const totalGrossProfit = stages.reduce(
    (total, stage) =>
      total + (stage.grossProfit ? parseFloat(stage.grossProfit) : 0),
    0
  );

  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleStageClick = (index) => {
    setExpandedStage(expandedStage === index ? null : index);
  };

  const calculateDaysLeft = (startDate, days) => {
    const currentDate = new Date();
    const start = new Date(startDate);
    const end = new Date(start);
    end.setDate(end.getDate() + days);
    const diffTime = end - currentDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return Math.max(0, diffDays);
  };

  const calculateProgress = (startDate, days) => {
    const currentDate = new Date();
    const start = new Date(startDate);
    const end = new Date(start);
    end.setDate(end.getDate() + days);
    const totalDays = (end - start) / (1000 * 60 * 60 * 24);
    const daysPassed = (currentDate - start) / (1000 * 60 * 60 * 24);
    return Math.min(100, (daysPassed / totalDays) * 100);
  };

  return (
    <>
      <Flex
        gap={5}
        align="center"
        alignItems={"center"}
        justifyContent={"space-between"}
        mb="4"
      >
        <Flex gap={5} align="center">
          <GenericHeader title={projectName + " Details"} back={"/projects"} />
          <IconButton
            colorScheme="blue"
            onClick={handleOpenModal}
            size="sm"
            variant="outline"
            icon={<GrOverview />}
            isRound={true}
            aria-label="View Details"
          >
            View Details
          </IconButton>
        </Flex>
        <Box>
          <Button
            variant={"outline"}
            onClick={() => {
              navigate("/add-site");
            }}
          >
            Edit Project
          </Button>
        </Box>
      </Flex>

      <Box p={5}>
        <Grid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={6}>
          <DashboardBox
            icon={GrMoney}
            name="Project Budget"
            value={totalBudget.toLocaleString()}
            type="money"
            backgroundColor="#0F3C5C"
          />
          <DashboardBox
            icon={GiExpense}
            name="Project Cost"
            value={totalGrossRevenue.toLocaleString()}
            type="money"
            backgroundColor="#D3CD42"
          />
          <DashboardBox
            icon={GiProfit}
            name="Total Profit"
            value={totalGrossProfit.toLocaleString()}
            type="money"
            backgroundColor="#178A3E"
          />
          <DashboardBox
            icon={PiMoneyThin}
            name="Project BOQ"
            value="15000"
            type="money"
            backgroundColor="#0F3C5C"
          />
        </Grid>
      </Box>

      <Box p="4">
        <Heading as="h2" size="xl" color="gray.500" fontWeight="bold" mb={5}>
          Stages
        </Heading>

        <Accordion allowMultiple>
          {stages.map((stage, index) => (
            <AccordionItem
              key={index}
              mb={4}
              p={0}
              backgroundColor={"white"}
              borderRadius={15}
            >
              {({ isExpanded }) => (
                <>
                  <h3>
                    <AccordionButton
                      onClick={() => handleStageClick(index)}
                      style={{ position: "relative", padding: "0" }}
                      _hover={{
                        backgroundColor: "#FCE8C9",
                        borderRadius: "15px",
                      }}
                    >
                      <Box
                        flex="1"
                        textAlign="left"
                        display="flex"
                        alignItems="center"
                        height="100px"
                        position="relative"
                        overflow="hidden"
                      >
                        <Box
                          position="absolute"
                          left="0"
                          top="0"
                          bottom="0"
                          width={`${calculateProgress(
                            stage.plannedStartDate,
                            stage.days
                          )}%`}
                          bg="#7EB979"
                          opacity="0.3"
                          zIndex="0"
                          borderRadius={15}
                          borderTopRightRadius={
                            calculateProgress(
                              stage.plannedStartDate,
                              stage.days
                            ) === 100 && !isExpanded
                              ? 15
                              : 0
                          }
                          borderBottomRightRadius={
                            calculateProgress(
                              stage.plannedStartDate,
                              stage.days
                            ) === 100 && !isExpanded
                              ? 15
                              : 0
                          }
                        />
                        <Box
                          zIndex="1"
                          display="flex"
                          alignItems="center"
                          width="100%"
                          justifyContent="space-between"
                          px="4"
                        >
                          <Box display="flex" alignItems="center">
                            {isExpanded ? (
                              <GoChevronDown fontSize="30" />
                            ) : (
                              <GoChevronRight fontSize="30" />
                            )}
                            <Text ml="2">Stage {index + 1}</Text>
                            <Text ml="2" fontWeight="bold" fontSize="30px">
                              {stage.name}
                            </Text>
                            {stage.budget && (
                              <Text
                                ml="2"
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                bg={"#0f3c5c"}
                                borderRadius={"md"}
                                padding={"0 10px"}
                                color={"white"}
                              >
                                Budget -{" "}
                                <Text ml="2" fontWeight="bold" fontSize="30px">
                                  {stage.budget}
                                  {" AED"}
                                </Text>
                              </Text>
                            )}
                          </Box>
                          <Box display="flex" alignItems="baseline">
                            <Text
                              ml="2"
                              fontWeight="bold"
                              fontSize="30px"
                              color={"primary.500"}
                            >
                              {calculateDaysLeft(
                                stage.plannedStartDate,
                                stage.days
                              )}{" "}
                            </Text>
                            <Text ml="2">Days Remaining</Text>
                          </Box>
                          <Box
                            display={"Flex"}
                            gap={2}
                            flexDirection={"column"}
                          >
                            <Flex justifyContent={"space-between"} gap={5}>
                              <Text>Planned Start Date</Text>
                              <Text
                                bg={"black"}
                                borderRadius={5}
                                color={"white"}
                                paddingLeft={2}
                                paddingRight={2}
                              >
                                {new Date(
                                  stage.plannedStartDate
                                ).toLocaleDateString()}
                              </Text>
                            </Flex>
                            <Flex justifyContent={"space-between"} gap={5}>
                              <Text>Planned End Date</Text>
                              <Text
                                bg={"black"}
                                borderRadius={5}
                                paddingLeft={2}
                                paddingRight={2}
                                color={"white"}
                              >
                                {new Date(
                                  stage.plannedEndDate
                                ).toLocaleDateString()}
                              </Text>
                            </Flex>
                          </Box>
                        </Box>
                      </Box>
                    </AccordionButton>
                  </h3>
                  <AccordionPanel>
                    <StageDetails
                      stage={stage}
                      projectStartDate={project.startDate}
                      stageIndex={index}
                      projectName={project.name}
                    />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
          <AccordionItem
            mb={4}
            p={0}
            backgroundColor={"#ebebeb"}
            display={"flex"}
            borderRadius={15}
            justifyContent={"center"}
            marginTop={5}
          >
            <AccordionButton
              display={"flex"}
              justifyContent={"center"}
              height={"66px"}
              fontSize={"20px"}
              fontWeight={"bold"}
              color=" #9d9d9d"
              onClick={() => setDrawerOpen(true)}
            >
              Add Extra Stage
            </AccordionButton>
          </AccordionItem>
        </Accordion>
      </Box>
      <ProjectDetailsModal
        isOpen={isModalOpen}
        onClose={handleCloseDrawer}
        project={project}
      />
      <Drawer isOpen={drawerOpen} placement="right" onClose={handleCloseDrawer}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add Extra Stage</DrawerHeader>
          <DrawerBody>
            <>
              <Text variant={"seconday"}>
                Days will be added to last stage date
              </Text>
              <Input placeholder="No Of Days" name="number" mb={4} />
              <Input placeholder="Stage Name" name="amount" mb={4} />
            </>
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={handleCloseDrawer}>
              Cancel
            </Button>
            <Button colorScheme="primary">Save</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ProjectDetails;
