import React, { useState, useCallback, useMemo } from "react";
import {
  Box,
  Flex,
  Button,
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
  useToast,
  useDisclosure,
  IconButton,
  FormControl,
  FormLabel,
  Switch,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter,
  Input,
  VStack,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import debounce from "lodash/debounce";
import GenericGrid from "./GenericGrid";
import SearchInput from "../../commons/SearchInput";
import DashboardBox from "../../commons/DashboardBox"; // Ensure DashboardBox is properly imported
import { CiMoneyBill } from "react-icons/ci";
import GenericHeader from "../../commons/GenericHeader";

const OverheadCosts = () => {
  const toast = useToast();

  // Initial Dummy Data
  const initialData = [
    {
      sno: 1,
      description: "Office Rent",
      date: "2023-09-01",
      amount: 5000,
    },
    {
      sno: 2,
      description: "Utilities",
      date: "2023-09-05",
      amount: 1500,
    },
    {
      sno: 3,
      description: "Internet Subscription",
      date: "2023-09-10",
      amount: 300,
    },
    // Add more entries as needed
  ];

  const [rowData, setRowData] = useState(initialData);
  const [originalData] = useState(initialData);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(initialData);

  // Modal Controls
  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();
  const {
    isOpen: isColumnModalOpen,
    onOpen: onOpenColumnModal,
    onClose: onCloseColumnModal,
  } = useDisclosure();

  // Drawer Controls
  const {
    isOpen: isDrawerOpen,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();
  const btnRef = React.useRef();

  // Filter States
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedAmount, setSelectedAmount] = useState("");

  // Form States for Adding New Entry
  const [newDescription, setNewDescription] = useState("");
  const [newDate, setNewDate] = useState("");
  const [newAmount, setNewAmount] = useState("");

  // Memoized Filters
  const filters = useMemo(
    () => ({
      description: selectedDescription,
      date: selectedDate,
      amount: selectedAmount,
    }),
    [selectedDescription, selectedDate, selectedAmount]
  );

  // Debounced Search
  const debouncedApplySearchFilter = useCallback(
    debounce((text) => applySearchFilter(text), 300),
    [originalData]
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedApplySearchFilter(text);
  };

  const applySearchFilter = (text) => {
    const lowercasedText = text.toLowerCase();
    const filtered = originalData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(lowercasedText)
      )
    );
    setFilteredData(filtered);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredData(originalData);
  };

  const handleTagClose = (filterType) => {
    let updatedFilters = { ...filters };

    switch (filterType) {
      case "description":
        setSelectedDescription("");
        updatedFilters.description = "";
        break;
      case "date":
        setSelectedDate("");
        updatedFilters.date = "";
        break;
      case "amount":
        setSelectedAmount("");
        updatedFilters.amount = "";
        break;
      default:
        break;
    }

    applyFilters(updatedFilters);
    toast({
      title: "Filter Removed",
      description: `Filter for ${filterType} has been removed.`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const applyFilters = (currentFilters) => {
    let filtered = originalData;

    if (currentFilters.description) {
      filtered = filtered.filter(
        (row) => row.description === currentFilters.description
      );
    }

    if (currentFilters.date) {
      filtered = filtered.filter((row) => row.date === currentFilters.date);
    }

    if (currentFilters.amount) {
      filtered = filtered.filter(
        (row) => row.amount === Number(currentFilters.amount)
      );
    }

    setFilteredData(filtered);
  };

  const handleApplyFilter = () => {
    const currentFilters = {
      description: selectedDescription,
      date: selectedDate,
      amount: selectedAmount,
    };
    applyFilters(currentFilters);
    toast({
      title: "Filters Applied",
      description: "Data has been filtered.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const handleClearFilter = () => {
    setSelectedDescription("");
    setSelectedDate("");
    setSelectedAmount("");
    setFilteredData(originalData);
    toast({
      title: "Filters Cleared",
      description: "All filters have been cleared.",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  // Column Definitions
  const overheadCostColumns = [
    {
      id: "sno",
      header: "S.No",
      accessor: (item, index) => index + 1,
      show: true,
      minWidth: 50,
    },
    {
      id: "description",
      header: "Description",
      accessor: (item) => item.description,
      show: true,
      minWidth: 200,
    },
    {
      id: "date",
      header: "Date",
      accessor: (item) => item.date,
      show: true,
      minWidth: 150,
    },
    {
      id: "amount",
      header: "Amount (AED)",
      accessor: (item) => `AED ${item.amount.toLocaleString()}`,
      show: true,
      minWidth: 150,
    },
    {
      id: "actions",
      header: "Actions",
      accessor: (item) => item.sno,
      render: (item) => (
        <IconButton
          aria-label="Delete Row"
          icon={<DeleteIcon />}
          colorScheme="red"
          size="sm"
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteRow(item.sno);
          }}
        />
      ),
      show: true,
      minWidth: 100,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    overheadCostColumns.filter((col) => col.show !== false).map((col) => col.id)
  );

  const handleVisibleColumnsChange = (columnId) => {
    const updatedColumns = visibleColumns.includes(columnId)
      ? visibleColumns.filter((col) => col !== columnId)
      : [...visibleColumns, columnId];
    setVisibleColumns(updatedColumns);
  };

  const handleDeleteRow = (sno) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this overhead cost entry?"
    );
    if (confirmed) {
      const updatedRowData = rowData.filter((row) => row.sno !== sno);
      setRowData(updatedRowData);
      setFilteredData(updatedRowData);
      toast({
        title: "Entry Deleted",
        description: `Overhead cost entry S.No ${sno} has been deleted.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Adding New Overhead Cost Entry
  const handleAddOverheadCost = () => {
    if (!newDescription || !newDate || !newAmount) {
      toast({
        title: "Incomplete Data",
        description: "Please fill in all the fields.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const newEntry = {
      sno: originalData.length + rowData.length + 1, // Adjust according to your data management
      description: newDescription,
      date: newDate,
      amount: parseFloat(newAmount),
    };

    const updatedRowData = [...rowData, newEntry];
    setRowData(updatedRowData);
    setFilteredData(updatedRowData);

    // Reset form fields
    setNewDescription("");
    setNewDate("");
    setNewAmount("");

    toast({
      title: "Entry Added",
      description: "New overhead cost entry has been added.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });

    onCloseDrawer();
  };

  return (
    <Box>
      <Flex justifyContent={"space-between"}>
        <GenericHeader title={"Overhead Costs"} />
        <Button
          leftIcon={<AddIcon />}
          colorScheme="primary"
          onClick={onOpenDrawer}
          ref={btnRef}
        >
          Add Overhead Cost
        </Button>
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Flex
          alignItems="center"
          width="100%"
          justifyContent="space-around"
          gap={3}
        >
          <DashboardBox
            icon={CiMoneyBill}
            name="Total Overhead Costs"
            value={`AED ${filteredData
              .reduce((sum, item) => sum + item.amount, 0)
              .toLocaleString()}`}
            backgroundColor="blue.300"
            type="money"
            ariaLabel="Total Overhead Costs"
          />
          {/* Add more DashboardBoxes as needed */}
        </Flex>
      </Flex>

      {/* Action Buttons */}
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        <Button leftIcon={<AddIcon />} onClick={onOpenFilterModal}>
          Filter
        </Button>
        <SearchInput
          placeholder="Search Overhead Costs"
          value={searchText}
          onChange={handleSearchInputChange}
          onClear={handleClearSearch}
        />
        <Button onClick={onOpenColumnModal}>Toggle Columns</Button>
      </Flex>

      {/* Active Filters */}
      <Flex mb={4}>
        <Flex>
          {filters.description && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Description: ${filters.description}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("description")} />
            </Tag>
          )}
          {filters.date && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Date: ${filters.date}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("date")} />
            </Tag>
          )}
          {filters.amount && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Amount: AED ${filters.amount}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("amount")} />
            </Tag>
          )}
        </Flex>
      </Flex>

      {/* Filter Modal */}
      <Modal isOpen={isFilterModalOpen} onClose={onCloseFilterModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Select
                  placeholder="Select Description"
                  value={selectedDescription}
                  onChange={(e) => setSelectedDescription(e.target.value)}
                >
                  {originalData.map((item, index) => (
                    <option key={index} value={item.description}>
                      {item.description}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Date</FormLabel>
                <Input
                  type="date"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Amount (AED)</FormLabel>
                <Input
                  type="number"
                  placeholder="Enter Amount"
                  value={selectedAmount}
                  onChange={(e) => setSelectedAmount(e.target.value)}
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleApplyFilter}>
              Apply Filter
            </Button>
            <Button variant="ghost" onClick={handleClearFilter}>
              Clear Filter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Column Toggle Modal */}
      <Modal isOpen={isColumnModalOpen} onClose={onCloseColumnModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Toggle Columns</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="start">
              {overheadCostColumns.map((col) => (
                <FormControl
                  display="flex"
                  alignItems="center"
                  key={col.id}
                  mb={2}
                >
                  <FormLabel htmlFor={col.id} mb="0" flex="1">
                    {col.header}
                  </FormLabel>
                  <Switch
                    id={col.id}
                    isChecked={visibleColumns.includes(col.id)}
                    onChange={() => handleVisibleColumnsChange(col.id)}
                  />
                </FormControl>
              ))}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onCloseColumnModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Drawer for Adding New Overhead Cost Entry */}
      <Drawer
        isOpen={isDrawerOpen}
        placement="right"
        onClose={onCloseDrawer}
        finalFocusRef={btnRef}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add New Overhead Cost</DrawerHeader>

          <DrawerBody>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Description</FormLabel>
                <Input
                  placeholder="Enter Description"
                  value={newDescription}
                  onChange={(e) => setNewDescription(e.target.value)}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Date</FormLabel>
                <Input
                  type="date"
                  value={newDate}
                  onChange={(e) => setNewDate(e.target.value)}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Amount (AED)</FormLabel>
                <Input
                  type="number"
                  placeholder="Enter Amount"
                  value={newAmount}
                  onChange={(e) => setNewAmount(e.target.value)}
                />
              </FormControl>
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onCloseDrawer}>
              Cancel
            </Button>
            <Button colorScheme="teal" onClick={handleAddOverheadCost}>
              Add
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      {/* Data Grid */}
      <Box>
        <GenericGrid
          columns={overheadCostColumns}
          data={filteredData}
          title="Overhead Costs"
          visibleColumnsState={visibleColumns}
          filterState={filters}
          onVisibleColumnsChange={handleVisibleColumnsChange}
        />
      </Box>
    </Box>
  );
};

export default OverheadCosts;
