import React, { useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Input,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import ResourceTable from "./ResourceTable";
import DrawerForm from "./DrawerForm";
import { AddIcon } from "@chakra-ui/icons";
import { differenceInDays, parseISO } from "date-fns"; // For calculating the days

const initialEquipmentData = [
  {
    id: 1,
    equipment: "SHOVEL",
    quantity: 2,
    assignedTo: "Equipment Manager",
    startDate: "1/7/2024",
    endDate: "7/9/2024",
    status: "In Use",
    notes: "Need Servicing",
  },
  {
    id: 2,
    equipment: "STEEL",
    quantity: 1,
    assignedTo: "Materials Manager",
    startDate: "1/7/2024",
    endDate: "7/9/2024",
    status: "Ordered",
    notes: "Delivery on 9/9",
  },
];

const initialPersonnelData = [
  {
    id: 1,
    skill: "Plumber", // Changed 'role' to 'skill'
    quantity: 5, // New field for personnel quantity
    startDate: "2024-01-07",
    endDate: "2024-07-09",
    status: "Active",
    notes: "Available for full project ",
  },
  {
    id: 2,
    skill: "Steel Fixer",
    quantity: 3, // New field for personnel quantity
    startDate: "2024-01-07",
    endDate: "2024-09-09",
    status: "On Leave",
    notes: "Visa Expir in Sep 2025",
  },
];

const initialFinanceData = [
  {
    id: 1,
    expenseDescription: "Equipment Purchase",
    expectedAmount: 5000,
    startDate: "1/7/2024",
    endDate: "7/9/2024",
    status: "Paid",
    notes: "Purchased shovels",
  },
];

const ResourceManager = () => {
  const [equipmentData, setEquipmentData] = useState(initialEquipmentData);
  const [personnelData, setPersonnelData] = useState(initialPersonnelData);
  const [financeData, setFinanceData] = useState(initialFinanceData);

  const [currentItem, setCurrentItem] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [currentSection, setCurrentSection] = useState("equipment");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchQuery, setSearchQuery] = useState("");

  const handleAdd = (section) => {
    setIsEdit(false);
    setCurrentSection(section);
    setCurrentItem(null);
    onOpen();
  };

  const handleEdit = (item, section) => {
    setIsEdit(true);
    setCurrentSection(section);
    setCurrentItem(item);
    onOpen();
  };

  const handleDelete = (id, section) => {
    if (section === "equipment") {
      setEquipmentData((prevData) => prevData.filter((item) => item.id !== id));
    } else if (section === "personnel") {
      setPersonnelData((prevData) => prevData.filter((item) => item.id !== id));
    } else if (section === "finance") {
      setFinanceData((prevData) => prevData.filter((item) => item.id !== id));
    }
  };

  const handleSave = (item) => {
    if (currentSection === "equipment") {
      if (isEdit) {
        setEquipmentData((prevData) =>
          prevData.map((dataItem) =>
            dataItem.id === currentItem.id ? item : dataItem
          )
        );
      } else {
        setEquipmentData([...equipmentData, { ...item, id: Date.now() }]);
      }
    } else if (currentSection === "personnel") {
      if (isEdit) {
        setPersonnelData((prevData) =>
          prevData.map((dataItem) =>
            dataItem.id === currentItem.id ? item : dataItem
          )
        );
      } else {
        setPersonnelData([...personnelData, { ...item, id: Date.now() }]);
      }
    } else if (currentSection === "finance") {
      if (isEdit) {
        setFinanceData((prevData) =>
          prevData.map((dataItem) =>
            dataItem.id === currentItem.id ? item : dataItem
          )
        );
      } else {
        setFinanceData([...financeData, { ...item, id: Date.now() }]);
      }
    }
    onClose();
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Helper function to calculate the difference in days
  const calculateDays = (startDate, endDate) => {
    const start = parseISO(startDate);
    const end = parseISO(endDate);
    return differenceInDays(end, start);
  };

  const filterData = (data) => {
    return data.filter((item) => {
      return Object.values(item).some((val) =>
        String(val).toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  };

  return (
    <>
      <Tabs>
        <TabList>
          <Tab>Equipment & Materials</Tab>
          <Tab>Personnel</Tab>
          <Tab>Finances</Tab>
        </TabList>

        <TabPanels>
          {/* Equipment & Materials Section */}
          <TabPanel>
            <Flex mb={4}>
              <Button
                leftIcon={<AddIcon />}
                colorScheme="teal"
                onClick={() => handleAdd("equipment")}
              >
                Add New Equipment/Material
              </Button>
              <Input
                ml={4}
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchChange}
                maxW="300px"
              />
            </Flex>
            <ResourceTable
              data={filterData(equipmentData).map((item) => ({
                ...item,
                days: calculateDays(item.startDate, item.endDate),
              }))}
              columns={[
                "Equipment",
                "Quantity",
                "Days",
                "Assigned To",
                "Planned Start Date",
                "Planned End Date",
                "Status",
                "Notes",
              ]}
              section="equipment"
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          </TabPanel>

          {/* Personnel Section */}
          <TabPanel>
            <Flex mb={4}>
              <Button
                leftIcon={<AddIcon />}
                colorScheme="teal"
                onClick={() => handleAdd("personnel")}
              >
                Add New Personnel
              </Button>
              <Input
                ml={4}
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchChange}
                maxW="300px"
              />
            </Flex>
            <ResourceTable
              data={filterData(personnelData).map((item) => ({
                ...item,
                days: calculateDays(item.startDate, item.endDate),
              }))}
              columns={[
                "Skill",
                "Quantity",
                "Days",
                "Planned Start Date",
                "Planned End Date",
                "Status",
                "Notes",
              ]}
              section="personnel"
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          </TabPanel>

          {/* Finances Section */}
          <TabPanel>
            <Flex mb={4}>
              <Button
                leftIcon={<AddIcon />}
                colorScheme="teal"
                onClick={() => handleAdd("finance")}
              >
                Add New Expense
              </Button>
              <Input
                ml={4}
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchChange}
                maxW="300px"
              />
            </Flex>
            <ResourceTable
              data={filterData(financeData).map((item) => ({
                ...item,
                days: calculateDays(item.startDate, item.endDate),
              }))}
              columns={[
                "Expense Description",
                "Expected Amount",
                "Planned Start Date",
                "Planned End Date",
                "Days",
                "Status",
                "Notes",
              ]}
              section="finance"
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/* Drawer Form for Add/Edit */}
      <DrawerForm
        isOpen={isOpen}
        onClose={onClose}
        isEdit={isEdit}
        item={currentItem}
        section={currentSection}
        onSave={handleSave}
      />
    </>
  );
};

export default ResourceManager;
